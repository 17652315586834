import React, { Component } from "react";
import axios from 'axios';
import cookie from 'react-cookies';
import { toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';

import 'react-confirm-alert/src/react-confirm-alert.css';
import * as GLOBAL from '../../../global';
import Sidebar from '../includes/sidebar';
import Topbar from '../includes/topbar';
import ProjectTabs from './project-tabs';
import ChecklistInnerDetails from './includes/checklist-inner';
import PopupManageChecklistInner from './popups/manage-checklist-inner';

class SingleChecklist extends Component {

   constructor(props) {
      super(props)
      this.state = {
         projectId: '',
         projectMainChecklist: null,
         projectChecklists: [],
         showPopup: false,
         loggedInUserId: '',
         accessLevels: [],
         unauthorizedAccess: false,
         token: cookie.load('_atclToken')
      };
   }

   componentDidMount() {
      if (this.props.match.params && this.props.match.params.projectId) {
         this.setState({
            projectId: this.props.match.params.projectId
         });
         this.refs.projectTabsComponent.syncProjectId(this.props.match.params.projectId);
         this.getProjectChecklists(this.props.match.params.projectChecklistId);
      } else {
         this.setState({
            projectId: null
         });
         this.refs.projectTabsComponent.syncProjectId(null);
      }
   }

   syncLoggedInUserData(user) {
      this.refs.topbarComponent.syncLoggedInUserData(user);
      this.setState({
         loggedInUserId: user._id,
         accessLevels: user.roleDetails[0].accessLevels,
         unauthorizedAccess: (user.roleDetails[0].accessLevels.indexOf(200) === -1) ? true : false
      });
   }

   toggleSidebar() {
      this.refs.sidebarComponent.toggleSidebar();
   }

   togglePopup() {
      this.setState({
         showPopup: !this.state.showPopup
      });
   }

   getProjectChecklists = (projectChecklistId) => {

      toast.dismiss();
      axios.get(GLOBAL.SERVER_URL + "/api/get-checklist-items-by-project-checklist?token=" + this.state.token + "&projectChecklistId=" + projectChecklistId).then(res => {
         if (res.data.errorCode === 0) {
            this.setState({
               projectMainChecklist: res.data.projectMainChecklist,
               projectChecklists: res.data.response
            });
         } else {
            toast.error(res.data.message, { delay: 150 });
         }
      }).catch(error => {
         toast.error('Error while retrieving projects', { delay: 150 });
      })

   }

   render() {

      if (this.state.unauthorizedAccess) {
         return <Redirect to="/dashboard" push={true} />;
      }

      if (this.state.projectId === null) {
         return <Redirect to="/projects" push={true} />;
      }

      return (
         <div className="gray-bg full-screen">
            <Sidebar activeMenu={'projects'} ref="sidebarComponent" syncLoggedInUserData={this.syncLoggedInUserData.bind(this)} />
            <div className="inner-container">
               <Topbar ref="topbarComponent" toggleSidebar={this.toggleSidebar.bind(this)} />
               <div className="mb-2 mt-2">
                  <div className="inner-conntainer">
                     <div className="row">
                        <div className="col-12 col-md-9">
                           <ProjectTabs activeLink={'checklist'} ref="projectTabsComponent" />
                        </div>
                        <div className="col-12 col-md-3 text-right">
                           {(this.state.accessLevels.indexOf(241) > -1) ?
                              <button className="add-btn" onClick={this.togglePopup.bind(this)}>Add Checklist</button>
                              : null}
                           {this.state.showPopup ?
                              <PopupManageChecklistInner projectId={this.state.projectId} activeChecklist={this.state.projectMainChecklist} getProjectChecklists={this.getProjectChecklists.bind(this)}
                                 closePopup={this.togglePopup.bind(this)}
                              />
                              : null
                           }
                        </div>
                     </div>
                     <ChecklistInnerDetails projectMainChecklist={this.state.projectMainChecklist} projectChecklists={this.state.projectChecklists} getProjectChecklists={this.getProjectChecklists.bind(this)} />
                  </div>
               </div>
            </div>
         </div>
      );
   }
}
export default SingleChecklist;
