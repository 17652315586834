import React, { Component } from "react";
import axios from 'axios';
import cookie from 'react-cookies';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import update from 'immutability-helper';
import { Redirect } from 'react-router-dom';

import 'react-confirm-alert/src/react-confirm-alert.css';
import * as GLOBAL from '../../../global';
import Sidebar from '../includes/sidebar';
import Topbar from '../includes/topbar';
import ProjectTabs from './project-tabs';
import ConsumableInnerDetails from './includes/consumable-inner';
import PopupManageConsumableInner from './popups/manage-consumable-inner';

class SingleConsumable extends Component {

   constructor(props) {
      super(props)
      this.state = {
         projectId: '',
         consumableDetails: null,
         consumableEntries: [],
         activeConsumableEntry: null,
         showPopup: false,
         loggedInUserId: '',
         accessLevels: [],
         unauthorizedAccess: false,
         wsInProgress: false,
         token: cookie.load('_atclToken')
      };
   }

   componentDidMount() {
      if (this.props.match.params && this.props.match.params.projectId && this.props.match.params.consumableId) {
         this.setState({
            projectId: this.props.match.params.projectId,
            consumableId: this.props.match.params.consumableId
         });
         this.refs.projectTabsComponent.syncProjectId(this.props.match.params.projectId);
         this.getConsumableEntries(this.props.match.params.consumableId);
      } else {
         this.setState({
            projectId: null
         });
         this.refs.projectTabsComponent.syncProjectId(null);
      }
   }

   syncLoggedInUserData(user) {
      this.refs.topbarComponent.syncLoggedInUserData(user);
      this.setState({
         loggedInUserId: user._id,
         accessLevels: user.roleDetails[0].accessLevels,
         unauthorizedAccess: (user.roleDetails[0].accessLevels.indexOf(200) === -1) ? true : false
      });
   }

   toggleSidebar() {
      this.refs.sidebarComponent.toggleSidebar();
   }

   openPopup(consumableEntry) {
      let params = {
         showPopup: true
      };
      if (consumableEntry) {
         params.activeConsumableEntry = consumableEntry;
      }
      this.setState(params);
   }

   closePopup() {
      this.setState({
         showPopup: false,
         activeConsumableEntry: null
      });
   }

   getConsumableEntries = (consumableId) => {

      toast.dismiss();
      axios.get(GLOBAL.SERVER_URL + "/api/get-consumable-entries?token=" + this.state.token + "&consumableId=" + consumableId).then(res => {
         if (res.data.errorCode === 0) {
            console.log(res.data.response);
            this.setState({
               consumableDetails: res.data.consumableDetails,
               consumableEntries: res.data.response
            });
         } else {
            toast.error(res.data.message, { delay: 150 });
         }
      }).catch(error => {
         toast.error('Error while retrieving consumables', { delay: 150 });
      })

   }

   getEntryDetails = (enrtyId) => {

      toast.dismiss();
      axios.get(GLOBAL.SERVER_URL + "/api/get-consumable-entry-by-id?token=" + this.state.token + "&enrtyId=" + enrtyId).then(res => {
         if (res.data.errorCode === 0) {
            if (res.data.response._id) {
               const index = this.state.consumableEntries.map(entry => entry._id).indexOf(res.data.response._id);
               const updatedEntries = update(this.state.consumableEntries, { $splice: [[index, 1, res.data.response]] });
               this.setState({ consumableEntries: updatedEntries });
            } else {
               toast.error(res.data.message, { delay: 150 });
            }
         } else {
            toast.error(res.data.message, { delay: 150 });
         }
      }).catch(error => {
         toast.error('Error while retrieving consumable details', { delay: 150 });
      })

   }

   deleteConsumableItemEntry(item) {
      toast.dismiss();
      if (item._id) {
         if (!this.state.wsInProgress) {
            confirmAlert({
               title: 'Confirm to delete',
               message: 'Are you sure to do this?',
               buttons: [
                  {
                     label: 'Cancel',
                     onClick: () => { }
                  },
                  {
                     label: 'Confirm',
                     onClick: () => {
                        this.setState({
                           wsInProgress: true
                        }, () => {
                           let consumableItemObject = item;
                           this.params = {
                              _id: consumableItemObject._id,
                              consumableId: consumableItemObject.consumableId,
                              token: this.state.token
                           }
                           axios.post(GLOBAL.SERVER_URL + "/api/delete-project-consumable-entry", this.params).then(res => {
                              this.setState({
                                 wsInProgress: false
                              }, () => {
                                 if (res.data.errorCode === 0) {
                                    this.getConsumableEntries(consumableItemObject.consumableId);
                                    toast.success(res.data.message, { delay: 150 });
                                 } else {
                                    toast.error(res.data.message, { delay: 150 });
                                 }
                              });
                           }).catch(error => {
                              toast.error("Error occured. Please try again!", { delay: 150 });
                           });
                        });
                     }
                  },
               ]
            });
         }
      } else {
         toast.error("Invalid operation!", { delay: 150 });
      }
   }

   render() {

      if (this.state.unauthorizedAccess) {
         return <Redirect to="/dashboard" push={true} />;
      }

      if (this.state.projectId === null) {
         return <Redirect to="/projects" push={true} />;
      }

      return (
         <div className="gray-bg full-screen">
            <Sidebar activeMenu={'projects'} ref="sidebarComponent" syncLoggedInUserData={this.syncLoggedInUserData.bind(this)} />
            <div className="inner-container">
               <Topbar ref="topbarComponent" toggleSidebar={this.toggleSidebar.bind(this)} />
               <div className="mb-2 mt-2">
                  <div className="inner-conntainer">
                     <div className="row">
                        <div className="col-12 col-md-9">
                           <ProjectTabs activeLink={'consumables'} ref="projectTabsComponent" />
                        </div>
                        <div className="col-12 col-md-3 text-right">
                           {(this.state.accessLevels.indexOf(263) > -1) ?
                              <button className="add-btn" onClick={() => this.openPopup(null)}>Add Entry</button>
                              : null}
                           {this.state.showPopup ?
                              <PopupManageConsumableInner projectId={this.state.projectId} consumableId={this.state.consumableId} activeConsumableEntry={this.state.activeConsumableEntry} getConsumableEntries={this.getConsumableEntries.bind(this)} getEntryDetails={this.getEntryDetails.bind(this)} closePopup={this.closePopup.bind(this)}
                              />
                              : null
                           }
                        </div>
                     </div>
                     <ConsumableInnerDetails consumableDetails={this.state.consumableDetails} consumableEntries={this.state.consumableEntries} loggedInUserId={this.state.loggedInUserId} accessLevels={this.state.accessLevels} openPopup={this.openPopup.bind(this)} deleteConsumableItemEntry={this.deleteConsumableItemEntry.bind(this)} />
                  </div>
               </div>
            </div>
         </div>
      );
   }
}
export default SingleConsumable;
