import React, { Component } from "react";
import axios from 'axios';
import { toast } from 'react-toastify';
import cookie from 'react-cookies';
import * as GLOBAL from '../../global';
import './auth.css';

class Login extends Component {

  constructor(props) {
    super(props)

    this.state = {
      username: '',
      password: '',
      rememberMe: false,
      wsInProgress: false
    }
  }

  componentDidMount() {
    if (cookie.load('_atclToken')) {
      const { history } = this.props;
      history.push({
        pathname: '/dashboard'
      });
    }
    if (localStorage._atclStatus && localStorage._atclKey && localStorage._atclSecret) {
      this.setState({
        username: window.atob(localStorage._atclKey),
        password: window.atob(localStorage._atclSecret),
        rememberMe: localStorage._atclStatus
      });
    }
  }

  handleCheckbox() {
    this.setState({ rememberMe: !Boolean(this.state.rememberMe) });
  }

  validate = () => {
    if (!this.state.username) {
      toast.error("Username cannot be blank", { delay: 150 });
      return false;
    } else if (!this.state.password) {
      toast.error("Password cannot be blank", { delay: 150 });
      return false;
    } else {
      return true;
    }
  }

  signIn = (event) => {
    event.preventDefault();
    if (!this.state.wsInProgress) {
      toast.dismiss();
      const isValid = this.validate();
      const { history } = this.props;
      if (isValid) {
        this.setState({
          wsInProgress: true
        }, () => {
          this.params = {
            username: this.state.username,
            password: this.state.password,
          }

          axios.post(GLOBAL.SERVER_URL + "/api/signin", this.params).then(res => {
            this.setState({
              wsInProgress: false
            }, () => {
              if (res.data.errorCode === 0) {
                const expires = new Date();
                expires.setHours(expires.getHours() + 24);
                cookie.save('_atclToken', res.data.token, { path: '/', expires });
                if (this.state.rememberMe) {
                  localStorage._atclKey = window.btoa(this.state.username);
                  localStorage._atclSecret = window.btoa(this.state.password);
                  localStorage._atclStatus = true;
                } else {
                  localStorage.removeItem('_atclKey');
                  localStorage.removeItem('_atclSecret');
                  localStorage.removeItem('_atclStatus');
                }
                history.push({
                  pathname: '/dashboard'
                });
              } else {
                toast.error(res.data.message, { delay: 150 });
              }
            });
          }).catch(error => {
            toast.error("Error occured. Please try again!", { delay: 150 });
          });
        });
      }
    }
  }

  render() {
    return (

      <div className="login-bg full-screen">
        <div className="vertical-center-container overlay">
          <div className="vertical-align-item">
            <div className="auth-wrapper">
              <img className="logo" src={require("../../assets/img/logo.png")} alt="ATCL logo" />
              <form onSubmit={this.signIn}>
                <div className="form-group">
                  <label>Username</label>
                  <input className="form-control" type="text" value={this.state.username} onChange={(e) => this.setState({ username: e.target.value })} autoComplete="off" />
                </div>

                <div className="form-group">
                  <label>Password</label>
                  <input className="form-control" type="password" value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} autoComplete="off" />
                </div>

                <div className="custom-checkbox">
                  <input type="checkbox" id="remember" onChange={() => this.handleCheckbox()} checked={this.state.rememberMe} />
                  <label htmlFor="remember">Remember me</label>
                </div>
                <button type="submit" className="btn btn-primary">SIGN IN</button>
              </form>
            </div>
          </div>
        </div>
      </div>

    );
  }
}

export default Login;
