import React from 'react';
import axios from 'axios';
import cookie from 'react-cookies';
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";

import * as GLOBAL from '../../../../global';
import "react-datepicker/dist/react-datepicker.css";
import '../project.css';

class PopupManageProject extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      _id: '',
      projectName: '',
      startDate: new Date(),
      location: '',
      projectImage: null,
      fileLabel: 'Choose file',
      autocompleteBlocker: Date.now(),
      wsInProgress: false,
      token: cookie.load('_atclToken')
    }
  }

  componentDidMount() {
    if (this.props.project) {
      this.setState({
        _id: this.props.project._id,
        projectName: this.props.project.projectName,
        startDate: new Date(this.props.project.startDate),
        location: this.props.project.location,
        projectImage: this.props.project.projectImage ? this.props.project.projectImage : null,
        fileLabel:this.props.project.projectImage ? this.props.project.projectImage.replace(/^.*[\\/]/, '') : 'Choose file'
      });
    }
  }

  handleDateChange = date => {
    this.setState({
      startDate: date
    });
  };

  handleImageChange = (event) => {
    toast.dismiss();
    if (event.target.files.length > 0) {
      let file = event.target.files[0];
      let allowedExtensions = ['jpg', 'jpeg', 'png', 'webp'];
      if (file.size > "5242880") {
        this.setState({
          projectImage: null,
          fileLabel: 'Choose file'
        });
        toast.error('Please upload a file size of less than 5MB', { delay: 150 });
      } else if (allowedExtensions.indexOf((this.getFileExtension(file.name)).toLowerCase()) === -1) {
        this.setState({
          projectImage: null,
          fileLabel: 'Choose file'
        });
        toast.error('Selected file format is not allowed', { delay: 150 });
      } else {
        this.setState({
          projectImage: file,
          fileLabel: file.name
        });
      }
    }
  }

  getFileExtension = (filename) => {
    return filename.split('.').pop();
  }

  validate = () => {

    let pojectNameLength = /^[a-zA-Z -_0-9]{1,35}$/;
    let nameReg = /^[a-zA-Z_0-9 ]*$/;
    if (!this.state.projectName) {
      toast.error('Project Name cannot be blank', { delay: 150 });
      return false;
    } else if (!(this.state.projectName).match(pojectNameLength)) {
      toast.error('Project Name is too lengthy', { delay: 150 });
      return false;
    } else if (!(this.state.projectName).match(nameReg)) {
      toast.error('Enter a valid Project Name', { delay: 150 });
      return false;
    } else if (!this.state.startDate) {
      toast.error('Project start date cannot be blank', { delay: 150 });
      return false;
    } else if (!this.state.location) {
      toast.error('Location cannot be blank', { delay: 150 });
      return false;
    } else if (!(this.state.location).match(nameReg)) {
      toast.error('Enter a valid Location', { delay: 150 });
      return false;
    }  else {
      return true;
    }
  }

  saveProject = (event) => {
    event.preventDefault();
    if (!this.state.wsInProgress) {
      toast.dismiss();
      const isValid = this.validate();
      if (isValid) {
        this.setState({
          wsInProgress: true
        }, () => {
          const formData = new FormData();
          formData.append('_id', this.state._id);
          formData.append('projectName', this.state.projectName);
          formData.append('startDate', this.state.startDate);
          formData.append('location', this.state.location);
          formData.append('token', this.state.token);

          if (this.state.projectImage) {
            formData.append('projectImage', this.state.projectImage);
          }

          axios.post(GLOBAL.SERVER_URL + "/api/save-project", formData).then(res => {
            this.setState({
              wsInProgress: false
            }, () => {
              if (res.data.errorCode === 0) {
                toast.success(res.data.message, { delay: 150 });
                if (this.state._id !== '') {
                  this.props.getProjectDetails(this.state._id);
                } else {
                  this.props.getProjects();
                }
                this.props.closePopup();
              } else {
                toast.error(res.data.message, { delay: 150 });
              }
            });
          }).catch(error => {
            toast.error("Error occured. Please try again!", { delay: 150 });
          });
        });
      }
    }
  }

  render() {
    return (
      <div className="popup">
        <div className="popup_inner popup_inner_medium">
          <div className="page-header">
            <div className="material-card text-left">
              <div className="popup-heads">
                <div className="row">
                  <div className="col-12 col-sm-6 col-xl-6">
                    <h3 className="popup-title">{this.state._id ? 'Update' : 'Add'} Project</h3>
                  </div>
                  <div className="col-12 col-sm-6 col-xl-6">
                    <button onClick={this.saveProject} className="entry-save project-save">Save</button>
                    <button onClick={this.props.closePopup} className="entry-close project-close">Cancel</button>
                  </div>
                </div>
              </div>
              <div className="popup-body p-b-15">

                <div className="row mb-1">
                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="projectName">Project Name <span className="required">*</span></label>
                      <input type="text" id="projectName" name="projectName" className="form-control" value={this.state.projectName} onChange={(e) => this.setState({ projectName: e.target.value })} autoComplete="off" />
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label htmlFor="date">Date <span className="required">*</span></label>
                      <DatePicker className="form-control"
                        dateFormat="dd/MM/yyyy"
                        selected={this.state.startDate}
                        maxDate={new Date()}
                        onChange={this.handleDateChange}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label htmlFor="location">Location <span className="required">*</span></label>
                      <input type="text" id="location" name="location" className="form-control" value={this.state.location} onChange={(e) => this.setState({ location: e.target.value })} autoComplete="off" />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <div className="input-group form-group">
                      <label>Project Image</label>
                      <div className="input-group-prepend">
                        <span className="input-group-text">Upload</span>
                      </div>
                      <div className="custom-file">
                        <input type="file" className="custom-file-input" id="projectImage" accept="image/*" onChange={(e) => this.handleImageChange(e)} autoComplete="off" />
                        <label className="custom-file-label" htmlFor="projectImage">{this.state.fileLabel}</label>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

export default PopupManageProject;