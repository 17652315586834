import React from "react";
import axios from 'axios';
import cookie from 'react-cookies';
import { toast } from 'react-toastify';

import * as GLOBAL from '../../../../global';
import '../project.css';

class PopupViewLabourDetails extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      _id: '',
      projectId: '',
      firstName: '',
      lastName: '',
      accno: '',
      ifsc: '',
      designationId: '',
      designations: [],
      wsInProgress: false,
      token: cookie.load('_atclToken')
    }
  }

  componentDidMount() {
    if (this.props.projectId !== '') {
      this.setState({
        projectId: this.props.projectId
      });
      if (this.props.activeMember) {
        this.setState({
          _id: this.props.activeMember._id,
          firstName: this.props.activeMember.firstName,
          lastName: this.props.activeMember.lastName,
          designationId: this.props.activeMember.designationId,
          ifsc: this.props.activeMember.ifsc,
          accno: this.props.activeMember.accno
        });
      }
      this.getDesignations();
    }
  }

  getDesignations = () => {
    axios.get(GLOBAL.SERVER_URL + "/api/get-designations?token=" + this.state.token).then(res => {
      this.setState({
        designations: res.data.response
      });
    });
  }

  validate = () => {
    let validname = /^[\w -_]*$/;

    if (!this.state.firstName) {
      toast.error('First Name cannot be blank', { delay: 150 });
      return false;
    } else if (!this.state.designationId) {
      toast.error('Designation cannot be blank', { delay: 150 });
      return false;
    } else if (!(this.state.firstName).match(validname)) {
      toast.error('Enter a valid First Name', { delay: 150 });
      return false;
    } else if (!(this.state.lastName).match(validname)) {
      toast.error('Enter a valid Last Name', { delay: 150 });
      return false;
    } else {
      return true;
    }
  }

  saveMember = (event) => {
    event.preventDefault();
    if (!this.state.wsInProgress) {
      toast.dismiss();
      const isValid = this.validate();
      if (isValid) {
        this.setState({
          wsInProgress: true
        }, () => {
          this.params = {
            _id: this.state._id,
            projectId: this.state.projectId,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            designationId: this.state.designationId,
            accno: this.state.accno,
            ifsc: this.state.ifsc,
            token: this.state.token
          }
          axios.post(GLOBAL.SERVER_URL + "/api/save-labour-member", this.params).then(res => {
            this.setState({
              wsInProgress: false
            }, () => {
              if (res.data.errorCode === 0) {
                toast.success(res.data.message, { delay: 150 });
                if (this.state._id !== '') {
                  this.props.getMemberDetails(this.state._id);
                } else {
                  this.props.getMembers(this.state.projectId);
                }
                this.props.closePopup();
              } else {
                toast.error(res.data.message, { delay: 150 });
              }
            });
          }).catch(error => {
            toast.error("Error occured. Please try again!", { delay: 150 });
          });
        });
      }
    }
  }

  render() {
    return (
      <div className="popup">
        <div className="popup_inner popup_inner_small">
          <div className="labour-popup">
            <div className="popup-heads">
              <div className="row">
                <div className="col-12 col-sm-6 col-xl-6">
                  <h3 className="popup-title">Add Member</h3>
                </div>
                <div className="col-12 col-sm-6 col-xl-6">
                  <button onClick={this.saveMember} className="entry-save project-save">Save</button>
                  <button onClick={this.props.closePopup} className="entry-close project-close">Cancel</button>
                </div>
              </div>
            </div>
            <div className="popup-body">
              <div className="form-group">
                <label htmlFor="first-name">First Name <span className="required">*</span></label>
                <input type="text" id="first-name" className="form-control" value={this.state.firstName} onChange={(e) => this.setState({ firstName: e.target.value })} autoComplete="off" />
              </div>
              <div className="form-group">
                <label htmlFor="last-name">Last Name <span className="required">*</span></label>
                <input type="text" id="last-name" className="form-control" value={this.state.lastName} onChange={(e) => this.setState({ lastName: e.target.value })} autoComplete="off" />
              </div>
              <div className="form-group">
                <label htmlFor="designationId">Designation <span className="required">*</span></label>
                <select className="form-control" id="designationId" name="designationId" value={this.state.designationId} onChange={(e) => this.setState({ designationId: e.target.value })} >
                  <option value="">Select designation</option>
                  {this.state.designations.map((value, index) => {
                    return (<option value={value._id} key={index}>{value.name}</option>)
                  })}
                </select>
              </div>

              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label htmlFor="accno">Account Number <span className="required">*</span></label>
                    <input type="number" id="accno" className="form-control" value={this.state.accno} onChange={(e) => this.setState({ accno: e.target.value })} autoComplete="off" />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label htmlFor="ifsc">IFSC Code <span className="required">*</span></label>
                    <input type="text" id="ifsc" className="form-control" value={this.state.ifsc} onChange={(e) => this.setState({ ifsc: e.target.value })} autoComplete="off" />
                  </div>
                </div>
              </div>
              



            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default PopupViewLabourDetails;