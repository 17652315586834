import React from 'react';
import axios from 'axios';
import cookie from 'react-cookies';
import { toast } from 'react-toastify';

import * as GLOBAL from '../../../../global';
import '../orders.css';

class PopupManageOrder extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      _id: '',
      items: [],
      itemId: '',
      itemName: '',
      unitName: '',
      toProjectId: '',
      toProject: '',
      fromProjectId: '',
      fromProject: '',
      type: '',
      brand: '',
      quantity: '',
      status: '',
      tripId: '',
      invoiceNumber: '',
      deliveryAgent: '',
      deliveryNotes: '',
      itemFromProjects: [],
      projectLocations: [],
      orderViewOnly: true,
      wsInProgress: false,
      token: cookie.load('_atclToken')
    }
  }

  componentDidMount() {
    if (this.props.activeOrder) {
      this.setState({
        _id: this.props.activeOrder._id,
        itemId: this.props.activeOrder.itemId,
        itemName: (this.props.activeOrder.items[0] != undefined) ? this.props.activeOrder.items[0].itemName : this.props.activeOrder.consumables[0].name,
        toProjectId: this.props.activeOrder.toProjectId,
        toProject: this.props.activeOrder.toProjectData[0].projectName,
        fromProjectId: this.props.activeOrder.fromProjectId,
        fromProject: this.props.activeOrder.fromProjectData[0].projectName,
        type: this.props.activeOrder.type,
        brand: this.props.activeOrder.brand,
        quantity: this.props.activeOrder.quantity,
        status: this.props.activeOrder.status,
        tripId: this.props.activeOrder.tripId,
        invoiceNumber: this.props.activeOrder.invoiceNumber,
        deliveryAgent: this.props.activeOrder.deliveryAgent,
        deliveryNotes: this.props.activeOrder.deliveryNotes
      });
    }
  }

  getProjectLocations = () => {
    axios.get(GLOBAL.SERVER_URL + "/api/get-project-locations").then(res => {
      this.setState({
        projectLocations: res.data.response
      });
    });
  }

  getProjects = () => {
    let items = this.state.items;
    let itemsId = this.state.itemId;
    let projectLoc = this.state.projectLocations;
    let filteredItems = items.filter(item => item._id === itemsId);
    let myArrayFiltered = projectLoc.filter((el) => {
      return filteredItems[0].projectId === el._id;
    });
    this.setState({
      itemFromProjects: myArrayFiltered,
    });
  }

  onTypeChange(e) {
    this.setState({
      type: e.target.value,
      fromProjectId: '',
      toProjectId: ''
    }, () => {
      if (this.state.type && (this.state.type === 'plant-machinery')) {
        axios.get(GLOBAL.SERVER_URL + "/api/get-inventory-items?token=" + this.state.token).then(res => {
          if (res.data.errorCode === 0) {
            this.setState({
              items: res.data.response
            });

          } else {
            toast.error(res.data.message, { delay: 150 });
          }
        }).catch(error => {
          toast.error('Error while retrieving P&M items', { delay: 150 });
        })
      } else if (this.state.type && (this.state.type === 'consumable')) {
        axios.get(GLOBAL.SERVER_URL + "/api//get-consumables?token=" + this.state.token).then(res => {
          if (res.data.errorCode === 0) {
            this.setState({
              items: res.data.response
            });

          } else {
            toast.error(res.data.message, { delay: 150 });
          }
        }).catch(error => {
          toast.error('Error while retrieving consumables', { delay: 150 });
        })
      } else {
        this.setState({ items: [] });
      }
    });
  }

  onItemChange(e) {
    this.getItemUnit(e.target.value)
    this.setState({
      itemId: e.target.value,
      fromProjectId: '',
      toProjectId: ''
    }, () => {
      if (this.state.type && (this.state.type === 'plant-machinery')) {
        axios.get(GLOBAL.SERVER_URL + "/api/get-inventory-unique-locations?token=" + this.state.token + "&itemId=" + this.state.itemId).then(res => {
          if (res.data.errorCode === 0) {
            this.setState({
              itemFromProjects: res.data.response
            }, () => {
              this.getProjectLocations();
            });
          }
        }).catch(error => {
          toast.error('Error while retrieving P&M locations', { delay: 150 });
        })
      }
      if (this.state.type && (this.state.type === 'consumable')) {
        axios.get(GLOBAL.SERVER_URL + "/api/get-project-locations").then(res => {
          this.setState({
            projectLocations: res.data.response
          });
          this.getProjects()
        });
      }
    });
  }

  handleQuantityChange(e) {
    var value = e.target.value ? ((parseFloat(e.target.value) < 0) ? 0 : e.target.value) : 0;
    if (parseFloat(value) % 1 != 0) {
      value = parseFloat(value).toFixed(2);
    }
    this.setState({ quantity: value });
  }

  orderStatusChange(e) {
    this.setState({
      status: e.target.value
    }, () => {
      this.setState({ orderViewOnly: true });
      if (this.state.status === 'Delivered') {
        this.setState({ orderViewOnly: false });
      }
    });
  }

  validate = () => {
    let onlyNumber = /^[0-9]+$/; //only Allows number and letters
    let validname = /^[a-zA-Z0-9_ ]+$/; //only Allows number and letters
    if (!this.state.fromProjectId) {
      toast.error('From Project cannot be blank', { delay: 150 });
      return false;
    } else if (!this.state.toProjectId) {
      toast.error('To Project cannot be blank', { delay: 150 });
      return false;
    } else if (this.state.fromProjectId === this.state.toProjectId) {
      toast.error('From and To projects cannot be the same', { delay: 150 });
      return false;
    } else if (!(this.state.brand) && this.state.type === 'consumable') {
      toast.error('Brand cannot be blank', { delay: 150 });
      return false;
      // } else if (this.state.type && (this.state.type === 'plant-machinery') && (this.state.status === 'Delivered') && !(this.state.tripId).match(onlyNumber)) {
    } else if (!(this.state.brand).match(validname) && this.state.type === 'consumable') {
      toast.error('Valid Brand is required', { delay: 150 });
      return false;
      // } else if (this.state.type && (this.state.type === 'plant-machinery') && (this.state.status === 'Delivered') && !(this.state.tripId).match(onlyNumber)) {
    } else if (this.state.type && (this.state.type === 'plant-machinery') && (this.state.status === 'Delivered') && !this.state.tripId) {
      toast.error('Valid Trip Id is required', { delay: 150 });
      return false;
    } else if (this.state.type && (this.state.type === 'plant-machinery') && (this.state.status === 'Delivered') && !(this.state.deliveryAgent).match(validname)) {
      toast.error('Valid Delivery Agent name is required', { delay: 150 });
      return false;
    } else if (!this.state.quantity) {
      toast.error('Quantity cannot be blank', { delay: 150 });
      return false;
    }else if (!(this.state.quantity).match(onlyNumber) && this.state.type === 'consumable') {
      toast.error('Enter a valid Quantity', { delay: 150 });
      return false;
    }
    //  else if (this.state.type && (this.state.type === 'consumable') && (this.state.status === 'Delivered') && !(this.state.invoiceNumber).match(onlyNumber)) {
    //   toast.error('Enter a valid Invoice Number', { delay: 150 });
    //   return false;
    // }
     else {
      return true;
    }
  }

  getItemUnit(Id) {
    let unit = this.state.items.find(x => x._id === Id).unit;
    this.setState({
      unitName: unit[0].label
    });
  }

  saveOrder = (event) => {
    event.preventDefault();
    if (!this.state.wsInProgress) {
      toast.dismiss();
      const isValid = this.validate();
      if (isValid) {
        this.setState({
          wsInProgress: true
        }, () => {

          this.params = {
            _id: this.state._id,
            itemId: this.state.itemId,
            toProjectId: this.state.toProjectId,
            fromProjectId: this.state.fromProjectId,
            type: this.state.type,
            brand: (this.state.type === 'consumable') ? this.state.brand : '',
            quantity: this.state.quantity,
            status: this.state._id ? this.state.status : "Pending",
            tripId: ((this.state.type === 'plant-machinery') && (this.state.status === 'Delivered')) ? this.state.tripId : '',
            deliveredBy: ((this.state.type === 'consumable') && (this.state.status === 'Delivered')) ? this.state.deliveredBy : '',
            deliveryAgent: (this.state.status === 'Delivered') ? this.state.deliveryAgent : '',
            deliveryNotes: ((this.state.type === 'consumable') && (this.state.status === 'Delivered')) ? this.state.deliveryNotes : '',
            token: this.state.token
          }

          axios.post(GLOBAL.SERVER_URL + "/api/save-order", this.params).then(res => {
            this.setState({
              wsInProgress: false
            }, () => {
              if (res.data.errorCode === 0) {
                toast.success(res.data.message, { delay: 150 });
                this.props.getOrders();
                this.props.closePopup();
              } else {
                toast.error(res.data.message, { delay: 150 });
              }
            });
          }).catch(error => {
            toast.error("Error occured. Please try again!", { delay: 150 });
          });
        });
      }
    }
  }

  render() {
    return (
      <div className="popup">
        <div className="popup_inner popup_inner_medium">
          <div className="page-header">
            <div className="material-card text-left">
              <div className="popup-heads">
                <div className="row">
                  <div className="col-12 col-sm-6 col-xl-6">
                    <h3 className="popup-title">{this.state._id ? 'Update' : 'Create'} Order</h3>
                  </div>
                  <div className="col-12 col-sm-6 col-xl-6">
                    <button onClick={this.saveOrder} className="entry-save order-save">Save</button>
                    <button onClick={this.props.closePopup} className="entry-close order-close">Cancel</button>
                  </div>
                </div>
              </div>
              <div className="popup-body p-b-15">

                <div className="row mb-1">

                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label htmlFor="type">Type <span className="required">*</span></label>
                      {this.state._id ?
                        <div className="form-control disabled" id="type">{(this.state.type === 'plant-machinery') ? 'Plant & Machinery' : 'Consumable'}</div>
                        :
                        <select className="form-control" id="type" name="type" value={this.state.type} onChange={this.onTypeChange.bind(this)} >
                          <option value="">Select type</option>
                          <option value="plant-machinery">Plant & Machinery</option>
                          <option value="consumable">Consumable</option>
                        </select>
                      }
                    </div>
                  </div>

                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label htmlFor="itemId">Item <span className="required">*</span></label>
                      {this.state._id ?
                        <div className="form-control disabled" id="itemId">{this.state.itemName}</div>
                        :
                        <select className="form-control" id="itemId" name="itemId" value={this.state.itemId} onChange={this.onItemChange.bind(this)
                        } >
                          <option value="">Select item</option>
                          {this.state.items.map((value, index) => {
                            return (
                              <option value={value._id} key={index}>{(this.state.type === 'plant-machinery') ? value.itemName : value.name}</option>
                            )
                          })}
                        </select>
                      }
                    </div>
                  </div>

                </div>

                <div className="row mb-1">
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label htmlFor="from_location">From Project <span className="required">*</span></label>
                      {this.state._id ?
                        <div className="form-control disabled" id="from_location">{this.state.fromProject}</div>
                        :
                        <select className="form-control" id="from_location" value={this.state.fromProjectId} onChange={(e) => this.setState({ fromProjectId: e.target.value })} >
                          <option value="">Select project</option>
                          {this.state.itemFromProjects.map((value, index) => {
                            return (
                              <option value={value._id} key={index}>{value.projectName}</option>
                            )
                          })}
                        </select>
                      }
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label htmlFor="to_location">To Project <span className="required">*</span></label>
                      {this.state._id ?
                        <div className="form-control disabled" id="to_location">{this.state.toProject}</div>
                        :
                        <select className="form-control" id="to_location" value={this.state.toProjectId} onChange={(e) => this.setState({ toProjectId: e.target.value })} >
                          <option value="">Select project</option>
                          {this.state.projectLocations.map((value, index) => {
                            return (
                              this.state.fromProjectId !== value._id ?
                                <option value={value._id} key={index}>{value.projectName}</option> : null
                            )
                          })}
                        </select>
                      }
                    </div>
                  </div>

                </div>

                <div className="row">

                  {(this.state.type && (this.state.type === 'consumable')) ?
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <label htmlFor="brand">Brand <span className="required">*</span></label>
                        <input type="text" id="brand" className="form-control" value={this.state.brand} onChange={(e) => this.setState({ brand: e.target.value })} autoComplete="off" />
                      </div>
                    </div> : null}

                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      {this.state.unitName === '' ? <label htmlFor="quantity">Quantity <span className="required">*</span></label>
                        :
                        <label htmlFor="quantity">Quantity ({this.state.unitName}) <span className="required">*</span></label>}
                      {this.state._id ?
                        <div className="form-control disabled" id="quantity">{this.state.quantity}</div>
                        :
                        <input type="text" id="quantity" className="form-control" value={this.state.quantity} onChange={(e) => this.setState({ quantity: e.target.value })} autoComplete="off" />
                      }
                    </div>
                  </div>

                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label htmlFor="order-status">Status <span className="required">*</span></label>
                      {this.state._id ?
                        <select className="form-control" id="order-status" name="order-status" value={this.state.status} onChange={this.orderStatusChange.bind(this)} >
                          <option value="">Select status</option>
                          <option value="Pending">Pending</option>
                          <option value="Rejected">Rejected</option>
                          <option value="Approved">Approved</option>
                          <option value="Shipped">Shipped</option>
                          <option value="Delivered">Delivered</option>
                        </select>
                        :
                        <div className="form-control disabled" id="order-status">Pending</div>
                      }
                    </div>
                  </div>

                  {(this.state.type && (this.state.type === 'plant-machinery') && (this.state.status === 'Delivered')) ?
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <label htmlFor="trip_id">Trip ID <span className="required">*</span></label>
                        <input type="text" id="trip_id" className="form-control" value={this.state.tripId} onChange={(e) => this.setState({ tripId: e.target.value })} autoComplete="off" />
                      </div>
                    </div> : null}

                  {(this.state.type && (this.state.type === 'consumable') && (this.state.status === 'Delivered')) ?
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <label htmlFor="deliveryAgent">Delivered By <span className="required">*</span></label>
                        <input type="text" id="deliveryAgent" className="form-control" value={this.state.deliveryAgent} onChange={(e) => this.setState({ deliveryAgent: e.target.value })} autoComplete="off" />
                      </div>
                    </div> : null}

                  {(this.state.type && (this.state.type === 'plant-machinery') && (this.state.status === 'Delivered')) ?
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <label htmlFor="deliveryAgent">Delivered By <span className="required">*</span></label>
                        <input type="text" id="deliveryAgent" className="form-control" value={this.state.deliveryAgent} onChange={(e) => this.setState({ deliveryAgent: e.target.value })} autoComplete="off" />
                      </div>
                    </div> : null}

                  {(this.state.type && (this.state.type === 'consumable') && (this.state.status === 'Delivered')) ?
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="deliveryNotes">Delivery notes</label>
                        <textarea id="deliveryNotes" className="form-control" rows="3" value={this.state.deliveryNotes} onChange={(e) => this.setState({ deliveryNotes: e.target.value })}></textarea>
                      </div>
                    </div> : null}

                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PopupManageOrder;