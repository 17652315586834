import React, { Component } from "react";
import update from 'immutability-helper';
import axios from 'axios';
import cookie from 'react-cookies';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';

import 'react-confirm-alert/src/react-confirm-alert.css';
import * as GLOBAL from '../../../global';
import PopupManageComment from './popups/manage-comment-popup';

class Comments extends Component {

  constructor(props) {
    super(props)
    this.state = {
      filterProjectId: '',
      activeComment: null,
      showPopup: false,
      comments: [],
      wsInProgress: false,
      token: cookie.load('_atclToken')
    }
  }

  componentDidMount() {
    if (this.props.compData._id !== '') {
      this.getInventoryComments(this.props.compData._id, '');
    }
  }

  openPopup(item) {
    let params = {
      showPopup: true,
      activeComment: {
        commentId: item._id,
        itemId: item.itemId,
        projectId: item.projectId,
        comment: item.comment
      }
    };
    this.setState(params);
  }

  closePopup() {
    this.setState({
      showPopup: false,
      activeComment: null
    });
  }

  getInventoryComments(itemId, projectId) {
    toast.dismiss();
    this.setState({
      filterProjectId: projectId
    });
    axios.get(GLOBAL.SERVER_URL + "/api/get-inventory-comments?token=" + this.state.token + "&itemId=" + itemId + "&projectId=" + projectId).then(res => {
      if (res.data.errorCode === 0) {
        this.setState({
          comments: res.data.response
        });
      } else {
        toast.error(res.data.message, { delay: 150 });
      }
    }).catch(error => {
      toast.error("Error while retrieving inventory comments", { delay: 150 });
    })
  }

  getInventoryCommentDetails = (commentId) => {
    toast.dismiss();
    axios.get(GLOBAL.SERVER_URL + "/api/get-inventory-comment-by-id?token=" + this.state.token + "&commentId=" + commentId).then(res => {
      if (res.data.errorCode === 0) {
        if (res.data.response._id) {
          const index = this.state.comments.map(comment => comment._id).indexOf(res.data.response._id);
          const updatedComments = update(this.state.comments, { $splice: [[index, 1, res.data.response]] });
          this.setState({ comments: updatedComments });
        } else {
          toast.error(res.data.message, { delay: 150 });
        }
      } else {
        toast.error(res.data.message, { delay: 150 });
      }
    }).catch(error => {
      toast.error('Error while retrieving comment details', { delay: 150 });
    })
  }

  deleteComment(index, item) {
    toast.dismiss();
    if (item._id) {
      if (!this.state.wsInProgress) {
        confirmAlert({
          title: 'Confirm to delete',
          message: 'Are you sure to do this?',
          buttons: [
            {
              label: 'Cancel',
              onClick: () => { }
            },
            {
              label: 'Confirm',
              onClick: () => {

                this.setState({
                  wsInProgress: true
                }, () => {
                  let commentObject = this.state.comments[index];
                  this.params = {
                    _id: commentObject._id,
                    token: this.state.token
                  }
                  axios.post(GLOBAL.SERVER_URL + "/api/delete-inventory-comment", this.params).then(res => {
                    this.setState({
                      wsInProgress: false
                    }, () => {
                      if (res.data.errorCode === 0) {
                        this.setState({
                          comments: this.state.comments.filter((_, i) => i !== index)
                        });
                        toast.success(res.data.message, { delay: 150 });
                      } else {
                        toast.error(res.data.message, { delay: 150 });
                      }
                    });
                  }).catch(error => {
                    toast.error("Error occured. Please try again!", { delay: 150 });
                  });
                });

              }
            },
          ]
        });
      }
    } else {
      toast.error("Invalid operation!", { delay: 150 });
    }
  }

  render() {
    return (

      <div>

        <div className="row">
          <div className="col-12 col-sm-6 text-left">
            <h3 className="order-item-name-overflow">{this.props.compData.itemName} <span className="items-span">{this.props.compData.pmCode}</span></h3>
          </div>
          <div className="col-12 col-sm-6">
            <div className="selection-div">
              <span>Search by project</span>
              <select name="projectId" value={this.state.filterProjectId} onChange={(e) => this.getInventoryComments(this.props.compData._id, e.target.value)}>
                <option value="">All projects</option>
                {this.props.compData.projectLocations.map((value, index) => {
                  return (
                    <option value={value._id} key={index}>{value.projectName} {(value._id === this.props.compData.workshopProjectId) ? '(WS)' : null}</option>
                  )
                })}
              </select>
            </div>
          </div>
        </div>

        {(this.state.comments.length === 0) && <p className="no-items-text">No comments found!</p>}

        {this.state.comments.map((cItem, index) => {
          return (
            <div key={index} className="single-item">
              <p className="meta-details">
                <span>{cItem.projectData[0].projectName}</span>
                {cItem.authorData[0].firstName} {cItem.authorData[0].lastName} on &nbsp;
                    {new Intl.DateTimeFormat('en-GB', {
                  month: 'long',
                  day: '2-digit',
                  year: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: true
                }).format(new Date(cItem.updatedDateTime))}
                <button className="comment-btn-item comment-edit-btn" onClick={() => this.openPopup(cItem)}></button>
                <button className="comment-btn-item comment-delete-btn" onClick={() => this.deleteComment(index, cItem)}></button>
              </p>
              <div className="clear"></div>
              <p>{cItem.comment}</p>
            </div>
          )
        })}

        {this.state.showPopup ?
          <PopupManageComment activeComment={this.state.activeComment} closePopup={this.closePopup.bind(this)} getInventoryCommentDetails={this.getInventoryCommentDetails.bind(this)} />
          : null
        }

      </div>

    );
  }
}

export default Comments;
