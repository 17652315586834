import React, { Component } from "react";
import axios from 'axios';
import cookie from 'react-cookies';
import { toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';

import * as GLOBAL from '../../../global';
import 'react-confirm-alert/src/react-confirm-alert.css';

import Sidebar from '../includes/sidebar';
import Topbar from '../includes/topbar';
import ProjectTabs from './project-tabs';
import OrganizationDetails from './includes/organization';
import PopupManageOrganizationMember from './popups/manage-organization-member';

class Organization extends Component {

   constructor(props) {
      super(props)
      this.state = {
         projectId: '',
         members: [],
         activeMember: null,
         showPopup: false,
         loggedInUserId: '',
         superAdminUserId: null,
         accessLevels: [],
         unauthorizedAccess: false,
         wsInProgress: false,
         token: cookie.load('_atclToken')
      };
   }

   componentDidMount() {
      if (this.props.match.params && this.props.match.params.projectId) {
         this.setState({
            projectId: this.props.match.params.projectId
         });
         this.refs.projectTabsComponent.syncProjectId(this.props.match.params.projectId);
         this.getProjectMembers(this.props.match.params.projectId);
      } else {
         this.setState({
            projectId: null
         });
         this.refs.projectTabsComponent.syncProjectId(null);
      }
   }

   syncLoggedInUserData(user) {
      this.refs.topbarComponent.syncLoggedInUserData(user);
      this.setState({
         loggedInUserId: user._id,
         superAdminUserId: user.superAdminUserId,
         accessLevels: user.roleDetails[0].accessLevels,
         unauthorizedAccess: (user.roleDetails[0].accessLevels.indexOf(200) === -1) ? true : false
      });
   }

   toggleSidebar() {
      this.refs.sidebarComponent.toggleSidebar();
   }

   openPopup(member) {
      let params = {
         showPopup: true
      };
      if (member) {
         params.activeMember = member;
      }
      this.setState(params);
   }

   closePopup() {
      this.setState({
         showPopup: false,
         activeMember: null
      });
   }

   getProjectMembers = (projectId) => {
      toast.dismiss();
      axios.get(GLOBAL.SERVER_URL + "/api/get-project-members?token=" + this.state.token + "&projectId=" + projectId).then(res => {
         if (res.data.errorCode === 0) {
            if (res.data.response.length > 0) {
               this.setState({
                  members: res.data.response
               });
            }
         } else {
            toast.error(res.data.message, { delay: 150 });
         }
      });
   }

   removeMember(index, item) {
      toast.dismiss();
      if (item._id) {
         if (!this.state.wsInProgress) {
            confirmAlert({
               title: 'Confirm to delete',
               message: 'Are you sure to do this?',
               buttons: [
                  {
                     label: 'Cancel',
                     onClick: () => { }
                  },
                  {
                     label: 'Confirm',
                     onClick: () => {
                        this.setState({
                           wsInProgress: true
                        }, () => {
                           let memObject = this.state.members[index];
                           this.params = {
                              _id: memObject._id,
                              token: this.state.token
                           }
                           axios.post(GLOBAL.SERVER_URL + "/api/delete-project-member", this.params).then(res => {
                              this.setState({
                                 wsInProgress: false
                              }, () => {
                                 if (res.data.errorCode === 0) {
                                    this.setState({
                                       members: this.state.members.filter((_, i) => i !== index)
                                    });
                                    toast.success(res.data.message, { delay: 150 });
                                 } else {
                                    toast.error(res.data.message, { delay: 150 });
                                 }
                              });
                           }).catch(error => {
                              toast.error("Error occured. Please try again!", { delay: 150 });
                           });
                        });
                     }
                  },
               ]
            });
         }
      } else {
         toast.error("Invalid operation!", { delay: 150 });
      }
   }

   render() {

      if (this.state.unauthorizedAccess) {
         return <Redirect to="/dashboard" push={true} />;
      }

      if (this.state.projectId === null) {
         return <Redirect to="/projects" push={true} />;
      }

      return (
         <div className="gray-bg full-screen">
            <Sidebar activeMenu={'projects'} ref="sidebarComponent" syncLoggedInUserData={this.syncLoggedInUserData.bind(this)} />
            <div className="inner-container">
               <Topbar ref="topbarComponent" toggleSidebar={this.toggleSidebar.bind(this)} />
               <div className="mb-2 mt-2">
                  <div className="inner-conntainer">
                     <div className="row">
                        <div className="col-12 col-md-9">
                           <ProjectTabs activeLink={'organization'} ref="projectTabsComponent" />
                        </div>
                        <div className="col-12 col-md-3 text-right">
                           {(this.state.accessLevels.indexOf(221) > -1) ?
                              <button className="add-btn" onClick={() => this.openPopup(null)}>Add Member</button>
                              : null}
                           {this.state.showPopup ?
                              <PopupManageOrganizationMember projectId={this.state.projectId} superAdminUserId={this.state.superAdminUserId} activeMember={this.state.activeMember}
                                 getProjectMembers={this.getProjectMembers.bind(this)} closePopup={this.closePopup.bind(this)}
                              />
                              : null
                           }

                        </div>
                     </div>
                     <OrganizationDetails members={this.state.members} loggedInUserId={this.state.loggedInUserId} accessLevels={this.state.accessLevels} openPopup={this.openPopup.bind(this)} removeMember={this.removeMember.bind(this)} />
                  </div>
               </div>
            </div>
         </div>
      );
   }
}
export default Organization;
