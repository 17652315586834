import React, { Component } from "react";
import axios from 'axios';
import cookie from 'react-cookies';
import { toast } from 'react-toastify';
import { Link, Redirect } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import PopupManageInventory from './popups/manage-inventory-popup';
import * as GLOBAL from '../../../global';
import Sidebar from '../includes/sidebar';
import Topbar from '../includes/topbar';

class Inventory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inventoryItems: [],
      loggedInUserId: '',
      accessLevels: [],
      unauthorizedAccess: false,
      showPopup: false,
      activeInventory: null,
      wsInProgress: false,
      token: cookie.load('_atclToken')
    }
  }

  componentDidMount() {
    if (this.state.token && typeof this.state.token != 'undefined') {
      this.getInventoryItems();
    }
  }

  syncLoggedInUserData(user) {
    this.refs.topbarComponent.syncLoggedInUserData(user);
    this.setState({
      loggedInUserId: user._id,
      accessLevels: user.roleDetails[0].accessLevels,
      unauthorizedAccess: (user.roleDetails[0].accessLevels.indexOf(300) === -1) ? true : false
    });
  }

  toggleSidebar() {
    this.refs.sidebarComponent.toggleSidebar();
  }

  togglePopup() {
    this.setState({
      showPopup: !this.state.showPopup
    });
  }

  openPopup(value) {
    let params = {
      showPopup: true
    };
    if (value) {
      params.activeInventory = value;
    }
    this.setState(params);
  }

  closePopup() {
    this.setState({
      showPopup: false,
      activeInventory: null
    });
  }

  getInventoryItems = () => {

    toast.dismiss();
    axios.get(GLOBAL.SERVER_URL + "/api/get-inventory-items?token=" + this.state.token).then(res => {
      if (res.data.errorCode === 0) {
        this.setState({
          inventoryItems: res.data.response
        });
      } else {
        toast.error(res.data.message, { delay: 150 });
      }
    }).catch(error => {
      toast.error('Error while retrieving inventory items', { delay: 150 });
    })

  }

  deleteInventoryItem(index, value) {
    toast.dismiss();
    if (value._id) {
      if (!this.state.wsInProgress) {
        confirmAlert({
          title: 'Confirm to delete',
          message: 'Are you sure to do this?',
          buttons: [
            {
              label: 'Cancel',
              onClick: () => { }
            },
            {
              label: 'Confirm',
              onClick: () => {
                this.setState({
                  wsInProgress: true
                }, () => {
                  let itemObject = this.state.inventoryItems[index];
                  this.params = {
                    _id: itemObject._id,
                    token: this.state.token
                  }
                  axios.post(GLOBAL.SERVER_URL + "/api/delete-inventory-item", this.params).then(res => {
                    this.setState({
                      wsInProgress: false
                    }, () => {
                      if (res.data.errorCode === 0) {
                        this.setState({
                          inventoryItems: this.state.inventoryItems.filter((_, i) => i !== index)
                        });
                        toast.success(res.data.message, { delay: 150 });
                      } else {
                        toast.error(res.data.message, { delay: 150 });
                      }
                    });
                  }).catch(error => {
                    toast.error("Error occured. Please try again!", { delay: 150 });
                  });
                });
              }
            },
          ]
        });
      }
    } else {
      toast.error("Invalid operation!", { delay: 150 });
    }
  }

  render() {

    if (this.state.unauthorizedAccess) {
      return <Redirect to="/dashboard" push={true} />;
    }

    return (
      <div className="gray-bg full-screen">
        <Sidebar activeMenu={'inventory'} ref="sidebarComponent" syncLoggedInUserData={this.syncLoggedInUserData.bind(this)} />
        <div className="inner-container">
          <Topbar ref="topbarComponent" toggleSidebar={this.toggleSidebar.bind(this)} />
          <div className="table-responsive-sm mt-3">
            <div className="row mb-3 page-header">
              <div className="col-6 col-md-9 col-lg-9 col-xl-9 text-left">
                <h1>Plant & Machinery</h1>
              </div>
              <div className="col-6 col-md-3 col-lg-3 col-xl-3 text-right">
                {(this.state.accessLevels.indexOf(301) > -1) ?
                  <button className="custom-btn" onClick={this.togglePopup.bind(this)}> Add Item</button>
                  : null}
                {this.state.showPopup ?
                  <PopupManageInventory itemId=''
                    closePopup={this.togglePopup.bind(this)} getInventoryItems={this.getInventoryItems.bind(this)}
                  />
                  : null
                }
              </div>
            </div>

            {(this.state.inventoryItems.length > 0) &&

              <div className="row">
                {this.state.inventoryItems.map((value, index) => {
                  return (
                    <div key={index} className="col-12 col-sm-3 col-md-3">
                      <Link className="clickable-block" to={{ pathname: '/manage-inventory/' + value._id }} >
                        <div className="org-inventory">
                          <h5 className="overflow-ellipsis">{value.itemName}</h5>
                        </div>
                      </Link>

                      <div className="more-menu">
                        <span></span>
                        <ul>
                          {(this.state.accessLevels.indexOf(303) > -1) ?
                            <li onClick={() => this.deleteInventoryItem(index, value)}>Delete</li>
                            : null}
                        </ul>
                      </div>
                    </div>
                  )
                })}
              </div>

            }
            {(this.state.inventoryItems.length === 0) && <p className="no-items-text">No items found!</p>}
          </div>
        </div>
        <div className="clear"></div>
      </div>
    );
  }
}

export default Inventory;
