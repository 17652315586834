import React, { Component } from "react";
import moment from 'moment';
import axios from 'axios';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';

import '../project.css';
import ArrowBlack from '../../../../assets/img/right-arrow.svg';
import noIcon from '../../../../assets/img/icons/no.svg';
import yesIcon from '../../../../assets/img/icons/yes.svg';
import PopupViewLabourDetails from "../popups/view-labour-details";
import PopupEditLabourDetails from "../popups/manage-add-labour";
import * as GLOBAL from '../../../../global';

class TabLabourTracking extends Component {

   constructor(props) {
     
      super(props);
      this.state = {
         activeMember: null,
         showPopup: false
      };
   };

   openPopup(member) {
      let params = {
         showPopup: true,
         activeMember: member
      };
      this.setState(params);
   }
   openEditPopup(member) {
      let params = {
         showEditPopup: true,
         activeMember: member
      };
      this.setState(params);
   }

   closePopup() {
      this.props.getMemberDetails(this.state.activeMember._id);
      this.setState({
         showPopup: false,
         activeMember: null
      });
   }

   closeEditPopup() {
      this.props.getMemberDetails(this.state.activeMember._id);
      this.setState({
         showEditPopup: false,
         activeMember: null
      });
   }

   deleteMember(member) {
      confirmAlert({
         title: 'Confirm to delete',
         message: 'Are you sure to do this?',
         buttons: [
            {
               label: 'Cancel',
               onClick: () => { }
            },
            {
               label: 'Confirm',
               onClick: () => {
                  this.setState({
                     wsInProgress: true
                  }, () => {

                     axios.post(GLOBAL.SERVER_URL + "/api/delete-labour-member", member).then(res => {
                        this.setState({
                           wsInProgress: false
                        }, () => {
                           if (res.data.errorCode === 0) {
                              toast.success(res.data.message, { delay: 150 });
                              this.props.getMembers(this.props.projectId);
                           } else {
                              toast.error(res.data.message, { delay: 150 });
                           }
                        });
                     }).catch(error => {
                        toast.error("Error occured. Please try again!", { delay: 150 });
                     });
                  });
               }
            },
         ]
      });

   }

   render() {
      return (
         <div className="main-div-consumable col-12">
            {(this.props.members.length > 0) ?
               <div className="row track-labour">
                  {this.state.showPopup ?
                     <PopupViewLabourDetails userRoleId={this.props.userRoleId} projectId={this.props.projectId} getMembers={this.props.getMembers.bind(this)} getMemberDetails={this.props.getMemberDetails.bind(this)} activeMember={this.state.activeMember} closePopup={this.closePopup.bind(this)}
                     />
                     : null
                  },
                  {this.state.showEditPopup ?
                     <PopupEditLabourDetails userRoleId={this.props.userRoleId} projectId={this.props.projectId} getMembers={this.props.getMembers.bind(this)} getMemberDetails={this.props.getMemberDetails.bind(this)} activeMember={this.state.activeMember} closePopup={this.closeEditPopup.bind(this)}
                     />
                     : null
                  }
                  <table className="table custom-table labour-table table-responsive-lg">
                     <thead className="labour-th">
                        <tr>
                           <th className="bold-head width-50">#</th>
                           <th className="bold-head width-50"></th>
                           <th className="bold-head text-left">Name</th>
                           <th className="bold-head">Designation</th>
                           {this.props.weekdays.map((value, index) => (
                              <th key={index}>
                                 <p>{moment(value).format("DD")}</p>
                                 <p className="uppercase">{moment(value).format("ddd")}</p>
                              </th>
                           ))}
                           <th className="bold-head">
                              <p>Total</p>
                              <p>Present Days</p>
                           </th>
                           <th className="bold-head">
                              <p>Balance</p>
                              <p>Amount</p>
                           </th>
                           <th className="bold-head"></th>
                        </tr>
                     </thead>
                     <tbody>
                        {this.props.members.map((member, index) => {
                           return (
                              <tr key={index}>
                                 <td>
                                   {index+1}
                                 </td>
                                 <td>
                                    <div className="member-img labour-member-img">
                                       {member.firstName.charAt(0)}{member.lastName ? member.lastName.charAt(0) : ''}
                                    </div>
                                 </td>
                                 <td className="text-left">
                                    <p>{member.firstName} {member.lastName}</p>
                                 </td>
                                 <td>
                                    <p>{member.designationDetails[0].name}</p>
                                 </td>
                                 {member.attendanceDetails.map((attendance, index) => (
                                    attendance.attendanceStatus ?
                                       <td key={index}><img src={yesIcon} alt="Present" /></td>
                                       : <td key={index}><img src={noIcon} alt="Absent" /></td>
                                 ))}
                                 <td>
                                    <p>{this.props.presentDaysCount[index]}</p>
                                 </td>
                                 <td>
                                    <p>₹ {parseFloat(member.metaData[0] ? member.finalAmount : 0).toFixed(0)}</p>
                                 </td>
                                 <td>
                                    <button className="labour-link" onClick={() => this.openPopup(member)}>
                                       <img src={ArrowBlack} alt="View Details" />
                                    </button>
                                 </td>
                                 <td>
                                    <div className="more-menu edit-menu">
                                       <span></span>
                                       <ul>
                                          <li onClick={() => this.openEditPopup(member)}>Edit</li>
                                          <li onClick={() => this.deleteMember(member)}>Delete</li>
                                       </ul>
                                    </div>
                                 </td>
                              </tr>
                           )
                        })
                        }
                        </tbody>
                        <tbody>

                              <tr>
                                 <th colSpan="4" className="text-left">Total Employees Present:</th>
                                 <th>{this.props.employeePresentDays[0]}</th>
                                 <th>{this.props.employeePresentDays[1]}</th>
                                 <th>{this.props.employeePresentDays[2]}</th>
                                 <th>{this.props.employeePresentDays[3]}</th>
                                 <th>{this.props.employeePresentDays[4]}</th>
                                 <th>{this.props.employeePresentDays[5]}</th>
                                 <th>{this.props.employeePresentDays[6]}</th>
                                 <th></th>
                                 <th></th>
                                 <th></th>
                              </tr>
                     </tbody>
                  </table>
               </div>
               : null
            }
            <div className="total-button">
               <p className="total-style"> Total: <span className="amout-style">INR {parseFloat(this.props.totalAmount).toFixed(2).replace(/\.00$/, '')}</span></p>
            </div>
         </div>
      );
   }
}
export default TabLabourTracking;

