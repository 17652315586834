import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { ToastContainer, toast, cssTransition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './App.css';

import Login from './app/guest/login';
import Dashboard from './app/admin/dashboard/dashboard';
import Inventory from './app/admin/inventory/inventory';
import ManageInventory from './app/admin/inventory/manage-inventory';
import Users from './app/admin/users/users';
import Projects from './app/admin/projects/projects';
import Organization from './app/admin/projects/organization';
import Checklists from './app/admin/projects/checklists';
import SingleChecklist from './app/admin/projects/single-checklist';
import Consumables from './app/admin/projects/consumables';
import SingleConsumable from './app/admin/projects/single-consumable';
import LabourTracking from './app/admin/projects/labour-tracking';
import Orders from './app/admin/orders/orders';
import SettingsChecklist from './app/admin/settings/settings-checklist';
import SettingsUserRoles from './app/admin/settings/settings-user-role';
import SettingsUnits from './app/admin/settings/settings-unit';
import SettingsDesignations from './app/admin/settings/settings-designation';

const fadeIn = cssTransition({
  enter: 'fadeInUp',
  exit: 'fadeOut',
  duration: [500, 100]
});

function App() {
  return (
    <Router >
      <div className="App">
        <Route path="/" exact component={Login} />
        <Route path="/dashboard" exact component={Dashboard} />
        <Route path="/inventory" exact component={Inventory} />
        <Route path="/manage-inventory/:itemId" exact component={ManageInventory} />
        <Route path="/users" exact component={Users} />
        <Route path="/projects" exact component={Projects} />
        <Route path="/organization/:projectId" exact component={Organization} />
        <Route path="/checklists/:projectId" exact component={Checklists} />
        <Route path="/single-checklist/:projectId/:projectChecklistId" exact component={SingleChecklist} />
        <Route path="/consumables/:projectId" exact component={Consumables} />
        <Route path="/single-consumable/:projectId/:consumableId" exact component={SingleConsumable} />
        <Route path="/labour-tracking/:projectId" exact component={LabourTracking} />
        <Route path="/orders" exact component={Orders} />
        <Route path="/settings/checklists" exact component={SettingsChecklist} />
        <Route path="/settings/user-roles" exact component={SettingsUserRoles} />
        <Route path="/settings/units" exact component={SettingsUnits} />
        <Route path="/settings/designations" exact component={SettingsDesignations} />
        <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} pauseOnFocusLoss={true} limit={1} closeButton={false} transition={fadeIn} autoClose={4000} />
      </div>
    </Router>
  );
}


export default App;
