import React from 'react';
import axios from 'axios';
import cookie from 'react-cookies';
import { toast } from 'react-toastify';

import * as GLOBAL from '../../../../global';
import '../project.css';

class PopupManageOrganizationMember extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      _id: '',
      projectId: '',
      memberId: '',
      designationId: '',
      users: [],
      designations: [],
      wsInProgress: false,
      token: cookie.load('_atclToken')
    }

  }

  componentDidMount() {
    if (this.props.projectId !== '') {
      this.setState({
        projectId: this.props.projectId
      });
      if (this.props.activeMember) {
        this.setState({
          _id: this.props.activeMember._id,
          memberId: this.props.activeMember.userId,
          designationId: this.props.activeMember.designationData[0]._id
        });
        this.getDesignations();
      } else {
        this.filterUsersByProjectId(this.props.projectId);
      }
    }
  }

  filterUsersByProjectId = (projectId) => {
    toast.dismiss();
    axios.get(GLOBAL.SERVER_URL + "/api/get-filtered-users-by-project-id?token=" + this.state.token + "&projectId=" + projectId).then(res => {
      if (res.data.errorCode === 0) {
        if (res.data.response.length > 0) {
          this.setState({
            users: res.data.response

          });
          this.getDesignations();
        } else {
          toast.success("This project holds all members of the system", { delay: 150 });
          this.props.closePopup();
        }
      } else {
        toast.error(res.data.message, { delay: 150 });
        this.props.closePopup();
      }
    });
  }

  getDesignations = () => {
    axios.get(GLOBAL.SERVER_URL + "/api/get-designations?token=" + this.state.token).then(res => {
      this.setState({
        designations: res.data.response
      });
    });
  }


  validate = () => {
    if (!this.state.memberId && !this.props.activeMember) {
      toast.error('Member field is required', { delay: 150 });
      return false;
    } else if (!this.state.designationId) {
      toast.error('Member designation field is required', { delay: 150 });
      return false
    } else {
      return true;
    }
  }

  saveMember = (event) => {
    event.preventDefault();
    if (!this.state.wsInProgress) {
      toast.dismiss();
      const isValid = this.validate();
      if (isValid) {
        this.setState({
          wsInProgress: true
        }, () => {
          this.params = {
            _id: this.state._id,
            projectId: this.state.projectId,
            memberId: this.state.memberId,
            designationId: this.state.designationId,
            token: this.state.token
          }

          axios.post(GLOBAL.SERVER_URL + "/api/save-project-member", this.params).then(res => {
            this.setState({
              wsInProgress: false
            }, () => {
              if (res.data.errorCode === 0) {
                toast.success(res.data.message, { delay: 150 });
                this.props.getProjectMembers(this.state.projectId);
                this.props.closePopup();
              } else {
                toast.error(res.data.message, { delay: 150 });
              }
            });
          }).catch(error => {
            toast.error("Error occured. Please try again!", { delay: 150 });
          });
        });
      }
    }
  }

  render() {
    return (
      <div className="popup">
        <div className="popup_inner popup_inner_small">
          <div className="page-header">
            <div className="material-card text-left">
              <div className="popup-heads">
                <div className="row">
                  <div className="col-12 col-sm-6 col-xl-6">
                    <h3 className="popup-title">{this.state._id ? 'Update' : 'Add'} Member</h3>
                  </div>
                  <div className="col-12 col-sm-6 col-xl-6">
                    <button onClick={this.saveMember} className="entry-save project-save">Save</button>
                    <button onClick={this.props.closePopup} className="entry-close project-close">Cancel</button>
                  </div>
                </div>
              </div>
              <div className="popup-body p-b-15">
                <div className="form-group">
                  <label htmlFor="member">Member <span className="required">*</span></label>
                  {!this.props.activeMember ?
                    <select className="form-control" id="member" value={this.state.memberId} onChange={(e) => this.setState({ memberId: e.target.value })} >
                      <option value="">Select</option>
                      {this.state.users.map((user, index) => {
                        return (((this.props.superAdminUserId !== user._id) && !user.deleted) ? <option value={user._id} key={index}>{user.firstName} {user.lastName}</option> : null)
                      })}
                    </select>
                    : <div className="form-control disabled" id="member">{this.props.activeMember.memberData[0].firstName} {this.props.activeMember.memberData[0].lastName}</div>
                  }
                </div>

                <div className="form-group">
                  <label htmlFor="designation">Designation <span className="required">*</span></label>
                  <select className="form-control" id="designation" value={this.state.designationId} onChange={(e) => this.setState({ designationId: e.target.value })} >
                    <option value="">Select</option>
                    {this.state.designations.map((designation, index) => {
                      return (<option value={designation._id} key={index}>{designation.name}</option>)
                    })}
                  </select>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

}

export default PopupManageOrganizationMember; 
