import React, { Component } from "react";
import axios from 'axios';
import cookie from 'react-cookies';
import { toast } from 'react-toastify';

import * as GLOBAL from '../../../global';

class Activities extends Component {

  constructor(props) {
    super(props)
    this.state = {
      filterProjectId: '',
      activities: [],
      token: cookie.load('_atclToken')
    }
  }

  componentDidMount() {
    if (this.props.compData._id !== '') {
      this.getInventoryActivities(this.props.compData._id, '');
    }
  }

  getInventoryActivities(itemId, projectId) {
    toast.dismiss();
    this.setState({
      filterProjectId: projectId
    });
    axios.get(GLOBAL.SERVER_URL + "/api/get-inventory-activities?token=" + this.state.token + "&itemId=" + itemId + "&projectId=" + projectId).then(res => {
      if (res.data.errorCode === 0) {
        this.setState({
          activities: res.data.response
        });
      } else {
        toast.error(res.data.message, { delay: 150 });
      }
    }).catch(error => {
      toast.error('Error while retrieving inventory activities', { delay: 150 });
    })
  }

  render() {
    return (

      <div className="activity-head">
        <div className="row">
          <div className="col-12 col-sm-6 text-left">
            <h3 className="order-item-name-overflow">{this.props.compData.itemName} <span className="items-span order-item-name-overflow">{this.props.compData.pmCode}</span></h3>
          </div>
          <div className="col-12 col-sm-6">
            <div className="selection-div">
              <span>Search by project</span>
              <select name="projectId" value={this.state.filterProjectId} onChange={(e) => this.getInventoryActivities(this.props.compData._id, e.target.value)}>
                <option value="">All projects</option>
                {this.props.compData.projectLocations.map((value, index) => {
                  return (
                    <option value={value._id} key={index}>{value.projectName} {(value._id === this.props.compData.workshopProjectId) ? '(WS)' : null}</option>
                  )
                })}
              </select>
            </div>
          </div>
        </div>

        {(this.state.activities.length === 0) && <p className="no-items-text">No activities found!</p>}

        {this.state.activities.map((aItem, index) => {
          return (
            <div key={index} className="single-item activity-item order-item-name-overflow">
              <p className="meta-details">
                {aItem.authorData[0].firstName} {aItem.authorData[0].lastName} on &nbsp;
                {new Intl.DateTimeFormat('en-GB', {
                  month: 'long',
                  day: '2-digit',
                  year: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: true
                }).format(new Date(aItem.updatedDateTime))}
                {(aItem.projectData.length > 0) &&
                  <span className="activity-location">{aItem.projectData[0].projectName}</span>
                }
              </p>
              <div className="clear"></div>
              <p dangerouslySetInnerHTML={{ __html: aItem.description }}></p>
            </div>
          )
        })}

      </div>

    );
  }
}

export default Activities;
