import React, { Component } from "react";
import axios from 'axios';
import cookie from 'react-cookies';
import { toast } from 'react-toastify';
import { Link, Redirect } from 'react-router-dom';
import * as GLOBAL from '../../../global';
import './sidebar-topbar.css';

class Sidebar extends Component {

    constructor(props) {
        super(props)

        this.state = {
            activeMenu: '',
            accessLevels: [],
            showSidebar: false,
            token: cookie.load('_atclToken')
        }
    }

    componentDidMount() {
        if (this.state.token && typeof this.state.token != 'undefined') {
            this.getUserDetails();
            this.setState({
                activeMenu: this.props.activeMenu
            })
        }
    }

    getUserDetails = () => {
        toast.dismiss();
        axios.get(GLOBAL.SERVER_URL + "/api/get-user?token=" + this.state.token + "&loggedInUser=1").then(res => {
            if (res.data.errorCode === 0) {
                this.props.syncLoggedInUserData(res.data.response);
                this.setState({
                    accessLevels: res.data.response.roleDetails[0].accessLevels
                });
            } else {
                toast.error(res.data.response.message, { delay: 150 });
            }
        }).catch(error => {
            toast.error("Unauthorized access!", { delay: 150 });
            this.logOut();
        })
    }

    toggleSidebar() {
        this.setState({
            showSidebar: !this.state.showSidebar
        });
    }

    logOut() {
        cookie.remove('_atclToken', { path: '/' });
        this.setState({
            token: null
        });
    }

    render() {

        if (!this.state.token) {
            return <Redirect to="/" push={true} />;
        }

        return (

            <div className={"sidebar" + (this.state.showSidebar ? " active-sidebar" : "")}>
                <img className="logo-sidebar" src={require("../../../assets/img/logo.png")} alt="ATCL logo" />
                <ul>
                    <li className={"dashboard-menu" + (this.state.activeMenu === "dashboard" ? " active-menu-item" : "")}>
                        <Link to='/dashboard'>
                            <span className="active-icon"></span>
                            <span className="inactive-icon"></span>
                            Dashboard
                        </Link>
                    </li>
                    {(this.state.accessLevels.indexOf(300) > -1) ?
                        <li className={"plant-machinery-menu" + (this.state.activeMenu === "inventory" ? " active-menu-item" : "")}>
                            <Link to='/inventory'>
                                <span className="active-icon"></span>
                                <span className="inactive-icon"></span>
                            Plant & Machinery
                        </Link>
                        </li>
                        : null}
                    {(this.state.accessLevels.indexOf(200) > -1) ?
                        <li className={"projects-menu" + (this.state.activeMenu === "projects" ? " active-menu-item" : "")}>
                            <Link to='/projects'>
                                <span className="active-icon"></span>
                                <span className="inactive-icon"></span>
                            Projects
                        </Link>
                        </li>
                        : null}
                    {(this.state.accessLevels.indexOf(307) > -1) ?
                        <li className={"orders-menu" + (this.state.activeMenu === "orders" ? " active-menu-item" : "")}>
                            <Link to='/orders'>
                                <span className="active-icon"></span>
                                <span className="inactive-icon"></span>
                            Orders
                        </Link>
                        </li>
                        : null}
                    {(this.state.accessLevels.indexOf(100) > -1) ?
                        <li className={"users-menu" + (this.state.activeMenu === "users" ? " active-menu-item" : "")}>
                            <Link to='/users'>
                                <span className="active-icon"></span>
                                <span className="inactive-icon"></span>
                            Users
                        </Link>
                        </li>
                        : null}
                    {(this.state.accessLevels.indexOf(400) > -1) ?
                        <li className={"settings-menu" + (this.state.activeMenu === "settings" ? " active-menu-item" : "")}>
                            <Link to='/settings/checklists'>
                                <span className="active-icon"></span>
                                <span className="inactive-icon"></span>
                            Settings
                        </Link>
                        </li>
                        : null}
                </ul>

            </div>

        );
    }
}

export default Sidebar;
