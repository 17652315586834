import React, { Component } from "react";
import update from 'immutability-helper';
import axios from 'axios';
import cookie from 'react-cookies';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';

import 'react-confirm-alert/src/react-confirm-alert.css';
import * as GLOBAL from '../../../global';
import PopupManageComment from './popups/manage-comment-popup';

class InventoryLocations extends Component {

  constructor(props) {
    super(props)
    this.state = {
      _id: '',
      maxQuantity: 0,
      type: '',
      unit: '',
      itemLocations: [],
      itemLocationCountMismatch: false,
      itemLocationCountMismatchValue: 0,
      projectLocations: [],
      activeComment: null,
      showPopup: false,
      wsInProgress: false,
      token: cookie.load('_atclToken')
    }
  }

  componentDidMount() {
    if (this.props.compData._id !== '') {
      this.setState({
        _id: this.props.compData._id,
        maxQuantity: this.props.compData.quantity,
        type: this.props.compData.type,
        unit: this.props.compData.unit,
        projectLocations: this.props.compData.projectLocations
      });
      this.getItemLocations(this.props.compData._id)
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.compData.quantity !== prevProps.compData.quantity) {
      this.setState({
        maxQuantity: this.props.compData.quantity
      }, () => {
        this.getItemLocations(this.props.compData._id);
      });
    }
  }

  openPopup(item) {
    let params = {
      showPopup: true,
      activeComment: {
        commentId: '',
        itemId: this.props.compData._id,
        projectId: item.projectId,
        comment: ''
      }
    };
    this.setState(params);
  }

  closePopup() {
    this.setState({
      showPopup: false,
      activeComment: null
    });
  }

  checkLocationQuantityStatistics() {
    let values = this.state.itemLocations.map((item) => parseFloat(item['total']) || 0);
    let totalCount = values.reduce((a, b) => a + b, 0);
    let mismatchValue = this.state.maxQuantity - totalCount;
    if (parseFloat(mismatchValue) % 1 != 0) {
      mismatchValue = parseFloat(mismatchValue).toFixed(2);
    }
    if (parseFloat(totalCount) !== parseFloat(this.state.maxQuantity)) {
      this.setState({
        itemLocationCountMismatch: true,
        itemLocationCountMismatchValue: mismatchValue
      });
    } else {
      this.setState({
        itemLocationCountMismatch: false,
        itemLocationCountMismatchValue: mismatchValue
      });
    }
  }

  getItemLocations(itemId) {

    toast.dismiss();
    axios.get(GLOBAL.SERVER_URL + "/api/get-inventory-locations?token=" + this.state.token + "&itemId=" + itemId).then(res => {
      if (res.data.errorCode === 0) {
        this.setState({
          itemLocations: res.data.response
        });
        if (this.state.itemLocations.length === 0) {
          this.addRow();
        }
        this.checkLocationQuantityStatistics();
        this.checkNullLocations();
      }
    }).catch(error => {
      toast.error('Error while retrieving inventory items', { delay: 150 });
    })

  }

  handleChange(index, e) {
    var locObject = this.state.itemLocations[index];
    locObject[e.target.name] = e.target.value;
    const updatedlocation = update(this.state.itemLocations, { $splice: [[index, 1, locObject]] });
    this.setState({ itemLocations: updatedlocation });
  }

  handleQtyChange(index, event, e) {
    var locObject = this.state.itemLocations[index];
    if (event === 'change') {
      locObject[e.target.name] = e.target.value;
    } else {
      locObject[e.target.name] = e.target.value ? ((parseFloat(e.target.value) < 0) ? 0 : e.target.value) : 0;
      if (parseFloat(locObject[e.target.name]) % 1 != 0) {
        locObject[e.target.name] = parseFloat(locObject[e.target.name]).toFixed(2);
      }
    }
    if (parseFloat(locObject[e.target.name]) >= 0) {
      locObject.total = (locObject.good ? parseFloat(locObject.good) : 0) + (locObject.repair ? parseFloat(locObject.repair) : 0) + (locObject.scrap ? parseFloat(locObject.scrap) : 0);
    }
    if (parseFloat(locObject.total) % 1 != 0) {
      locObject.total = parseFloat(locObject.total).toFixed(2);
    }
    const updatedlocation = update(this.state.itemLocations, { $splice: [[index, 1, locObject]] });
    this.setState({ itemLocations: updatedlocation });
  }

  getQuantitySum = () => {
    let values = this.state.itemLocations.map((item) => parseInt(item['total']) || 0);
    return values.reduce((a, b) => a + b);
  }

  hasDuplicates(arr) {
    return new Set(arr).size !== arr.length;
  }

  projectValidation = () => {
    let projArray = [];
    this.state.itemLocations.forEach(function (location) {
      projArray.push(location.projectId);
    });
    return this.hasDuplicates(projArray);
  }

  validate = (item) => {
    if (!item.projectId) {
      toast.error('Project cannot be blank', { delay: 150 });
      return false;
    } else if (this.projectValidation()) {
      toast.error('Duplicate project entries are not allowed', { delay: 150 });
      return false;
    } else if (this.getQuantitySum() > parseInt(this.state.maxQuantity)) {
      toast.error('Maximum ' + this.state.maxQuantity + ' items are allowed to update under locations', { delay: 150 });
      return false;
    } else {
      return true;
    }
  }

  checkNullLocations() {
    
    let Loc = this.state.itemLocations;
    for (let i = 0; i < Loc.length; i++) {
      if(Loc[i].good == 0 && Loc[i].repair == 0 && Loc[i].scrap == 0){
        this.removeNullLocations(Loc[i]._id)
      }
   }
   toast.dismiss();
  //  toast.success('Zero quantity locations removed', { delay: 150 });
 }
 removeNullLocations(id){
  let params = {
    _id: id,
    type: this.state.type,
    token: this.state.token
  }
  axios.post(GLOBAL.SERVER_URL + "/api/delete-inventory-location",params).then(res => {

    if (res.data.errorCode === 0) {
      this.getItemLocations(this.state._id);
      this.props.getItemUniqueLocations(this.state._id);
    }
  }).catch(error => {
    toast.error('Error while retrieving inventory items', { delay: 150 });
  })

 }
  addRow() {
    this.setState({
      itemLocations: this.state.itemLocations.concat({
        _id: '',
        projectId: '',
        good: 0,
        repair: 0,
        scrap: 0,
        total: 0,
        viewOnly: false
      })
    });
  }

  editRow(index, e) {
    var locObject = this.state.itemLocations[index];
    locObject.viewOnly = false;
    const updatedlocation = update(this.state.itemLocations, { $splice: [[index, 1, locObject]] });
    this.setState({ itemLocations: updatedlocation });
  }

  saveRow(index, e) {
    if (!this.state.wsInProgress) {
      var locObject = this.state.itemLocations[index];
      toast.dismiss();
      const isValid = this.validate(locObject);
      if (isValid) {

        this.setState({
          wsInProgress: true
        }, () => {
          this.params = {
            _id: locObject._id,
            itemId: this.state._id,
            projectId: locObject.projectId,
            good: locObject.good,
            repair: locObject.repair,
            scrap: locObject.scrap,
            total: locObject.total,
            type: this.state.type,
            token: this.state.token
          }

          axios.post(GLOBAL.SERVER_URL + "/api/save-inventory-location", this.params).then(res => {

            this.setState({
              wsInProgress: false
            }, () => {
              if (res.data.errorCode === 0) {
                toast.success(res.data.message, { delay: 150 });
                this.getItemLocations(this.state._id);
                this.props.getItemUniqueLocations(this.state._id);
              } else {
                toast.error(res.data.message, { delay: 150 });
              }
            });

          }).catch(error => {
            toast.error("Error occured. Please try again!", { delay: 150 });
          });
        });

      }
    }
  }

  removeRow(index, item) {
    toast.dismiss();
    if (item._id) {
      if (!this.state.wsInProgress) {
        confirmAlert({
          title: 'Confirm to delete',
          message: 'Are you sure to do this?',
          buttons: [
            {
              label: 'Cancel',
              onClick: () => { }
            },
            {
              label: 'Confirm',
              onClick: () => {

                this.setState({
                  wsInProgress: true
                }, () => {
                  let locObject = this.state.itemLocations[index];
                  this.params = {
                    _id: locObject._id,
                    type: this.state.type,
                    token: this.state.token
                  }
                  axios.post(GLOBAL.SERVER_URL + "/api/delete-inventory-location", this.params).then(res => {
                    this.setState({
                      wsInProgress: false
                    }, () => {
                      if (res.data.errorCode === 0) {
                        this.setState({
                          itemLocations: this.state.itemLocations.filter((_, i) => i !== index)
                        }, () => {
                          this.props.getItemUniqueLocations(this.state._id);
                          this.checkLocationQuantityStatistics();
                          toast.success(res.data.message, { delay: 150 });
                        });
                      } else {
                        toast.error(res.data.message, { delay: 150 });
                      }
                    });
                  }).catch(error => {
                    toast.error("Error occured. Please try again!", { delay: 150 });
                  });
                });

              }
            },
          ]
        });
      }
    } else {
      this.setState({
        itemLocations: this.state.itemLocations.filter((_, i) => i !== index)
      });
    }
  }

  render() {
   
    return (
        
      <div className="items-details">
        {this.state.itemLocationCountMismatch ? <p className="error-label">{this.props.compData.itemName} total count on different locations does not match with total quantity <span>
          {(this.state.itemLocationCountMismatchValue > 0 ) ? this.state.itemLocationCountMismatchValue + " " + this.state.unit + " missing" : Math.abs(this.state.itemLocationCountMismatchValue) + " " + this.state.unit + " extra"}</span></p> : null}
        <table className="table table-borderless item-locations-table table-responsive-sm items-inventory-tab">
          <thead>
            <tr>
              <th scope="col" className="width-200">LOCATIONS</th>
              <th scope="col" className="text-center">GOOD</th>
              <th scope="col" className="text-center">REPAIR</th>
              <th scope="col" className="text-center">SCRAP</th>
              <th scope="col" className="width-100 text-center">TOTAL</th>
              {(this.props.compData.accessLevels.indexOf(306) > -1) ?
                <th scope="col" className="width-60"></th>
                : null}
              {(this.props.compData.accessLevels.indexOf(304) > -1) ?
                <th scope="col" className="width-60"></th>
                : null}
              {(this.props.compData.accessLevels.indexOf(305) > -1) ?
                <th scope="col" className="width-60"></th>
                : null}
            </tr>
          </thead>
          <tbody>
            {this.state.itemLocations.map((itemLoc, index) => {
              return (
                <tr key={index}>
                  <td>
                    {itemLoc.viewOnly ?
                      <div className="inventory-location disabled custom-form-control order-item-name-overflow scroll">{itemLoc.projects[0].projectName} {(itemLoc.projectId === this.props.compData.workshopProjectId) ? <span className="small-blue-text">(WS)</span> : null}</div>
                      :
                      <select className="inventory-location" name="projectId" value={itemLoc.projectId} onChange={this.handleChange.bind(this, index)}>
                        <option value="">Select location</option>
                        {this.state.projectLocations.map((value, index) => {
                          return (
                            <option value={value._id} key={index}>{value.projectName} {(value._id === this.props.compData.workshopProjectId) ? '(WS)' : null}</option>
                          )
                        })}
                      </select>
                    }
                  </td>
                  <td>
                    {itemLoc.viewOnly ?
                      <div className="form-control text-center disabled">{itemLoc.good}</div>
                      :
                      <input className="form-control text-center" name="good" type="number" min="0" max="10000" value={itemLoc.good} onChange={this.handleQtyChange.bind(this, index, 'change')} onBlur={this.handleQtyChange.bind(this, index, 'blur')} />
                    }
                  </td>
                  <td>
                    {itemLoc.viewOnly ?
                      <div className="form-control text-center disabled">{itemLoc.repair}</div>
                      :
                      <input className="form-control text-center" name="repair" type="number" min="0" max="10000" value={itemLoc.repair} onChange={this.handleQtyChange.bind(this, index, 'change')} onBlur={this.handleQtyChange.bind(this, index, 'blur')} />
                    }
                  </td>
                  <td>
                    {itemLoc.viewOnly ?
                      <div className="form-control text-center disabled">{itemLoc.scrap}</div>
                      :
                      <input className="form-control text-center" name="scrap" type="number" min="0" max="10000" value={itemLoc.scrap} onChange={this.handleQtyChange.bind(this, index, 'change')} onBlur={this.handleQtyChange.bind(this, index, 'blur')} />
                    }
                  </td>

                  <td>
                    <div className="form-control text-center disabled">
                      {itemLoc.total}
                    </div>
                  </td>


                  {
                    (this.props.compData.accessLevels.indexOf(306) > -1) ?
                      <td>
                        {itemLoc._id ?
                          <button className="item-location-button comment-button" onClick={() => this.openPopup(itemLoc)}></button>
                          :
                          <button className="item-location-button comment-button"></button>
                        }
                      </td>
                      : null
                  }
                  {
                    (this.props.compData.accessLevels.indexOf(304) > -1) ?
                      <td>
                        {itemLoc.viewOnly ?
                          <button className="item-location-button location-edit-button" onClick={this.editRow.bind(this, index)}></button>
                          :
                          <button className="item-location-button save-button" onClick={this.saveRow.bind(this, index)}></button>
                        }
                      </td>
                      : null
                  }
                  {
                    (this.props.compData.accessLevels.indexOf(305) > -1) ?
                      <td>
                        <button className="item-location-button delete-button" onClick={() => this.removeRow(index, itemLoc)}></button>
                      </td>
                      : null
                  }
                </tr>
              )
            })}
          </tbody>
        </table>
        {
          (this.props.compData.accessLevels.indexOf(303) > -1) ?
            <button className="add-more-inventory" onClick={this.addRow.bind(this)}>Add More +</button>
            : null
        }
        <div className="clear"></div>

        {
          this.state.showPopup ?
            <PopupManageComment activeComment={this.state.activeComment} closePopup={this.closePopup.bind(this)} />
            : null
        }

      </div >

    );
  }
}

export default InventoryLocations;
