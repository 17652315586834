import React, { Component } from "react";
import axios from 'axios';
import cookie from 'react-cookies';
import { toast } from 'react-toastify';
import update from 'immutability-helper';
import { Redirect } from 'react-router-dom';
import DatePicker from "react-datepicker";
import moment from 'moment';
import ReactExport from "react-export-excel-fixed-xlsx";

import * as GLOBAL from '../../../global';
import Sidebar from '../includes/sidebar';
import Topbar from '../includes/topbar';
import ProjectTabs from './project-tabs';
import LabourTrackingDetails from './includes/labour-tracking';
import PopupManageLabourMember from './popups/manage-add-labour';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class LabourTracking extends Component {

   constructor(props) {
      super(props)
      this.state = {
         projectId: '',
         activeMember: null,
         members: [],
         selectedYear: new Date(),
         selectedMonth: new Date(),
         selectedWeek: 1,
         totalweeks: 4,
         weekdays: [],
         presentDays: [],
         exportArray: [],
         showPopup: false,
         showEditPopup: false,
         loggedInUserId: '',
         userRoleId: null,
         accessLevels: [],
         unauthorizedAccess: false,
         token: cookie.load('_atclToken'),
         accno: null,
         ifsc: null,
         employeePresentDays :[]
      };
   }

   componentDidMount() {
      if (this.props.match.params && this.props.match.params.projectId) {
         this.setState({
            projectId: this.props.match.params.projectId
         });
         this.refs.projectTabsComponent.syncProjectId(this.props.match.params.projectId);

         var date = new Date();
         this.setState({
            selectedWeek: parseInt(this.getWeekOfMonth(date))
         });
         this.calculateWeeks();
      } else {
         this.setState({
            projectId: null
         });
         this.refs.projectTabsComponent.syncProjectId(null);
      }
   }

   getWeekOfMonth(date) {
      var nth = 0;
      var timestamp = date.getTime();
      var month = date.getMonth();
      var m = month;
      while (m === month) {
         nth++;
         m = new Date(timestamp - nth * 604800000).getMonth();
      }
      return nth;
   }

   syncLoggedInUserData(user) {
      this.refs.topbarComponent.syncLoggedInUserData(user);
      this.setState({
         loggedInUserId: user._id,
         accessLevels: user.roleDetails[0].accessLevels,
         userRoleId: user.roleDetails[0]._id,
         unauthorizedAccess: (user.roleDetails[0].accessLevels.indexOf(200) === -1) ? true : false
      });
   }

   openPopup(member) {
      let params = {
         showPopup: true
      };
      if (member) {
         params.activeMember = member;
      }
      this.setState(params);
   }

   closePopup() {
      this.setState({
         showPopup: false,
         activeMember: null
      });
   }

   handleYearChange = date => {
      this.setState({
         selectedYear: date
      },
         () => {
            this.calculateWeeks()
         });
   };

   handleMonthChange = date => {
      this.setState({
         selectedMonth: date
      },
         () => {
            this.calculateWeeks()
         });
   };

   calculateWeeks() {//based on monday starts week
      var year = moment(this.state.selectedYear).format("YYYY");
      var month_number = moment(this.state.selectedMonth).format("M");

      //week start on sunday
      var firstOfMonth = new Date(year, month_number - 1, 1);
      var lastOfMonth = new Date(year, month_number, 0);
      var used = firstOfMonth.getDay() + lastOfMonth.getDate();

      this.setState({
         totalweeks: Math.ceil(used / 7)
      },
         () => {
            this.getDaysOfWeek()
         }
      );
   }

   handleWeekchange = event => {
      this.setState({
         selectedWeek: event.target.value
      },
         () => {
            this.getDaysOfWeek()
         }
      );
   };

   getDaysOfWeek() {
      var year = moment(this.state.selectedYear).format("YYYY");
      var month_number = moment(this.state.selectedMonth).format("M");

      var startDay = new Date(year + "-" + month_number + "-01").getDay();// 6 - Saturday
      var lastDate = moment(new Date(year, month_number - 1, 1, 0, 0, 0, 0)).endOf('month').format('DD');

      var counter = (7 - startDay) + 1;

      if (parseInt(this.state.selectedWeek) === 1) {

         var startOfWeek = 1;
         var endOfWeek = moment(new Date(year, month_number - 1, 1, 0, 0, 0, 0)).endOf('week').format('D');
         var weekdays = [];
         var firstdate = startOfWeek;
         var lastdate = endOfWeek;
         var currentDate = null;

         for (var i = firstdate; i <= lastdate; i++) {
            currentDate = new Date(year, month_number - 1, i, 0, 0, 0, 0);
            weekdays.push(moment(currentDate).format("L"));
            startDay = startDay + 1;
         }
         this.setState({
            weekdays: weekdays
         }, () => {
            this.getMembers(this.state.projectId);
         });

      } else if (parseInt(this.state.selectedWeek) === parseInt(this.state.totalweeks)) {

         firstdate = moment(new Date(year, month_number - 1, lastDate, 0, 0, 0, 0)).startOf('week').format('DD');
         startDay = 0;
         weekdays = [];
         for (i = firstdate; i <= lastDate; i++) {
            currentDate = new Date(year, month_number - 1, i, 0, 0, 0, 0);
            weekdays.push(moment(currentDate).format("L"));
            startDay = startDay + 1;
         }
         this.setState({
            weekdays: weekdays
         }, () => {
            this.getMembers(this.state.projectId);
         });

      } else {

         startDay = 0;
         weekdays = [];
         firstdate = parseInt((this.state.selectedWeek - 2) * 7) + counter;
         lastdate = parseInt(firstdate + 6);
         for (i = firstdate; i <= lastdate; i++) {
            currentDate = new Date(year, month_number - 1, i, 0, 0, 0, 0);
            weekdays.push(moment(currentDate).format("L"));
            startDay = startDay + 1;
         }
         this.setState({
            weekdays: weekdays
         }, () => {
            this.getMembers(this.state.projectId);
            this.getPresentDays();
         });

      }
   }

   syncExportArray = () => {
      let tempExportArray = [];
      this.state.members.forEach(function (member) {
      let a0 = (member.attendanceDetails[0] && member.attendanceDetails[0].ot) ? member.attendanceDetails[0].ot : 0;
      let a1 = (member.attendanceDetails[1] && member.attendanceDetails[1].ot) ? member.attendanceDetails[1].ot : 0;
      let a2 = (member.attendanceDetails[2] && member.attendanceDetails[2].ot) ? member.attendanceDetails[2].ot : 0;
      let a3 = (member.attendanceDetails[3] && member.attendanceDetails[3].ot) ? member.attendanceDetails[3].ot : 0;
      let a4 = (member.attendanceDetails[4] && member.attendanceDetails[4].ot) ? member.attendanceDetails[4].ot : 0;
      let a5 = (member.attendanceDetails[5] && member.attendanceDetails[5].ot) ? member.attendanceDetails[5].ot : 0;
      let a6 = (member.attendanceDetails[6] && member.attendanceDetails[6].ot) ? member.attendanceDetails[6].ot : 0;
      let totOt = a0 + a1 + a2 + a3 + a4 + a5 + a6
         tempExportArray.push({
            name: member.firstName + " " + member.lastName,
            designation: member.designationDetails[0].name,
            day1: (member.attendanceDetails[0] && member.attendanceDetails[0].attendanceStatus) ? "P" : "A",
            day1ot: (member.attendanceDetails[0] && member.attendanceDetails[0].ot) ? parseFloat(member.attendanceDetails[0].ot).toFixed(2) : 0,
            day2: (member.attendanceDetails[1] && member.attendanceDetails[1].attendanceStatus) ? "P" : "A",
            day2ot: (member.attendanceDetails[1] && member.attendanceDetails[1].ot) ? parseFloat(member.attendanceDetails[1].ot).toFixed(2) : 0,
            day3: (member.attendanceDetails[2] && member.attendanceDetails[2].attendanceStatus) ? "P" : "A",
            day3ot: (member.attendanceDetails[2] && member.attendanceDetails[2].ot) ? parseFloat(member.attendanceDetails[2].ot).toFixed(2) : 0,
            day4: (member.attendanceDetails[3] && member.attendanceDetails[3].attendanceStatus) ? "P" : "A",
            day4ot: (member.attendanceDetails[3] && member.attendanceDetails[3].ot) ? parseFloat(member.attendanceDetails[3].ot).toFixed(2) : 0,
            day5: (member.attendanceDetails[4] && member.attendanceDetails[4].attendanceStatus) ? "P" : "A",
            day5ot: (member.attendanceDetails[4] && member.attendanceDetails[4].ot) ? parseFloat(member.attendanceDetails[4].ot).toFixed(2) : 0,
            day6: (member.attendanceDetails[5] && member.attendanceDetails[5].attendanceStatus) ? "P" : "A",
            day6ot: (member.attendanceDetails[5] && member.attendanceDetails[5].ot) ? parseFloat(member.attendanceDetails[6].ot).toFixed(2) : 0,
            day7: (member.attendanceDetails[6] && member.attendanceDetails[6].attendanceStatus) ? "P" : "A",
            day7ot: (member.attendanceDetails[6] && member.attendanceDetails[6].ot) ? parseFloat(member.attendanceDetails[6].ot).toFixed(2) : 0,
            balance_amount: parseFloat(member.metaData[0] ? member.finalAmount : 0).toFixed(0),
            totalOTHours:parseFloat(totOt).toFixed(2),
            wage: member.wageRate != undefined ? parseFloat(member.wageRate).toFixed(2) : 0,
            otRate: member.otRate != undefined ? parseFloat(member.otRate).toFixed(2) : 0,
            totalWages: 0,
            extraAllowance: parseFloat(member.extraAllowance).toFixed(2),
            totalAmt: parseFloat(member.finalAmount).toFixed(0),
            totalOTAmt: 0,
            previousDue: member.advanceDues != undefined ? member.advanceDues.toFixed(2) : 0,
            loanPaid: member.newAdvance != undefined ? member.newAdvance.toFixed(2) : 0,
            advDeducted: member.advanceDeduction != undefined ? member.advanceDeduction.toFixed(2) : 0,
            weekAdvPaid: member.weeklyAdvance != undefined ? member.weeklyAdvance.toFixed(2) : 0,
            accno: member.accno != undefined ? member.accno.toString() : 0,
            ifsc: member.ifsc != undefined ? member.ifsc : 0,
         });
      });
      let attendanceStatus = []
      tempExportArray.forEach(function (tempArray) {
         attendanceStatus.push(
            tempArray.day1, tempArray.day1ot, tempArray.day2, tempArray.day2ot,
            tempArray.day3, tempArray.day3ot, tempArray.day4, tempArray.day4ot,
            tempArray.day5, tempArray.day5ot, tempArray.day6, tempArray.day6ot,
            tempArray.day7, tempArray.day7ot)
      });
      
      let attendanceStatusValue = this.chunkArray(attendanceStatus, 14)
      
      let attendanceStatusValueArray = [];
      attendanceStatusValue.forEach(function (attendanceStatus) {
         let count = attendanceStatus.filter((value) => value == "P").length;
         attendanceStatusValueArray.push(count)
      })
      tempExportArray.forEach(function (e, index) {
         if (typeof e === "object") {
            e["PrescentDays"] = attendanceStatusValueArray[index];
            e['totalWages'] = e.PrescentDays * e.wage;
            e['totalOTAmt'] = e.totalOTHours * e.otRate;
            if(parseFloat(e['totalOTAmt'])>0){
               e['totalOTAmt']=parseFloat(e['totalOTAmt']).toFixed(2);
            }
            if(parseFloat(e['totalWages'])>0){
               e['totalWages']=parseFloat(e['totalWages']).toFixed(2);
            }
         }
      });
      this.setState({
         exportArray: tempExportArray
      },()=>{
         console.log(this.state.exportArray);
      });
      
   }

   getEmpPresentPerDay(members){
      var present = [0,0,0,0,0,0,0];
      members.forEach(member=>{
         for(let i=0; i<member.attendanceDetails.length; i++){
            if(member.attendanceDetails[i].attendanceStatus == true){
               present[i] = present[i] + 1;
            }
         }
      })
      this.state.employeePresentDays = present;
   }

   getMembers = (projectId) => {

      toast.dismiss();
      axios.get(GLOBAL.SERVER_URL + "/api/get-labour-members?token=" + this.state.token + "&projectId=" + projectId + "&weekdays=" + JSON.stringify(this.state.weekdays)).then(res => {
         if (res.data.errorCode === 0) {
            this.getEmpPresentPerDay(res.data.response)
            this.setState({
               members: res.data.response,
               totalAmount: this.total(res.data.response)
            }, () => {
               this.syncExportArray();
               this.getPresentDays();
            });
         } else {
            toast.error(res.data.message, { delay: 150 });
         }
      }).catch(error => {
         console.log(error)
         toast.error('Error while retrieving consumables', { delay: 150 });
      })

   }

   getMemberDetails = (memberId) => {

      toast.dismiss();
      axios.get(GLOBAL.SERVER_URL + "/api/get-labour-member-by-id?token=" + this.state.token + "&memberId=" + memberId + "&weekdays=" + JSON.stringify(this.state.weekdays)).then(res => {
         if (res.data.errorCode === 0) {
            if (res.data.response._id) {
               this.state.accno = res.data.response.accno;
               this.state.ifsc = res.data.response.ifsc;
               const index = this.state.members.map(member => member._id).indexOf(res.data.response._id);
               const updatedMembers = update(this.state.members, { $splice: [[index, 1, res.data.response]] });
               this.setState({
                  members: updatedMembers,
                  totalAmount: this.total(updatedMembers)
               }, () => {
                  this.syncExportArray();
                  this.getPresentDays();
               });
            } else {
               toast.error(res.data.message, { delay: 150 });
            }
         } else {
            toast.error(res.data.message, { delay: 150 });
         }
      }).catch(error => {
         toast.error('Error while retrieving member details', { delay: 150 });
      })
   }

   getPresentDays() {
      let presentDayStatus = [];
      this.state.members.forEach(function (member) {
         presentDayStatus.push(
            (member.attendanceDetails[0] && member.attendanceDetails[0].attendanceStatus) ? true : false,
            (member.attendanceDetails[1] && member.attendanceDetails[1].attendanceStatus) ? true : false,
            (member.attendanceDetails[2] && member.attendanceDetails[2].attendanceStatus) ? true : false,
            (member.attendanceDetails[3] && member.attendanceDetails[3].attendanceStatus) ? true : false,
            (member.attendanceDetails[4] && member.attendanceDetails[4].attendanceStatus) ? true : false,
            (member.attendanceDetails[5] && member.attendanceDetails[5].attendanceStatus) ? true : false,
            (member.attendanceDetails[6] && member.attendanceDetails[6].attendanceStatus) ? true : false,
         )
      })
      let result = this.chunkArray(presentDayStatus, 7)
      let presentDayStatusCount = []
      let presentDayCount = presentDayStatusCount
      for (let i = 0; i < result.length; i++) {
         let count = result[i].filter(Boolean).length;
         presentDayStatusCount.push(count)
      }
      this.setState({
         presentDays: presentDayCount
      })
   }

   chunkArray(array, chunkSize) {
      let tempArray = [];
      for (let i = 0; i < array.length; i += chunkSize) {
         let myChunk = array.slice(i, i + chunkSize);
         tempArray.push(myChunk)
      }
      return tempArray;
   }

   total(input) {
      let projArray = [];
      input.forEach(function (labour) {
         projArray.push(parseFloat(labour.finalAmount).toFixed(0));
      });
      var totalAmount = 0;
      for (var i = 0; i < projArray.length; i++) {
         if (isNaN(projArray[i])) {
            continue;
         }
         totalAmount += Number(projArray[i]);
      }
      return totalAmount;
   }

   render() {

      if (this.state.unauthorizedAccess) {
         return <Redirect to="/dashboard" push={true} />;
      }

      if (this.state.projectId === null) {
         return <Redirect to="/projects" push={true} />;
      }

      return (
         <div className="gray-bg full-screen">
            <Sidebar activeMenu={'projects'} syncLoggedInUserData={this.syncLoggedInUserData.bind(this)} />
            <div className="inner-container">
               <Topbar ref="topbarComponent" />
               <div className="mb-2 mt-2">
                  <div className="inner-conntainer">
                     <div className="row">
                        <div className="col-12 col-md-9">
                           <ProjectTabs activeLink={'labour-tracking'} ref="projectTabsComponent" />
                        </div>
                        <div className="col-12 col-md-3 text-right">
                           {(this.state.accessLevels.indexOf(281) > -1) ?
                              <button className="add-btn" onClick={() => this.openPopup(null)}>Add Member</button>
                              : null}
                           {this.state.showPopup ?
                              <PopupManageLabourMember projectId={this.state.projectId} activeMember={this.state.activeMember} getMembers={this.getMembers.bind(this)} getMemberDetails={this.getMemberDetails.bind(this)}
                                 closePopup={this.closePopup.bind(this)}
                              />
                              : null
                           }
                        </div>

                        <div className="clear"></div>
                        <div className="col-12 col-sm-6 col-xl-3 year">
                           <DatePicker className="year-name"
                              dateFormat="yyyy"
                              selected={this.state.selectedYear}
                              onChange={this.handleYearChange}
                              showYearPicker
                              maxDate={new Date()}
                           />
                        </div>
                        <div className="col-12 col-sm-3 col-xl-9 month-select">
                           <select name="week" id="weeks" value={this.state.selectedWeek} onChange={this.handleWeekchange} className="checklist-weeks">
                              <option value="1">Week 1</option>
                              <option value="2">Week 2</option>
                              <option value="3">Week 3</option>
                              <option value="4">Week 4</option>
                              {this.state.totalweeks > 4 ?
                                 <option value="5">Week 5</option> : null
                              }
                              {this.state.totalweeks > 5 ?
                                 <option value="6">Week 6</option> : null
                              }
                           </select>
                           <DatePicker className="checklist-month"
                              dateFormat="MMMM"
                              selected={this.state.selectedMonth}
                              onChange={this.handleMonthChange}
                              showMonthYearPicker
                           />
                           {this.state.exportArray.length > 0 ?
                              <ExcelFile element={<button className="col-xl-2 col-sm-9 export-btn">Export</button>} filename={"weekly-report-" + moment(this.state.weekdays[0]).format("DD-MM-YYYY") + "-" + moment(this.state.weekdays[6]).format("DD-MM-YYYY")}>
                                 <ExcelSheet data={this.state.exportArray} name="Report">
                                    <ExcelColumn label="Name" value="name" />
                                    <ExcelColumn label="Designation" value="designation" />
                                    <ExcelColumn label={moment(this.state.weekdays[0]).format("DD-MM-YYYY")} value="day1" />
                                    <ExcelColumn label="OT Hours" value="day1ot" />
                                    <ExcelColumn label={moment(this.state.weekdays[1]).format("DD-MM-YYYY")} value="day2" />
                                    <ExcelColumn label="OT Hours" value="day2ot" />
                                    <ExcelColumn label={moment(this.state.weekdays[2]).format("DD-MM-YYYY")} value="day3" />
                                    <ExcelColumn label="OT Hours" value="day3ot" />
                                    <ExcelColumn label={moment(this.state.weekdays[3]).format("DD-MM-YYYY")} value="day4" />
                                    <ExcelColumn label="OT Hours" value="day4ot" />
                                    <ExcelColumn label={moment(this.state.weekdays[4]).format("DD-MM-YYYY")} value="day5" />
                                    <ExcelColumn label="OT Hours" value="day5ot" />
                                    <ExcelColumn label={moment(this.state.weekdays[5]).format("DD-MM-YYYY")} value="day6" />
                                    <ExcelColumn label="OT Hours" value="day6ot" />
                                    <ExcelColumn label={moment(this.state.weekdays[6]).format("DD-MM-YYYY")} value="day7" />
                                    <ExcelColumn label="OT Hours" value="day7ot" />
                                    <ExcelColumn label="Present Days" value="PrescentDays" />
                                    <ExcelColumn label="Total OT Hours" value="totalOTHours" />
                                    <ExcelColumn label="Wage Rate" value="wage" />
                                    <ExcelColumn label="OT Rate" value="otRate" />
                                    <ExcelColumn label="Total Wages" value="totalWages" />
                                    <ExcelColumn label="Total OT" value="totalOTAmt" />
                                    <ExcelColumn label="Extra Allowance" value="extraAllowance" />
                                    <ExcelColumn label="Total Amount" value="totalAmt" />
                                    <ExcelColumn label="Previous Loan Dues" value="previousDue" />
                                    <ExcelColumn label="Loan Paid" value="loanPaid" />
                                    <ExcelColumn label="Loan Deducted" value="advDeducted" />
                                    <ExcelColumn label="Weekly Advance Paid" value="weekAdvPaid" />
                                    <ExcelColumn label="Balance Amount" value="balance_amount" />
                                    <ExcelColumn label="Account Number" value="accno" />
                                    <ExcelColumn label="IFSC Code" value="ifsc" />
                                 </ExcelSheet>
                              </ExcelFile>
                              : null}
                        </div>
                        <div className="clear"></div>
                     </div>

                     <LabourTrackingDetails projectId={this.state.projectId} getMembers={this.getMembers.bind(this)} members={this.state.members} totalAmount={this.state.totalAmount} getMemberDetails={this.getMemberDetails.bind(this)} weekdays={this.state.weekdays} loggedInUserId={this.state.loggedInUserId} accessLevels={this.state.accessLevels} userRoleId={this.state.userRoleId} presentDaysCount={this.state.presentDays} employeePresentDays={this.state.employeePresentDays} />

                  </div>
               </div>
            </div>
         </div>
      );
   }
}
export default LabourTracking;
