import React, { Component } from "react";
import axios from 'axios';
import cookie from 'react-cookies';
import { Link, Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as GLOBAL from '../../../global';
import './sidebar-topbar.css';
import '../../../assets/css/style.css';
import CloseImage from '../../../assets/img/close-icon.svg';

class Topbar extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loggedInUser: null,
            searchResults: {},
            activeMenu: '',
            profileSidebarStatus: false,
            notificationStatus: false,
            token: cookie.load('_atclToken')
        }
    }

    componentDidMount() {
        if (!this.state.token || (typeof this.state.token === 'undefined')) {
            cookie.remove('_atclToken', { path: '/' });
            this.setState({
                token: null
            });
        } else {
            this.setState({
                activeMenu: this.props.activeMenu
            })
        }
    }

    syncLoggedInUserData(user) {
        this.setState({
            loggedInUser: user
        });
    }

    handleSearch(e) {
        if (e.target.value.length > 2) {
            axios.get(GLOBAL.SERVER_URL + "/api/search?query=" + e.target.value + "&token=" + this.state.token).then(res => {
                if (res.data.errorCode === 0) {
                    var sorted = { consumables: [], plantMachinery: [], projects: [] };
                    sorted.consumables = res.data.response.consumables.sort((a, b) => {
                        let fa = a.name.toLowerCase(),
                            fb = b.name.toLowerCase();
                    
                        if (fa < fb) {
                            return -1;
                        }
                        if (fa > fb) {
                            return 1;
                        }
                        return 0;
                    });
                    sorted.projects = res.data.response.projects.sort((a, b) => {
                        let fa = a.projectName.toLowerCase(),
                            fb = b.projectName.toLowerCase();
                    
                        if (fa < fb) {
                            return -1;
                        }
                        if (fa > fb) {
                            return 1;
                        }
                        return 0;
                    });
                    sorted.plantMachinery = res.data.response.plantMachinery.sort((a, b) => {
                        let fa = a.itemName.toLowerCase(),
                            fb = b.itemName.toLowerCase();
                    
                        if (fa < fb) {
                            return -1;
                        }
                        if (fa > fb) {
                            return 1;
                        }
                        return 0;
                    });
                    this.setState({
                        searchResults: sorted
                    });
                } else {
                    toast.error(res.data.message, { delay: 150 });
                }
            }).catch(error => {
                toast.error("Error occured. Please try again!", { delay: 150 });
            })
        } else {
            this.setState({
                searchResults: {}
            });
        }
    }

    hideSearchResults(e) {
        setTimeout(function () {
            this.setState({
                searchResults: {}
            });
        }.bind(this), 250)
    }

    toggleProfileSidebar() {
        this.setState({
            profileSidebarStatus: !this.state.profileSidebarStatus
        });
    }

    toggleNotificationStatus() {
        this.setState({
            notificationStatus: !this.state.notificationStatus
        });
    }

    togglePopup() {
        this.setState({
            showPopup: !this.state.showPopup
        });
    }

    toggleSidebar() {
        this.props.toggleSidebar();
    }

    logOut() {
        cookie.remove('_atclToken', { path: '/' });
        this.setState({
            token: null
        });
        toast.dismiss();
        toast.success('Sign Out successfully!', { delay: 150 });
    }

    render() {
        if (!this.state.token) {
            return <Redirect to="/" push={true} />;
        }
        return (
            <div className="mb-2 mt-2 top-bar-main-class">
                <div className="topbar row topbar-mob-menu">
                    <div className="col-4 mob-logo-row">
                        <img className="mobile-logo" src={require("../../../assets/img/logo.png")} alt="ATCL logo" />
                    </div>
                    <form className="col-6 col-md-8 col-lg-8 search-btn">
                        <div className="form-group position-relative">
                            <input type="text" className="form-control search" placeholder="Search for Plant & Machinery, Projects and Consumables" onChange={this.handleSearch.bind(this)} onBlur={this.hideSearchResults.bind(this)} />
                            <button type="submit"></button>
                        </div>
                        {((this.state.searchResults.plantMachinery && this.state.searchResults.plantMachinery.length > 0) || (this.state.searchResults.projects && this.state.searchResults.projects.length > 0) || (this.state.searchResults.consumables && this.state.searchResults.consumables.length > 0)) ?
                            <div className="search-results">
                                {(this.state.searchResults.plantMachinery && this.state.searchResults.plantMachinery.length > 0) ?
                                    <div>
                                        <h4>Plant & Machinery</h4>
                                        <ul>
                                            {this.state.searchResults.plantMachinery.map((item, index) => {
                                                return (
                                                    <li key={index}>
                                                        <Link to={{ pathname: '/manage-inventory/' + item._id }}>{item.itemName}</Link>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div> : null}
                                {(this.state.searchResults.projects && this.state.searchResults.projects.length > 0) ?
                                    <div>
                                        <h4>Projects</h4>
                                        <ul>
                                            {this.state.searchResults.projects.map((item, index) => {
                                                return (
                                                    <li key={index}>
                                                        <Link to={{ pathname: '/organization/' + item._id }}>{item.projectName}</Link>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div> : null}
                                {(this.state.searchResults.consumables && this.state.searchResults.consumables.length > 0) ?
                                    <div>
                                        <h4>Consumables</h4>
                                        <ul>
                                            {this.state.searchResults.consumables.map((item, index) => {
                                                return (
                                                    <li key={index}>
                                                        <Link to={{ pathname: '/single-consumable/' + item.projectId + '/' + item._id }}>{item.projectData[0].projectName + " > " + item.name}</Link>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div> : null}
                            </div> : null}
                        {((this.state.searchResults.plantMachinery && this.state.searchResults.plantMachinery.length === 0) && (this.state.searchResults.projects && this.state.searchResults.projects.length === 0) && (this.state.searchResults.consumables && this.state.searchResults.consumables.length === 0)) ?
                            <div className="search-results">
                                <ul>
                                    <li>No results found!</li>
                                </ul>
                            </div> : null}
                    </form>
                    <div className="col-4 col-md-8 col-lg-4 text-right topbar-icon-wrapper">
                        <button className="notification-icon mr-3" onClick={() => this.toggleNotificationStatus()}></button>
                        <button className="user-avatar" onClick={() => this.toggleProfileSidebar()}></button>
                        <button className="mobile-menu-button" onClick={() => this.toggleSidebar()}></button>

                        <div className={(this.state.profileSidebarStatus ? 'user-details-content profile-sidebar-active' : 'user-details-content')}>
                            <button className="profile-close-button" onClick={() => this.toggleProfileSidebar()}>
                                <img src={CloseImage} alt="close-button" />
                            </button>
                            <div className="clear-class"></div>

                            <div className="user-detail-desk">
                                <div className="row">
                                    <div className="col-4">
                                        <div className="user-image">
                                            {this.state.loggedInUser?.firstName.charAt(0)}{this.state.loggedInUser?.lastName ? this.state.loggedInUser?.lastName.charAt(0) : ''}
                                        </div>
                                    </div>
                                    <div className="col-8 user-detail padding-zero">
                                        <h5>{this.state.loggedInUser?.firstName}</h5>
                                        <h5>{this.state.loggedInUser?.lastName}</h5>
                                        <p>{this.state.loggedInUser?.designationDetails[0].name}</p>
                                    </div>
                                    <div className="basic-info-desk">
                                        <div className="col-xl-12">
                                            <h1 className="bi">Basic Information</h1>
                                            <p>Username</p>
                                            <h5>{this.state.loggedInUser?.username}</h5>
                                            <p>Office ID</p>
                                            <h5>{this.state.loggedInUser?.officeId}</h5>
                                        </div>
                                    </div>
                                    <div className="cont-info-desk">
                                        <div className="col-xl-12">
                                            <h1 className="bi">CONTACT INFORMATION</h1>
                                            <p>Phone</p>
                                            <h5>{this.state.loggedInUser?.contactNumber}</h5>
                                            <p>Email</p>
                                            <h5>{this.state.loggedInUser?.email}</h5>

                                        </div>
                                        <div className="edits">
                                            {/* <button className="edit-pro-class" onClick={this.togglePopup.bind(this)}>Edit Profile</button>
                                            {this.state.showPopup ?
                                                <PopupProfileEdit
                                                    closePopup={this.togglePopup.bind(this)}
                                                />
                                                : null
                                            } */}
                                            <button className="log-out-class" onClick={this.logOut.bind(this)}>SIGN OUT</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default Topbar;
