import React, { Component } from "react";
import axios from 'axios';
import cookie from 'react-cookies';
import { toast } from 'react-toastify';
import { Link, Redirect } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import update from 'immutability-helper';

import 'react-confirm-alert/src/react-confirm-alert.css';
import * as GLOBAL from '../../../global';
import Sidebar from '../includes/sidebar';
import Topbar from '../includes/topbar';
import PopupManageProject from './popups/manage-project';
import placeholderBg from "../../../assets/img/placeholder-bg.png";

class Projects extends Component {

   constructor(props) {
      super(props);
      this.state = {
         projects: [],
         serverFilePath: '',
         workshopProjectId: null,
         showPopup: false,
         activeProject: null,
         loggedInUserId: '',
         superAdminUserId: null,
         accessLevels: [],
         unauthorizedAccess: false,
         wsInProgress: false,
         token: cookie.load('_atclToken')
      };
   }

   componentDidMount() {
      if (this.state.token && typeof this.state.token != 'undefined') {
         this.getProjects();
      }
   }

   syncLoggedInUserData(user) {
      this.refs.topbarComponent.syncLoggedInUserData(user);
      this.setState({
         loggedInUserId: user._id,
         superAdminUserId: user.superAdminUserId,
         accessLevels: user.roleDetails[0].accessLevels,
         unauthorizedAccess: (user.roleDetails[0].accessLevels.indexOf(200) === -1) ? true : false
      });
   }

   toggleSidebar() {
      this.refs.sidebarComponent.toggleSidebar();
   }

   openPopup(project) {
     let params = {
         showPopup: true
      };
      if (project) {
         params.activeProject = project;
      }
      this.setState(params);
   }

   closePopup() {
      this.setState({
         showPopup: false,
         activeProject: null
      });
   }

   getProjects = () => {
      toast.dismiss();
      axios.get(GLOBAL.SERVER_URL + "/api/get-projects?token=" + this.state.token).then(res => {
         if (res.data.errorCode === 0) {
            var sortedPrject = res.data.response.sort((a, b) => {
               let fa = a.projectName.toLowerCase(),
                  fb = b.projectName.toLowerCase();

               if (fa < fb) {
                  return -1;
               }
               if (fa > fb) {
                  return 1;
               }
               return 0;
            });
            this.setState({
               projects: sortedPrject,
               serverFilePath: res.data.serverFilePath,
               workshopProjectId: res.data.workshopProjectId
            });

         } else {
            toast.error(res.data.message, { delay: 150 });
         }
      }).catch(error => {
         toast.error('Error while retrieving projects', { delay: 150 });
      })
   }

   getProjectDetails = (projectId) => {
      toast.dismiss();
      axios.get(GLOBAL.SERVER_URL + "/api/get-project-by-id?token=" + this.state.token + "&projectId=" + projectId).then(res => {
         if (res.data.errorCode === 0) {
            if (res.data.response._id) {
               const index = this.state.projects.map(project => project._id).indexOf(res.data.response._id);
               const updatedProjects = update(this.state.projects, { $splice: [[index, 1, res.data.response]] });
               this.setState({ projects: updatedProjects });
            } else {
               toast.error(res.data.message, { delay: 150 });
            }
         } else {
            toast.error(res.data.message, { delay: 150 });
         }
      }).catch(error => {
         toast.error('Error while retrieving project details', { delay: 150 });
      })

   }

   deleteProject(index, item) {
      toast.dismiss();
      if (item._id) {
         if (!this.state.wsInProgress) {
            confirmAlert({
               title: 'Confirm to delete',
               message: 'Are you sure to do this?',
               buttons: [
                  {
                     label: 'Cancel',
                     onClick: () => { }
                  },
                  {
                     label: 'Confirm',
                     onClick: () => {
                        this.setState({
                           wsInProgress: true
                        }, () => {
                           let projObject = this.state.projects[index];
                           this.params = {
                              _id: projObject._id,
                              token: this.state.token
                           }
                           axios.post(GLOBAL.SERVER_URL + "/api/delete-project", this.params).then(res => {
                              this.setState({
                                 wsInProgress: false
                              }, () => {
                                 if (res.data.errorCode === 0) {
                                    this.setState({
                                       projects: this.state.projects.filter((_, i) => i !== index)
                                    });
                                    toast.success(res.data.message, { delay: 150 });
                                 } else {
                                    toast.error(res.data.message, { delay: 150 });
                                 }
                              });
                           }).catch(error => {
                              toast.error("Error occured. Please try again!", { delay: 150 });
                           });
                        });
                     }
                  },
               ]
            });
         }
      } else {
         toast.error("Invalid operation!", { delay: 150 });
      }
   }

   render() {
      if (this.state.unauthorizedAccess) {
         return <Redirect to="/dashboard" push={true} />;
      }
      return (
         <div className="gray-bg full-screen">
            <Sidebar activeMenu={'projects'} ref="sidebarComponent" syncLoggedInUserData={this.syncLoggedInUserData.bind(this)} />
            <div className="inner-container">
               <Topbar ref="topbarComponent" toggleSidebar={this.toggleSidebar.bind(this)} />
               <div className="row project_desc">

                  {(this.state.accessLevels.indexOf(201) > -1) ?
                     <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                        <div className="main-project-div add-project">
                           <button className="add-project-button" onClick={() => this.openPopup(null)} ></button>
                           {this.state.showPopup ?
                              <PopupManageProject project={this.state.activeProject} getProjects={this.getProjects.bind(this)} getProjectDetails={this.getProjectDetails.bind(this)}
                                 closePopup={this.closePopup.bind(this)}
                              />
                              : null
                           }
                        </div>
                     </div>
                     : null
                  }

                  {(this.state.projects.length > 0) &&
                     this.state.projects.map((project, index) => {
                        if ((this.state.accessLevels.indexOf(200) > -1) && (this.state.superAdminUserId == this.state.loggedInUserId) || (project.organizationChartData.length > 0)) {
                           var projectBg = placeholderBg;
                           if (GLOBAL.APP_ENV === 'REMOTE' && (project.projectImage !== null)) {
                              projectBg = this.state.serverFilePath + project.projectImage
                           }
                           return (
                              <div key={index} className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                 <div className="main-project-div">
                                    <div className="project-image" style={{ backgroundImage: `url(${projectBg})` }} >
                                    </div>
                                    <div className="project-single-meta-data">
                                       {((this.state.accessLevels.indexOf(202) > -1) || (this.state.accessLevels.indexOf(203) > -1)) ?
                                          <div className="more-menu">
                                             <span></span>
                                             <ul>
                                                {((this.state.accessLevels.indexOf(202) > -1) && (this.state.superAdminUserId === this.state.loggedInUserId) || (project.organizationChartData.length > 0)) ?
                                                   <li onClick={() => this.openPopup(project)}>Edit</li>
                                                   : null}
                                                {((this.state.accessLevels.indexOf(203) > -1) && (project._id !== this.state.workshopProjectId)) ?
                                                   <li onClick={() => this.deleteProject(index, project)}>Delete</li>
                                                   : null}
                                             </ul>
                                          </div>
                                          : null}
                                       <h5 className="overflow-ellipsis" title={project.projectName}>{project.projectName}</h5>
                                       <h6 className="pro-date">Start Date: {new Intl.DateTimeFormat('en-GB', {
                                          month: 'long',
                                          day: '2-digit',
                                          year: 'numeric',
                                       }).format(new Date(project.startDate))}</h6>
                                       <h6 className="pro-locs">{project.location}</h6>
                                       <Link to={{ pathname: '/organization/' + project._id }} className="custom-btn goto-btn projects-button"></Link>
                                    </div>
                                 </div>
                              </div>
                           )
                        }
                     })
                  }

               </div>
            </div>
         </div>
      );
   }
}
export default Projects;
