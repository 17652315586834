import React from 'react';
import axios from 'axios';
import cookie from 'react-cookies';
import { toast } from 'react-toastify';
import * as GLOBAL from '../../../../global';

import '../project.css';

class PopupManageConsumableMain extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      _id: '',
      projectId: '',
      name: '',
      unit: '',
      units: [],
      wsInProgress: false,
      token: cookie.load('_atclToken')
    }
  }

  componentDidMount() {
    if (this.props.projectId !== '') {
      this.setState({
        projectId: this.props.projectId
      });
      if (this.props.activeConsumable) {
        this.setState({
          _id: this.props.activeConsumable._id,
          name: this.props.activeConsumable.name,
          unit: this.props.activeConsumable.unit[0]._id
        });
      }
      this.getUnits();
    }
  }

  getUnits = () => {
    axios.get(GLOBAL.SERVER_URL + "/api/get-units").then(res => {
      this.setState({
        units: res.data.response
      });
    });
  }

  validate = () => {
    let validname = /^(?=.*[a-zA-Z])([a-zA-Z0-9 @()]+)$/  
    
    if (!this.state.name) {
      toast.error('Name cannot be blank', { delay: 150 });
      return false;
    } else if (!this.state.unit) {
      toast.error('Unit is required', { delay: 150 });
      return false;
    } else if (!(this.state.name).match(validname)) {
      toast.error('Enter a valid name', { delay: 150 });
      return false;
    }  else {
      return true;
    }
  }

  saveItem = (event) => {
    event.preventDefault();
    if (!this.state.wsInProgress) {
      toast.dismiss();
      const isValid = this.validate();
      if (isValid) {
        this.setState({
          wsInProgress: true
        }, () => {
          this.params = {
            _id: this.state._id,
            projectId: this.state.projectId,
            name: this.state.name,
            unit: this.state.unit,
            token: this.state.token
          }

          axios.post(GLOBAL.SERVER_URL + "/api/save-consumable", this.params).then(res => {
            this.setState({
              wsInProgress: false
            }, () => {
              if (res.data.errorCode === 0) {
                toast.success(res.data.message, { delay: 150 });
                if (this.state._id !== '') {
                  this.props.getItemDetails(this.state._id);
                } else {
                  this.props.getConsumables(this.state.projectId);
                }
                this.props.closePopup();
              } else {
                toast.error(res.data.message, { delay: 150 });
              }
            });
          }).catch(error => {
            toast.error("Error occured. Please try again!", { delay: 150 });
          });
        });
      }
    }
  }

  render() {
    return (
      <div className="popup">
        <div className="popup_inner popup_inner_small">
          <div className="page-header">
            <div className="material-card text-left">
              <div className="popup-heads">
                <div className="row">
                  <div className="col-12 col-sm-6 col-xl-6">
                    <h3 className="popup-title">{this.state._id ? 'Update' : 'Add'} Item</h3>
                  </div>
                  <div className="col-12 col-sm-6 col-xl-6">
                    <button onClick={this.saveItem} className="entry-save project-save">Save</button>
                    <button onClick={this.props.closePopup} className="entry-close project-close">Cancel</button>
                  </div>
                </div>
              </div>
              <div className="popup-body p-b-15">

                <div className="form-group">
                  <label htmlFor="name">Name <span className="required">*</span></label>
                  <input type="text" id="name" className="form-control" value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} autoComplete="off" />
                </div>

                <div className="form-group">
                  <label htmlFor="unit">Unit <span className="required">*</span></label>
                  <select id="unit" className="form-control" value={this.state.unit} onChange={(e) => this.setState({ unit: e.target.value })}>
                    <option value="">Select</option>
                    {this.state.units.map((value, index) => {
                      return (<option value={value._id} key={index}>{value.unit}</option>)
                    })}
                  </select>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PopupManageConsumableMain;