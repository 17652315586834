import React from 'react';
import axios from 'axios';
import cookie from 'react-cookies';
import { toast } from 'react-toastify';

import * as GLOBAL from '../../../../global';
import "../settings.css";

class PopupManageAdminUserRoles extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      _id: '',
      name: '',
      accessLevels: [],
      selectedAccessLevels: [],
      wsInProgress: false,
      token: cookie.load('_atclToken')
    }
  }

  componentDidMount() {
    this.setState({
      accessLevels: this.props.accessLevelsList
    });
    if (this.props.activeRole) {
      this.setState({
        _id: this.props.activeRole._id,
        name: this.props.activeRole.name,
        selectedAccessLevels: this.props.activeRole.accessLevels
      });
    }
  }

  updateAccessLevels = (value) => {
    let index = this.state.selectedAccessLevels.indexOf(value);
    if (index !== -1) {
      this.setState({
        selectedAccessLevels: this.state.selectedAccessLevels.filter((_, i) => i !== index)
      });
    } else {
      this.setState({
        selectedAccessLevels: this.state.selectedAccessLevels.concat(value)
      })
    }
  }

  validate = () => {

    let rolename = /^[A-Za-z() ]*$/;
    if (!this.state.name) {
      toast.error('Role Name cannot be blank', { delay: 150 });
      return false;
    } else if (!(this.state.name).match(rolename)) {
      toast.error('Enter a valid Role Name', { delay: 150 });
      return false;
    } else {
      return true;
    }
  }

  saveRole = (event) => {
    event.preventDefault();
    if (!this.state.wsInProgress) {
      toast.dismiss();
      const isValid = this.validate();
      if (isValid) {
        this.setState({
          wsInProgress: true
        }, () => {
          this.params = {
            _id: this.state._id,
            name: this.state.name,
            selectedAccessLevels: this.state.selectedAccessLevels,
            token: this.state.token
          }

          axios.post(GLOBAL.SERVER_URL + "/api/save-role", this.params).then(res => {
            this.setState({
              wsInProgress: false
            }, () => {
              if (res.data.errorCode === 0) {
                toast.success(res.data.message, { delay: 150 });
                if (this.state._id !== '') {
                  this.props.getRoleDetails(this.state._id);
                } else {
                  this.props.getRoles();
                }
                this.props.closePopup();
              } else {
                toast.error(res.data.message, { delay: 150 });
              }
            });
          }).catch(error => {
            toast.error("Error occured. Please try again!", { delay: 150 });
          });
        });
      }
    }
  }

  render() {
    return (
      <div className="popup">
        <div className="popup_inner popup_inner_large">
          <div className="page-header">
            <div className="material-card text-left">
              <div className="popup-heads">
                <div className="row">
                  <div className="col-12 col-sm-6 col-xl-6">
                    <h3 className="popup-title">{this.state._id ? 'Update' : 'Add'} Role</h3>
                  </div>
                  <div className="col-12 col-sm-6 col-xl-6">
                    <button onClick={this.saveRole} className="entry-save green-save">Save</button>
                    <button onClick={this.props.closePopup} className="entry-close green-close">Cancel</button>
                  </div>
                </div>
              </div>
              <div className="popup-body overflow-popup-body p-b-15">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="name">Role Name</label>
                      <input type="text" id="name" className="form-control" autoComplete="off" value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} />
                    </div>
                  </div>
                </div>
                <div className="form-group mb-0">
                  <label>Access Levels</label>
                </div>
                <div className="row">
                  {this.state.accessLevels.map((accessLevel, aIndex) => {
                    if (accessLevel.value != 266 && this.state._id != this.props.superAdminUserId) {
                      return (
                        <div key={aIndex} className="col-md-4" >
                          <div className="adv-checkbox">
                            <label htmlFor={accessLevel._id}>
                              <input type="checkbox" id={accessLevel._id} value={accessLevel.value} defaultChecked={(this.state.selectedAccessLevels.indexOf(accessLevel.value) !== -1)} onChange={() => this.updateAccessLevels(accessLevel.value)} />
                              <span className="custom-checkbox">
                                <i className="icon-check"></i>
                              </span>
                              {accessLevel.label}
                            </label>
                          </div>
                        </div>
                      )
                    }
                  })}
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }
}
export default PopupManageAdminUserRoles;