import React, { Component } from "react";
import { Link } from 'react-router-dom';

class ProjectTabs extends Component {

   constructor(props) {
      super(props)
      this.state = {
         projectId: null,
         activeLink: ''
      }
   }

   componentDidMount() {
      this.setState({
         activeLink: this.props.activeLink
      })
   }

   render() {

      return (
         <div className="page-header mt-2">
            <div className="row mb-3">
               <div className="col-10 col-xl-9">
                  <div className="custom-tabs-div">
                     <ul className="custom-tabs has-link super-admin-tabs">
                        <li className={((this.state.activeLink === 'settings-checklists') ? 'active-tab' : '')}>
                           <Link to='/settings/checklists'>Checklist</Link>
                        </li>
                        <li className={((this.state.activeLink === 'settings-user-roles') ? 'active-tab' : '')}>
                           <Link to='/settings/user-roles'>User Role</Link>
                        </li>
                        <li className={((this.state.activeLink === 'settings-units') ? 'active-tab' : '')}>
                           <Link to='/settings/units'>Units</Link>
                        </li>
                        <li className={((this.state.activeLink === 'settings-designations') ? 'active-tab' : '')}>
                           <Link to='/settings/designations'>Designations</Link>
                        </li>
                     </ul>
                  </div>

                  <div className="clear"></div>
               </div>
            </div>
         </div>
      );
   }
}

export default ProjectTabs;