import React from 'react';
import axios from 'axios';
import cookie from 'react-cookies';
import { toast } from 'react-toastify';

import * as GLOBAL from '../../../../global';
import "../settings.css";

class PopupManageAdminUnits extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      _id: '',
      label: '',
      unit: '',
      wsInProgress: false,
      token: cookie.load('_atclToken')
    }
  }

  componentDidMount() {
    if (this.props.activeUnit) {
      this.setState({
        _id: this.props.activeUnit._id,
        label: this.props.activeUnit.label,
        unit: this.props.activeUnit.unit
      });
    }
  }

  validate = () => {

    let nameReg = /^[\w -_]*$/;

    if (!this.state.label) {
      toast.error('Label cannot be blank', { delay: 150 });
      return false;
    } else if (!(this.state.label).match(nameReg)) {
      toast.error('Enter a valid Label', { delay: 150 });
      return false;
    } else if (!this.state.unit) {
      toast.error('Unit cannot be blank', { delay: 150 });
      return false;
    } else if (!(this.state.unit).match(nameReg)) {
      toast.error('Enter a valid unit', { delay: 150 });
      return false;
    } else {
      return true;
    }
  }

  saveUnit = (event) => {
    event.preventDefault();
    if (!this.state.wsInProgress) {
      toast.dismiss();
      const isValid = this.validate();
      if (isValid) {
        this.setState({
          wsInProgress: true
        }, () => {
          this.params = {
            _id: this.state._id,
            label: this.state.label,
            unit: this.state.unit,
            token: this.state.token
          }

          axios.post(GLOBAL.SERVER_URL + "/api/save-unit", this.params).then(res => {
            this.setState({
              wsInProgress: false
            }, () => {
              if (res.data.errorCode === 0) {
                toast.success(res.data.message, { delay: 150 });
                if (this.state._id !== '') {
                  this.props.getUnitDetails(this.state._id);
                } else {
                  this.props.getUnits();
                }
                this.props.closePopup();
              } else {
                toast.error(res.data.message, { delay: 150 });
              }
            });
          }).catch(error => {
            toast.error("Error occured. Please try again!", { delay: 150 });
          });
        });
      }
    }
  }

  render() {
    return (
      <div className="popup">
        <div className="popup_inner popup_inner_small">
          <div className="page-header">
            <div className="material-card text-left">
              <div className="popup-heads">
                <div className="row">
                  <div className="col-12 col-sm-6 col-xl-6">
                    <h3 className="popup-title">{this.state._id ? 'Update' : 'Add'} Unit</h3>
                  </div>
                  <div className="col-12 col-sm-6 col-xl-6">
                    <button onClick={this.saveUnit} className="entry-save green-save">Save</button>
                    <button onClick={this.props.closePopup} className="entry-close green-close">Cancel</button>
                  </div>
                </div>
              </div>
              <div className="popup-body p-b-15">
                <div className="form-group">
                  <label htmlFor="label">Label</label>
                  <input type="text" id="label" className="form-control" autoComplete="off" value={this.state.label} onChange={(e) => this.setState({ label: e.target.value })} />
                </div>
                <div className="form-group">
                  <label htmlFor="unit">Unit</label>
                  <input type="text" id="unit" className="form-control" autoComplete="off" value={this.state.unit} onChange={(e) => this.setState({ unit: e.target.value })} />
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }
}
export default PopupManageAdminUnits;
