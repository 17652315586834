import React from 'react';
import axios from 'axios';
import cookie from 'react-cookies';
import { toast } from 'react-toastify';
import update from 'immutability-helper';
import { confirmAlert } from 'react-confirm-alert';

import 'react-confirm-alert/src/react-confirm-alert.css';
import * as GLOBAL from '../../../../global';
import "../settings.css";
import AddMoreImage from "../../../../assets/img/plus.svg";

class PopupManageAdminChecklist extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      _id: '',
      title: '',
      checklistItems: [{
        _id: '',
        description: ''
      }],
      wsInProgress: false,
      token: cookie.load('_atclToken')
    }
  }

  componentDidMount() {
    if (this.props.activeChecklist) {
      this.setState({
        _id: this.props.activeChecklist._id,
        title: this.props.activeChecklist.title,
        checklistItems: this.props.activeChecklist.checklistItems
      });
    }
  }

  addRow() {
    this.setState({
      checklistItems: [...this.state.checklistItems, {
        _id: '',
        description: ''
      }]
    })
  }

  handleChange(index, e) {
    var itemObject = this.state.checklistItems[index];
    itemObject[e.target.name] = e.target.value;
    const updatedItems = update(this.state.checklistItems, { $splice: [[index, 1, itemObject]] });
    this.setState({ checklistItems: updatedItems });
  }

  validate = () => {
    
    let inputvalidation = /.*[a-zA-Z ].*/;

    if (!this.state.title) {
      toast.error('Title is required', { delay: 150 });
      return false;
    } else if (!(this.state.title).match(inputvalidation)) {
      toast.error('Enter a valid Title', { delay: 150 });
      return false;
    } else if (this.state.checklistItems.length === 0) {
      toast.error('Atleast one checklist item is required', { delay: 150 });
      return false;
    } 
    else {
      let error = 0;
      this.state.checklistItems.forEach(async function (entry) {
        if (!entry.description) {
          error = 1;
        }
      })
      if (error === 1) {
        toast.error('Item description cannot be blank', { delay: 150 });
        return false;
      } else {
        let error = 0;
        this.state.checklistItems.forEach(async function (entry) {
          if (!(entry.description).match(inputvalidation)) {
           error = 2;
          }
        }) 
        if (error === 2) {
          toast.error('The checklist item description should contain a letter', { delay: 150 });
          return false;
        } else {
          return true;
        }
        
      }

    }

  }

  saveChecklist = (event) => {
    event.preventDefault();
    if (!this.state.wsInProgress) {
      toast.dismiss();
      const isValid = this.validate();
      if (isValid) {
        this.setState({
          wsInProgress: true
        }, () => {
          this.params = {
            _id: this.state._id,
            title: this.state.title,
            checklistItems: this.state.checklistItems,
            token: this.state.token
          }

          axios.post(GLOBAL.SERVER_URL + "/api/save-checklist", this.params).then(res => {
            this.setState({
              wsInProgress: false
            }, () => {
              if (res.data.errorCode === 0) {
                toast.success(res.data.message, { delay: 150 });
                if (this.state._id !== '') {
                  this.props.getChecklistDetails(this.state._id);
                } else {
                  this.props.getChecklists();
                }
                this.props.closePopup();
              } else {
                toast.error(res.data.message, { delay: 150 });
              }
            });
          }).catch(error => {
            toast.error("Error occured. Please try again!", { delay: 150 });
          });
        });
      }
    }
  }

  removeRow(index, item) {
    toast.dismiss();
    if (item._id) {
      if (!this.state.wsInProgress) {
        confirmAlert({
          title: 'Confirm to delete',
          message: 'Are you sure to do this?',
          buttons: [
            {
              label: 'Cancel',
              onClick: () => { }
            },
            {
              label: 'Confirm',
              onClick: () => {
                this.setState({
                  wsInProgress: true
                }, () => {
                  let itemObject = this.state.checklistItems[index];
                  this.params = {
                    _id: itemObject._id,
                    token: this.state.token
                  }
                  axios.post(GLOBAL.SERVER_URL + "/api/delete-checklist-item", this.params).then(res => {
                    this.setState({
                      wsInProgress: false
                    }, () => {
                      if (res.data.errorCode === 0) {
                        this.setState({
                          checklistItems: this.state.checklistItems.filter((_, i) => i !== index)
                        });
                        this.props.getChecklistDetails(this.state._id);
                        toast.success(res.data.message, { delay: 150 });
                      } else {
                        toast.error(res.data.message, { delay: 150 });
                      }
                    });
                  }).catch(error => {
                    toast.error("Error occured. Please try again!", { delay: 150 });
                  });
                });
              }
            },
          ]
        });
      }
    } else {
      this.setState({
        checklistItems: this.state.checklistItems.filter((_, i) => i !== index)
      });
    }
  }

  render() {
    return (
      <div className="popup">
        <div className="popup_inner popup_inner_medium">
          <div className="page-header">
            <div className="material-card text-left">
              <div className="popup-heads">
                <div className="row">
                  <div className="col-12 col-sm-6 col-xl-6">
                    <h3 className="popup-title">{this.state._id ? 'Update' : 'Add'} Checklist</h3>
                  </div>
                  <div className="col-12 col-sm-6 col-xl-6">
                    <button onClick={this.saveChecklist} className="entry-save green-save">Save</button>
                    <button onClick={this.props.closePopup} className="entry-close green-close">Cancel</button>
                  </div>
                </div>
              </div>
              <div className="popup-body overflow-popup-body p-b-15">

                <div className="row">
                  <div className="col-12 col-md-12">
                    <div className="form-group">
                      <label htmlFor="checklist-title">Title</label>
                      <input type="text" id="checklist-title" className="form-control" autoComplete="off" value={this.state.title} onChange={(e) => this.setState({ title: e.target.value })} />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <h5 className="popup-sub-heading">Items</h5>
                  </div>
                  <div className="col-12">
                    {(this.state.checklistItems.length > 0) &&
                      this.state.checklistItems.map((checklistItem, index) => {
                        return (
                          <div key={index} className="form-group checklist-item-entry">
                            <input type="text" className="form-control" placeholder={"Write entry " + (index + 1) + " description"} autoComplete="off" name="description" value={checklistItem.description} onChange={this.handleChange.bind(this, index)} />
                            <button className="delete-btn" onClick={() => this.removeRow(index, checklistItem)}></button>
                          </div>
                        )
                      })
                    }
                  </div>
                  <div className="col-12">
                    <button className="add-task-btn" onClick={this.addRow.bind(this)}>
                      <img src={AddMoreImage} alt="Add Task" />
                      <p className="">Add Item</p>
                    </button>
                  </div>
                </div>

              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }
}
export default PopupManageAdminChecklist;