import React, { Component } from "react";
import axios from 'axios';
import cookie from 'react-cookies';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import update from 'immutability-helper';
import { Redirect } from 'react-router-dom';
   
import 'react-confirm-alert/src/react-confirm-alert.css';
import * as GLOBAL from '../../../global';
import Sidebar from '../includes/sidebar';
import Topbar from '../includes/topbar';
import ProjectTabs from './project-tabs';
import ChecklistMainDetails from './includes/checklist-main';
import PopupManageChecklistMain from './popups/manage-checklist-main';

class Checklists extends Component {

   constructor(props) {
      super(props)
      this.state = {
         projectId: '',
         checklists: [],
         showPopup: false,
         activeChecklist: null,
         loggedInUserId: '',
         accessLevels: [],
         unauthorizedAccess: false,
         wsInProgress: false,
         token: cookie.load('_atclToken')
      };
   }

   componentDidMount() {
      if (this.props.match.params && this.props.match.params.projectId) {
         if (this.state.token && typeof this.state.token != 'undefined') {
            this.setState({
               projectId: this.props.match.params.projectId
            });
            this.refs.projectTabsComponent.syncProjectId(this.props.match.params.projectId);
            this.getProjectChecklists(this.props.match.params.projectId);
         }

      } else {
         this.setState({
            projectId: null
         });
         this.refs.projectTabsComponent.syncProjectId(null);
      }
   }

   syncLoggedInUserData(user) {
      this.refs.topbarComponent.syncLoggedInUserData(user);
      this.setState({
         loggedInUserId: user._id,
         accessLevels: user.roleDetails[0].accessLevels,
         unauthorizedAccess: (user.roleDetails[0].accessLevels.indexOf(200) === -1) ? true : false
      });
   }

   toggleSidebar() {
      this.refs.sidebarComponent.toggleSidebar();
   }

   openPopup(checklist) {
      let params = {
         showPopup: true
      };
      if (checklist) {
         params.activeChecklist = checklist;
      }
      this.setState(params);
   }

   closePopup() {
      this.setState({
         showPopup: false,
         activeChecklist: null
      });
   }

   getProjectChecklists = (projectId) => {

      toast.dismiss();
      axios.get(GLOBAL.SERVER_URL + "/api/get-project-checklists?token=" + this.state.token + "&projectId=" + projectId).then(res => {
         if (res.data.errorCode === 0) {
            this.setState({
               checklists: res.data.response
            });
         } else {
            toast.error(res.data.message, { delay: 150 });
         }
      }).catch(error => {
         toast.error('Error while retrieving projects', { delay: 150 });
      })

   }

   getProjectChecklistDetails = (projectChecklistId) => {

      toast.dismiss();
      axios.get(GLOBAL.SERVER_URL + "/api/get-project-checklist-by-id?token=" + this.state.token + "&projectChecklistId=" + projectChecklistId).then(res => {
         if (res.data.errorCode === 0) {
            if (res.data.response._id) {
               const index = this.state.checklists.map(checklist => checklist._id).indexOf(res.data.response._id);
               const updatedChecklists = update(this.state.checklists, { $splice: [[index, 1, res.data.response]] });
               this.setState({ checklists: updatedChecklists });
            } else {
               toast.error(res.data.message, { delay: 150 });
            }
         } else {
            toast.error(res.data.message, { delay: 150 });
         }
      }).catch(error => {
         toast.error('Error while retrieving checklist details', { delay: 150 });
      })

   }

   deleteProjectChecklist(index, item) {
      toast.dismiss();
      if (item._id) {
         if (!this.state.wsInProgress) {
            confirmAlert({
               title: 'Confirm to delete',
               message: 'Are you sure to do this?',
               buttons: [
                  {
                     label: 'Cancel',
                     onClick: () => { }
                  },
                  {
                     label: 'Confirm',
                     onClick: () => {
                        this.setState({
                           wsInProgress: true
                        }, () => {
                           let checklistObject = this.state.checklists[index];
                           this.params = {
                              _id: checklistObject._id,
                              token: this.state.token
                           }
                           axios.post(GLOBAL.SERVER_URL + "/api/delete-project-checklist", this.params).then(res => {
                              this.setState({
                                 wsInProgress: false
                              }, () => {
                                 if (res.data.errorCode === 0) {
                                    this.setState({
                                       checklists: this.state.checklists.filter((_, i) => i !== index)
                                    });
                                    toast.success(res.data.message, { delay: 150 });
                                 } else {
                                    toast.error(res.data.message, { delay: 150 });
                                 }
                              });
                           }).catch(error => {
                              toast.error("Error occured. Please try again!", { delay: 150 });
                           });
                        });
                     }
                  },
               ]
            });
         }
      } else {
         toast.error("Invalid operation!", { delay: 150 });
      }
   }

   render() {

      if (this.state.unauthorizedAccess) {
         return <Redirect to="/dashboard" push={true} />;
      }

      if (this.state.projectId === null) {
         return <Redirect to="/projects" push={true} />;
      }

      return (
         <div className="gray-bg full-screen">
            <Sidebar activeMenu={'projects'} ref="sidebarComponent" syncLoggedInUserData={this.syncLoggedInUserData.bind(this)} />
            <div className="inner-container">
               <Topbar ref="topbarComponent" toggleSidebar={this.toggleSidebar.bind(this)} />
               <div className="mb-2 mt-2">
                  <div className="inner-conntainer">
                     <div className="row">
                        <div className="col-12 col-md-9">
                           <ProjectTabs activeLink={'checklist'} ref="projectTabsComponent" />
                        </div>
                        <div className="col-12 col-md-3 text-right">
                           {(this.state.accessLevels.indexOf(241) > -1) ?
                              <button className="add-btn" onClick={() => this.openPopup(null)}>Add Entry</button>
                              : null}
                           {this.state.showPopup ?
                              <PopupManageChecklistMain
                                 projectId={this.state.projectId} activeChecklist={this.state.activeChecklist}
                                 getProjectChecklists={this.getProjectChecklists.bind(this)} getProjectChecklistDetails={this.getProjectChecklistDetails.bind(this)} closePopup={this.closePopup.bind(this)}
                              />
                              : null
                           }
                        </div>
                     </div>
                     <ChecklistMainDetails checklists={this.state.checklists} loggedInUserId={this.state.loggedInUserId} accessLevels={this.state.accessLevels} openPopup={this.openPopup.bind(this)} deleteProjectChecklist={this.deleteProjectChecklist.bind(this)} />
                  </div>
               </div>
            </div>
         </div>
      );
   }
}
export default Checklists;
