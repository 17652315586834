import React, { Component } from "react";
import axios from 'axios';
import cookie from 'react-cookies';
import { toast } from 'react-toastify';
import update from 'immutability-helper';
import { confirmAlert } from 'react-confirm-alert';
import { Redirect } from 'react-router-dom';

import 'react-confirm-alert/src/react-confirm-alert.css';
import Sidebar from '../includes/sidebar';
import Topbar from '../includes/topbar';
import * as GLOBAL from '../../../global';
import './users.css';
import PopupManageUser from './popups/manage-user';

class Users extends Component {

  constructor(props) {
    super(props);
    this.state = {
      users: [],
      loggedInUserId: '',
      superAdminUserId: null,
      accessLevels: [],
      unauthorizedAccess: false,
      showPopup: false,
      activeUser: null,
      wsInProgress: false,
      token: cookie.load('_atclToken')
    };
  }

  componentDidMount() {
    if (this.state.token && typeof this.state.token != 'undefined') {
      this.getUsers();
    }
  }

  syncLoggedInUserData(user) {
    this.refs.topbarComponent.syncLoggedInUserData(user);
    this.setState({
      loggedInUserId: user._id,
      superAdminUserId: user.superAdminUserId,
      accessLevels: user.roleDetails[0].accessLevels,
      unauthorizedAccess: (user.roleDetails[0].accessLevels.indexOf(100) === -1) ? true : false
    });
  }

  toggleSidebar() {
    this.refs.sidebarComponent.toggleSidebar();
 }

  openPopup(user) {
    if (user) {
      this.setState({
        activeUser: user,
        showPopup: true
      });
    } else {
      this.setState({
        showPopup: true
      });
    }
  }

  closePopup() {
    this.setState({
      activeUser: null,
      showPopup: false
    });
  }

  getUsers = () => {

    toast.dismiss();
    axios.get(GLOBAL.SERVER_URL + "/api/get-users?token=" + this.state.token).then(res => {
      if (res.data.errorCode === 0) {
        this.setState({
          users: res.data.response
        });
      } else {
        toast.error(res.data.message, { delay: 150 });
      }
    }).catch(error => {
      toast.error('Error while retrieving users', { delay: 150 });
    })

  }

  getUserDetails = (userId) => {

    toast.dismiss();
    axios.get(GLOBAL.SERVER_URL + "/api/get-user?token=" + this.state.token + "&reqUserId=" + userId + "&loggedInUser=0").then(res => {
      if (res.data.errorCode === 0) {
        const index = this.state.users.map(user => user._id).indexOf(res.data.response._id);
        const updatedUsers = update(this.state.users, { $splice: [[index, 1, res.data.response]] });
        this.setState({ users: updatedUsers });
      } else {
        toast.error('Error while retrieving user details', { delay: 150 });
      }
    }).catch(error => {
      toast.error('Error while retrieving user details', { delay: 150 });
    })

  }

  deleteUser(index, user) {
    toast.dismiss();
    if (user._id) {
      if (!this.state.wsInProgress) {
        confirmAlert({
          title: 'Confirm to delete',
          message: 'Are you sure to do this?',
          buttons: [
            {
              label: 'Cancel',
              onClick: () => { }
            },
            {
              label: 'Confirm',
              onClick: () => {
                this.setState({
                  wsInProgress: true
                }, () => {
                  let userObject = this.state.users[index];
                  this.params = {
                    _id: userObject._id,
                    token: this.state.token
                  }
                  axios.post(GLOBAL.SERVER_URL + "/api/delete-user", this.params).then(res => {
                    this.setState({
                      wsInProgress: false
                    }, () => {
                      if (res.data.errorCode === 0) {
                        this.setState({
                          users: this.state.users.filter((_, i) => i !== index)
                        });
                        toast.success(res.data.message, { delay: 150 });
                      } else {
                        toast.error(res.data.message, { delay: 150 });
                      }
                    });
                  }).catch(error => {
                    toast.error("Error occured. Please try again!", { delay: 150 });
                  });
                });
              }
            },
          ]
        });
      }
    }
  }

  render() {

    if (this.state.unauthorizedAccess) {
      return <Redirect to="/dashboard" push={true} />;
    }

    return (
      <div className="gray-bg full-screen">
        <Sidebar activeMenu={'users'} ref="sidebarComponent" syncLoggedInUserData={this.syncLoggedInUserData.bind(this)} />
        <div className="inner-container">
          <Topbar ref="topbarComponent" toggleSidebar={this.toggleSidebar.bind(this)} />
          <div className="users-main-desk">
            <div className="row mb-3 page-header">
              <div className="col-6 col-lg-10 text-left">
                <h1 className="user-h1">Users</h1>
              </div>
              <div className="col-6 col-lg-2 text-right">
                {(this.state.accessLevels.indexOf(101) > -1) ?
                  <button className="custom-btn add-user-btn" onClick={() => this.openPopup(null)}> Add User </button>
                  : null}
                {this.state.showPopup ?
                  <PopupManageUser activeUser={this.state.activeUser}
                    closePopup={this.closePopup.bind(this)} getUsers={this.getUsers.bind(this)} getUserDetails={this.getUserDetails.bind(this)}
                  />
                  : null
                }
              </div>
            </div>
            {(this.state.users.length > 0) &&
              <div className="">
                <table className="table custom-table user-table table-responsive-md">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Username</th>
                      <th scope="col">Role</th>
                      <th scope="col">Contact Number</th>
                      <th scope="col">Office ID</th>
                      <th scope="col">Designation</th>
                      <th scope="col" className="text-center">Active</th>
                      <th scope="col" className="width-125"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.users.map((user, index) => {
                      return (
                        <tr key={index}>
                          <td>{user.firstName} {user.lastName}</td>
                          <td>{user.username}</td>
                          <td>{user.roleDetails[0].name}</td>
                          <td>{user.contactNumber}</td>
                          <td>{user.officeId}</td>
                          <td>{user.designationDetails[0].name}</td>
                          <td className="text-center">{user.active ? <span className="active-indication"></span> : <span className="inactive-indication"></span>}</td>
                          <td className="text-center">
                            {((this.state.accessLevels.indexOf(102) > -1) && (this.state.loggedInUserId === user._id)) ?
                              <button className="custom-btn goto-btn small-btn" onClick={() => this.openPopup(user)} ></button>
                              : ((this.state.accessLevels.indexOf(103) > -1) && (this.state.loggedInUserId !== user._id)) ?
                                <button className="custom-btn goto-btn small-btn" onClick={() => this.openPopup(user)} ></button>
                                : null}
                            {((this.state.loggedInUserId !== user._id) && (this.state.accessLevels.indexOf(104) > -1) && (this.state.superAdminUserId !== user._id)) ?
                              <button className="delete-btn active" onClick={() => this.deleteUser(index, user)} ></button>
                              : <button className="delete-btn disabled"></button>
                            }
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            }
            {(this.state.users.length === 0) && <p className="no-items-text">No users found!</p>}
          </div>
        </div>
        <div className="clear"></div>
      </div>
    );
  }
}

export default Users;
