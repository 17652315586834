import React, { Component } from "react";
import { Link } from 'react-router-dom';

class TabConsumablesMain extends Component {

   constructor(props) {
      super(props);
      this.state = {
      };
   }

   render() {
      return (
         <div className="main-div-consumable">
            <div className="row">
               {(this.props.consumables.length > 0) && this.props.consumables.map((consumable, index) => {

                  return (
                     <div key={index} className="col-12 col-md-4 col-xl-4">
                        <div className="consumable-blocks">
                           {(((this.props.accessLevels.indexOf(262) > -1) || (this.props.accessLevels.indexOf(264) > -1)) && this.props.loggedInUserId == this.props.superAdminUserId) ?
                              <div className="more-menu">
                                 <span></span>
                                 <ul>
                                    {(this.props.accessLevels.indexOf(242) > -1) ?
                                       <li onClick={() => this.props.openPopup(consumable)}>Edit</li>
                                       : null}
                                    {(this.props.accessLevels.indexOf(243) > -1) ?
                                       <li onClick={() => this.props.deleteConsumable(index, consumable)}>Delete</li>
                                       : null}
                                 </ul>
                              </div>
                              : null}
                           <Link className="clickable-block" to={{ pathname: '/single-consumable/' + consumable.projectId + '/' + consumable._id }} >
                              <h5>{consumable.name}</h5>
                           </Link>
                        </div>
                     </div>
                  )
               })
               }
            </div>
         </div>
      );
   }
}
export default TabConsumablesMain;
