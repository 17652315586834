import React, { Component } from "react";
import axios from 'axios';
import cookie from 'react-cookies';
import { toast } from 'react-toastify';
import update from 'immutability-helper';
import { confirmAlert } from 'react-confirm-alert';
import { Redirect } from 'react-router-dom';

import * as GLOBAL from '../../../global';
import Sidebar from '../includes/sidebar';
import Topbar from '../includes/topbar';
import OrdersInnerDetails from './includes/orders-inner';
import PopupManageOrder from './popups/manage-order';

class Orders extends Component {

   constructor(props) {
      super(props)
      this.state = {
         orders: [],
         activeOrder: null,
         showPopup: false,
         loggedInUserId: '',
         accessLevels: [],
         unauthorizedAccess: false,
         wsInProgress: false,
         token: cookie.load('_atclToken')
      };
   }

   componentDidMount() {
      this.getOrders();
   }

   syncLoggedInUserData(user) {
      this.refs.topbarComponent.syncLoggedInUserData(user);
      this.setState({
         loggedInUserId: user._id,
         accessLevels: user.roleDetails[0].accessLevels,
         unauthorizedAccess: (user.roleDetails[0].accessLevels.indexOf(200) === -1) ? true : false
      });
   }

   toggleSidebar() {
      this.refs.sidebarComponent.toggleSidebar();
   }

   openPopup(order) {
      let params = {
         showPopup: true
      };
      if (order) {
         params.activeOrder = order;
      }
      this.setState(params);
   }

   closePopup() {
      this.setState({
         showPopup: false,
         activeOrder: null
      });
   }

   getOrders = () => {

      toast.dismiss();

      axios.get(GLOBAL.SERVER_URL + "/api/get-orders?token=" + this.state.token).then(res => {
         if (res.data.errorCode === 0) {
            this.setState({
               orders: res.data.response
            });
         } else {
            toast.error(res.data.message, { delay: 150 });
         }
      }).catch(error => {
         toast.error('Error while retrieving orders', { delay: 150 });
      })

   }

   getOrderDetails = (orderId) => {

      toast.dismiss();
      axios.get(GLOBAL.SERVER_URL + "/api/get-order-by-id?token=" + this.state.token + "&orderId=" + orderId).then(res => {
         if (res.data.errorCode === 0) {
            if (res.data.response._id) {
               const index = this.state.orders.map(order => order._id).indexOf(res.data.response._id);
               const updatedOrders = update(this.state.orders, { $splice: [[index, 1, res.data.response]] });
               this.setState({ orders: updatedOrders });
            } else {
               toast.error(res.data.message, { delay: 150 });
            }
         } else {
            toast.error(res.data.message, { delay: 150 });
         }
      }).catch(error => {
         toast.error('Error while retrieving order details', { delay: 150 });
      })

   }

   deleteOrder(item) {
      toast.dismiss();
      if (item._id) {
         if (!this.state.wsInProgress) {
            confirmAlert({
               title: 'Confirm to delete',
               message: 'Are you sure to do this?',
               buttons: [
                  {
                     label: 'Cancel',
                     onClick: () => { }
                  },
                  {
                     label: 'Confirm',
                     onClick: () => {
                        this.setState({
                           wsInProgress: true
                        }, () => {
                           let orderObject = item;
                           this.params = {
                              _id: orderObject._id,
                              token: this.state.token
                           }
                           axios.post(GLOBAL.SERVER_URL + "/api/delete-order-entry", this.params).then(res => {
                              this.setState({
                                 wsInProgress: false
                              }, () => {
                                 if (res.data.errorCode === 0) {
                                    this.getOrders();
                                    toast.success(res.data.message, { delay: 150 });
                                 } else {
                                    toast.error(res.data.message, { delay: 150 });
                                 }
                              });
                           }).catch(error => {
                              toast.error("Error occured. Please try again!", { delay: 150 });
                           });
                        });
                     }
                  },
               ]
            });
         }
      } else {
         toast.error("Invalid operation!", { delay: 150 });
      }
   }


   render() {
      if (this.state.unauthorizedAccess) {
         return <Redirect to="/dashboard" push={true} />;
      }

      return (
         <div className="gray-bg full-screen">
            <Sidebar activeMenu={'orders'} ref="sidebarComponent" syncLoggedInUserData={this.syncLoggedInUserData.bind(this)} />
            <div className="inner-container order-module">
               <Topbar ref="topbarComponent" toggleSidebar={this.toggleSidebar.bind(this)} />
               <div className="mb-2 mt-4">
                  <div className="inner-conntainer">
                     <div className="row">
                        <div className="col-12 col-md-9 text-left page-header">
                           <h1>Orders</h1>
                        </div>
                        <div className="col-12 col-md-3 text-right">
                           {(this.state.accessLevels.indexOf(307) > -1) ?
                              <button className="custom-btn" onClick={() => this.openPopup(null)}>Create Order</button>
                              : null}
                           {this.state.showPopup ?
                              <PopupManageOrder activeOrder={this.state.activeOrder} getOrders={this.getOrders.bind(this)} getOrderDetails={this.getOrderDetails.bind(this)} closePopup={this.closePopup.bind(this)}
                              />
                              : null
                           }
                        </div>
                     </div>
                     <OrdersInnerDetails orders={this.state.orders} loggedInUserId={this.state.loggedInUserId} accessLevels={this.state.accessLevels} openPopup={this.openPopup.bind(this)} deleteOrder={this.deleteOrder.bind(this)} />
                  </div>
               </div>
            </div>
         </div>
      );
   }
}
export default Orders;
