import React from 'react';
import axios from 'axios';
import cookie from 'react-cookies';
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";

import * as GLOBAL from '../../../../global';
import "react-datepicker/dist/react-datepicker.css";
import '../project.css';

class PopupManageConsumableInner extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      _id: '',
      projectId: '',
      consumableId: '',
      consumableNotes:'',
      date: '',
      brand: '',
      quantityReceived: '',
      quantityConsumed: '',
      orderType: '',
      invoiceNumber: '',
      deliveryNotes: '',
      projectLocations: [],
      locationId: '',
      wsInProgress: false,
      token: cookie.load('_atclToken')
    }
  }

  componentDidMount() {
    if ((this.props.projectId !== '') && (this.props.consumableId !== '')) {
      this.setState({
        projectId: this.props.projectId,
        consumableId: this.props.consumableId
      });
      if (this.props.activeConsumableEntry) {
        this.setState({
          _id: this.props.activeConsumableEntry._id,
          date: new Date(this.props.activeConsumableEntry.date),
          brand: this.props.activeConsumableEntry.brand,
          quantityReceived: this.props.activeConsumableEntry.quantityReceived,
          quantityConsumed: this.props.activeConsumableEntry.quantityConsumed,
          orderType: this.props.activeConsumableEntry.orderType,
          invoiceNumber: this.props.activeConsumableEntry.invoiceNumber,
          deliveryNotes: this.props.activeConsumableEntry.deliveryNotes,
          consumableNotes: this.props.activeConsumableEntry.consumableNotes,

        });
        if ((this.props.activeConsumableEntry.projects.length !== 0)) {
          this.setState({
            locationId: this.props.activeConsumableEntry.projects[0]._id
          });
        }
      }
      this.getProjectLocations();
    }
  }

  getProjectLocations = () => {
    axios.get(GLOBAL.SERVER_URL + "/api/get-project-locations").then(res => {
      this.setState({
        projectLocations: res.data.response,
      });
    });
  }


  handleDateChange = date => {
    this.setState({
      date: date
    });
  };

  handleReceivedQtyChange(e) {
    var value = e.target.value ? ((parseFloat(e.target.value) < 0) ? 0 : e.target.value) : 0;
    if (parseFloat(value) % 1 != 0) {
      value = parseFloat(value).toFixed(2);
    }
    this.setState({ quantityReceived: value });
  }

  handleConsumedQtyChange(e) {
    var value = e.target.value ? ((parseFloat(e.target.value) < 0) ? 0 : e.target.value) : 0;
    if (parseFloat(value) % 1 != 0) {
      value = parseFloat(value).toFixed(2);
    }
    this.setState({ quantityConsumed: value });
  }

  validate = () => {

    let nameReg = /^[\w -_]*$/;

    if (!this.state.date) {
      toast.error('Date cannot be blank', { delay: 150 });
      return false;
    } else if (!this.state.brand) {
      toast.error('Brand name is required', { delay: 150 });
      return false;
    } else if (!(this.state.brand).match(nameReg)) {
      toast.error('Enter a valid Brand Name', { delay: 150 });
      return false;
    } else if ((!this.state.quantityReceived && !this.state.quantityConsumed) || (this.state.quantityReceived && (this.state.quantityReceived === 0) && this.state.quantityConsumed && (this.state.quantityConsumed === 0))) {
      toast.error('Either received quantity or consumed quantity value is required', { delay: 150 });
      return false;
    } else if (this.state.quantityReceived == 0 && this.state.quantityConsumed == 0) {
      toast.error('Either received quantity or consumed quantity valid value', { delay: 150 });
      return false;
    } else if (this.state.quantityReceived > 0 && this.state.quantityConsumed > 0) {
      toast.error('Add only received quantity or consumed quantity', { delay: 150 });
      return false;
    } else if (this.state.quantityReceived && isNaN(this.state.quantityReceived)) {
      toast.error('Insert only valid received quantity', { delay: 150 });
      return false;
    } else if (this.state.quantityConsumed && isNaN(this.state.quantityConsumed)) {
      toast.error('Insert only valid consumed quantity', { delay: 150 });
      return false;
    } else if (this.state.quantityReceived && (this.state.quantityReceived !== '0') && !this.state.orderType) {
      toast.error('Type cannot be empty', { delay: 150 });
      return false;
    } else if (this.state.quantityReceived && (this.state.quantityReceived !== '0') && (this.state.orderType === 'Purchase') && !this.state.invoiceNumber) {
      toast.error('Invoice Number cannot be blank', { delay: 150 });
      return false;
    } else {
      return true;
    }
  }

  saveEntry = (event) => {
    event.preventDefault();
    if (!this.state.wsInProgress) {
      toast.dismiss();
      const isValid = this.validate();
      if (isValid) {
        this.setState({
          wsInProgress: true
        }, () => {

          this.params = {
            _id: this.state._id,
            projectId: this.state.projectId,
            consumableId: this.state.consumableId,
            date: this.state.date,
            brand: this.state.brand,
            quantityReceived: this.state.quantityReceived,
            quantityConsumed: this.state.quantityConsumed,
            token: this.state.token
          }
          this.params.orderType = this.state.quantityReceived ? this.state.orderType : '';
          this.params.invoiceNumber = this.state.quantityReceived ? this.state.invoiceNumber : '';
          this.params.deliveryNotes = this.state.quantityReceived ? this.state.deliveryNotes : '';
          this.params.consumableNotes = this.state.quantityConsumed ? this.state.consumableNotes : '';
          this.params.locationId = this.state.orderType == "Transfer" ? this.state.locationId : ''
          console.log(this.params);
          axios.post(GLOBAL.SERVER_URL + "/api/save-consumable-entry", this.params).then(res => {
            this.setState({
              wsInProgress: false
            }, () => {
              if (res.data.errorCode === 0) {
                toast.success(res.data.message, { delay: 150 });
                this.props.getConsumableEntries(this.state.consumableId);
                this.props.closePopup();
              } else {
                toast.error(res.data.message, { delay: 150 });
              }
            });
          }).catch(error => {
            toast.error("Error occured. Please try again!", { delay: 150 });
          });
        });
      }
    }
  }

  render() {

    return (
      <div className="popup">
        <div className="popup_inner popup_inner_medium">
          <div className="page-header">
            <div className="material-card text-left">
              <div className="popup-heads">
                <div className="row">
                  <div className="col-12 col-sm-6 col-xl-6">
                    <h3 className="popup-title">{this.state._id ? 'Update' : 'Add'} Entry</h3>
                  </div>
                  <div className="col-12 col-sm-6 col-xl-6">
                    <button onClick={this.saveEntry} className="entry-save project-save">Save</button>
                    <button onClick={this.props.closePopup} className="entry-close project-close">Cancel</button>
                  </div>
                </div>
              </div>
              <div className="popup-body p-b-15">

                <div className="row">
                  <div className="col-12 col-md-6 col-xl-6">
                    <div className="form-group">
                      <label htmlFor="date">Date <span className="required">*</span></label>
                      <DatePicker className="form-control"
                        dateFormat="dd/MM/yyyy h:mm aa"
                        selected={this.state.date}
                        maxDate={new Date()}
                        onChange={this.handleDateChange}
                        timeFormat="h:mm aa"
                        showTimeSelect
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-xl-6">
                    <div className="form-group">
                      <label htmlFor="brand">Brand <span className="required">*</span></label>
                      <input type="text" id="brand" className="form-control" autoComplete="off" value={this.state.brand} onChange={(e) => this.setState({ brand: e.target.value })} />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-xl-6">
                    <div className="form-group">
                      <label htmlFor="quantity-received">Quantity Received <span className="required">*</span></label>
                      <input type="number" min="0" max="10000" id="quantity-received" name="quantityReceived" className="form-control" autoComplete="off" value={this.state.quantityReceived} onChange={(e) => this.setState({ quantityReceived: e.target.value })} onBlur={this.handleReceivedQtyChange.bind(this)} />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-xl-6">
                    <div className="form-group">
                      <label htmlFor="quantity-consumed">Quantity Consumed <span className="required">*</span></label>
                      <input type="number" min="0" max="10000" id="quantity-consumed" name="quantityConsumed" className="form-control" autoComplete="off" value={this.state.quantityConsumed} onChange={(e) => this.setState({ quantityConsumed: e.target.value })} onBlur={this.handleConsumedQtyChange.bind(this)} />
                    </div>
                  </div>
                </div>
                {((this.state.quantityReceived !== '') && (this.state.quantityReceived > 0)) ?
                  <div className="row">
                    <div className="col-12 col-md-6 col-xl-6">
                      <div className="form-group">
                        <label htmlFor="order-type">Type<span className="required">*</span></label>
                        <select id="order-type" className="form-control" value={this.state.orderType} onChange={(e) => this.setState({ orderType: e.target.value })}>
                          <option value="">Select</option>
                          <option value="Transfer">Transfer</option>
                          <option value="Purchase">Purchase</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-6">
                      <div className="form-group">
                        <label htmlFor="invoiceNumber">Invoice Number {(this.state.orderType === 'Purchase') ? <span className="required">*</span> : null}</label>
                        <input type="text" id="invoiceNumber" className="form-control" value={this.state.invoiceNumber} onChange={(e) => this.setState({ invoiceNumber: e.target.value })} autoComplete="off" />
                      </div>
                    </div>
                    {this.state.orderType == "Transfer" ?
                      <div className="col-12">
                        <div className="form-group">
                          <label htmlFor="locationId">Location<span className="required">*</span></label>
                          <select id="locationId" className="form-control" value={this.state.locationId} onChange={(e) => this.setState({ locationId: e.target.value })}>
                            <option value="">Select</option>
                            {this.state.projectLocations.map((value, index) => {
                              return (
                                <option value={value._id} key={index}>{value.projectName}  {(value._id === this.state.workshopProjectId) ? '(WS)' : null}</option>
                              )
                            })}
                          </select>
                        </div>
                      </div>
                      :
                      null
                    }
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="deliveryNotes">Delivery notes</label>
                        <textarea id="deliveryNotes" className="form-control" rows="3" value={this.state.deliveryNotes} onChange={(e) => this.setState({ deliveryNotes: e.target.value })}></textarea>
                      </div>
                    </div>
                  </div>
                  : null
                }

                {/* consumable dev notes */}
                {((this.state.quantityConsumed !== '') && (this.state.quantityConsumed > 0) && (this.state.quantityReceived == '' || this.state.quantityReceived == 0)) ?
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="consumableNotes">Notes</label>
                        <textarea id="consumableNotes" className="form-control" rows="3" value={this.state.consumableNotes} onChange={(e) => this.setState({ consumableNotes: e.target.value })}></textarea>
                      </div>
                    </div>
                  </div>
                  : null
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PopupManageConsumableInner;