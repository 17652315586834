import React from 'react';
import axios from 'axios';
import cookie from 'react-cookies';
import { toast } from 'react-toastify';

import * as GLOBAL from '../../../../global';
import "../settings.css";

class PopupManageAdminDesignation extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      _id: '',
      name: '',
      wageRate: 0,
      otRate: 0,
      wsInProgress: false,
      token: cookie.load('_atclToken')
    }
  }

  componentDidMount() {
    if (this.props.activeDesignation) {
      this.setState({
        _id: this.props.activeDesignation._id,
        name: this.props.activeDesignation.name,
        wageRate: this.props.activeDesignation.wageRate,
        otRate: this.props.activeDesignation.otRate
      });
    }
  }

  handleDecimalValue(e, event) {
    let value = 0;
    if (event === 'change') {
       value = e.target.value;
    } else {
       value = e.target.value ? ((parseFloat(e.target.value) < 0) ? 0 : e.target.value) : 0;
       if (parseFloat(value) % 1 != 0) {
          value = parseFloat(value).toFixed(2);
       }
    }
    return value;
 }

 handleWageRateChange(e, event) {
    this.setState({
      wageRate: this.handleDecimalValue(e, event),
      otRate: this.handleDecimalValue(e, event) / 8
    });
 }

 handleOtRateChange(e, event) {
  this.setState({
    otRate: this.handleDecimalValue(e, event)
  });
}

  validate = () => {


    let nameReg = /^[\w -_]*$/;

    if (!this.state.name) {
      toast.error('Name cannot be blank', { delay: 150 });
      return false;
    } else if (!this.state.name.match(nameReg)) {
      toast.error('Enter a valid Name', { delay: 150 });
      return false;
    } else if (!this.state.wageRate || (this.state.wageRate === 0)) {
      toast.error('Enter a valid Wage Rate', { delay: 150 });
      return false;
    } else if (!this.state.otRate || (this.state.otRate === 0)) {
      toast.error('Enter a valid OT Rate', { delay: 150 });
      return false;
    } else {
      return true;
    }
  }

  saveDesignation = (event) => {
    event.preventDefault();
    if (!this.state.wsInProgress) {
      toast.dismiss();
      const isValid = this.validate();
      if (isValid) {
        this.setState({
          wsInProgress: true
        }, () => {
          this.params = {
            _id: this.state._id,
            name: this.state.name,
            wageRate: this.state.wageRate,
            otRate: this.state.otRate,
            token: this.state.token
          }

          axios.post(GLOBAL.SERVER_URL + "/api/save-designation", this.params).then(res => {
            this.setState({
              wsInProgress: false
            }, () => {
              if (res.data.errorCode === 0) {
                toast.success(res.data.message, { delay: 150 });
                if (this.state._id !== '') {
                  this.props.getDesignationDetails(this.state._id);
                } else {
                  this.props.getDesignations();
                }
                this.props.closePopup();
              } else {
                toast.error(res.data.message, { delay: 150 });
              }
            });
          }).catch(error => {
            toast.error("Error occured. Please try again!", { delay: 150 });
          });
        });
      }
    }
  }

  render() {
    return (
      <div className="popup">
        <div className="popup_inner popup_inner_medium">
          <div className="page-header">
            <div className="material-card text-left">
              <div className="popup-heads">
                <div className="row">

                  <div className="col-12 col-sm-6 col-xl-6">
                    <h3 className="popup-title">{this.state._id ? 'Update' : 'Add'} Designation</h3>
                  </div>
                  <div className="col-12 col-sm-6 col-xl-6">
                    <button onClick={this.saveDesignation} className="entry-save green-save">Save</button>
                    <button onClick={this.props.closePopup} className="entry-close green-close">Cancel</button>
                  </div>
                </div>
              </div>
              <div className="popup-body p-b-15">
                <div className="form-group">
                  <label htmlFor="name">Name</label>
                  <input type="text" id="name" className="form-control" autoComplete="off" value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} maxLength="30" />
                </div>
                <div className="row">
                  <div className="col-12 col-md-6 col-xl-6">
                    <div className="form-group">
                      <label htmlFor="wage-rate">Wage Rate</label>
                      <input type="text" id="wage-rate" className="form-control" autoComplete="off" value={this.state.wageRate} onChange={(e) => this.handleWageRateChange(e, 'change')} onBlur={(e) => this.handleWageRateChange(e, 'blur')} />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-xl-6">
                    <div className="form-group">
                      <label htmlFor="ot-rate">OT Rate</label>
                      <input type="text" id="ot-rate" className="form-control" autoComplete="off" value={this.state.otRate} onChange={(e) => this.handleOtRateChange(e, 'change')} onBlur={(e) => this.handleOtRateChange(e, 'blur')} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }
}
export default PopupManageAdminDesignation;
