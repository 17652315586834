import React, { Component } from "react";
import axios from 'axios';
import cookie from 'react-cookies';
import { toast } from 'react-toastify';
import { Accordion, Button, Card } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import update from 'immutability-helper';
import { Redirect } from 'react-router-dom';

import 'react-confirm-alert/src/react-confirm-alert.css';
import "./settings.css";
import * as GLOBAL from '../../../global';
import Sidebar from '../includes/sidebar';
import Topbar from '../includes/topbar';
import PopupManageAdminUserRoles from "./popups/manage-user-role";
import SettingsTab from "./settings-tab";

class SettingsUserRole extends Component {

    constructor(props) {
        super(props);
        this.state = {
            roles: [],
            activeRole: null,
            activeAccordionKey: '',
            activeLink: '',
            showPopup: false,
            loggedInUserId: '',
            adminUserRoleId: null,
            accessLevels: [],
            accessLevelsList: [],
            unauthorizedAccess: false,
            wsInProgress: false,
            token: cookie.load('_atclToken')
        }

    }

    componentDidMount() {
        if (this.state.token && typeof this.state.token != 'undefined') {
            this.getAccessLevels();
        }
    }

    syncLoggedInUserData(user) {
        this.refs.topbarComponent.syncLoggedInUserData(user);
        this.setState({
            loggedInUserId: user._id,
            accessLevels: user.roleDetails[0].accessLevels,
            superAdminUserId: user.superAdminUserId,
            unauthorizedAccess: (user.roleDetails[0].accessLevels.indexOf(400) === -1) ? true : false
        });
    }

    toggleSidebar() {
        this.refs.sidebarComponent.toggleSidebar();
    }

    openPopup(role) {
        let params = {
            showPopup: true
        };
        if (role) {
            params.activeRole = role;
        }
        this.setState(params);
    }

    closePopup() {
        this.setState({
            showPopup: false,
            activeRole: null
        });
    }

    activeAccordionToggle = (id) => {
        this.setState({
            activeAccordionKey: (this.state.activeAccordionKey === id) ? '' : id
        });
    }

    getAccessLevels = () => {
        toast.dismiss();
        axios.get(GLOBAL.SERVER_URL + "/api/get-access-levels?token=" + this.state.token).then(res => {
            if (res.data.errorCode === 0) {
                this.setState({
                    accessLevelsList: res.data.response
                });
                this.getRoles();
            } else {
                toast.error(res.data.message, { delay: 150 });
            }
        }).catch(error => {
            toast.error('Error while retrieving access levels', { delay: 150 });
        })
    }

    getRoles = () => {
        toast.dismiss();
        axios.get(GLOBAL.SERVER_URL + "/api/get-roles?token=" + this.state.token).then(res => {
            if (res.data.errorCode === 0) {
                this.setState({
                    roles: res.data.response,
                    adminUserRoleId: res.data.adminUserRoleId,
                    activeAccordionKey: res.data.response[0]._id
                });
            } else {
                toast.error(res.data.message, { delay: 150 });
            }
        }).catch(error => {
            toast.error('Error while retrieving roles', { delay: 150 });
        })
    }

    getRoleDetails = (roleId) => {
        toast.dismiss();
        axios.get(GLOBAL.SERVER_URL + "/api/get-role-by-id?token=" + this.state.token + "&roleId=" + roleId).then(res => {
            if (res.data.errorCode === 0) {
                if (res.data.response._id) {
                    const index = this.state.roles.map(role => role._id).indexOf(res.data.response._id);
                    const updatedRoles = update(this.state.roles, { $splice: [[index, 1, res.data.response]] });
                    this.setState({ roles: updatedRoles });
                } else {
                    toast.error(res.data.message, { delay: 150 });
                }
            } else {
                toast.error(res.data.message, { delay: 150 });
            }
        }).catch(error => {
            toast.error('Error while retrieving role details', { delay: 150 });
        })
    }

    deleteRole(index, item) {
        toast.dismiss();
        if (item._id) {
            if (!this.state.wsInProgress) {
                confirmAlert({
                    title: 'Confirm to delete',
                    message: 'Are you sure to do this?',
                    buttons: [
                        {
                            label: 'Cancel',
                            onClick: () => { }
                        },
                        {
                            label: 'Confirm',
                            onClick: () => {
                                this.setState({
                                    wsInProgress: true
                                }, () => {
                                    let roleObject = this.state.roles[index];
                                    this.params = {
                                        _id: roleObject._id,
                                        token: this.state.token
                                    }
                                    axios.post(GLOBAL.SERVER_URL + "/api/delete-role", this.params).then(res => {
                                        this.setState({
                                            wsInProgress: false
                                        }, () => {
                                            if (res.data.errorCode === 0) {
                                                this.setState({
                                                    roles: this.state.roles.filter((_, i) => i !== index)
                                                });
                                                toast.success(res.data.message, { delay: 150 });
                                            } else {
                                                toast.error(res.data.message, { delay: 150 });
                                            }
                                        });
                                    }).catch(error => {
                                        toast.error("Error occured. Please try again!", { delay: 150 });
                                    });
                                });
                            }
                        },
                    ]
                });
            }
        } else {
            toast.error("Invalid operation!", { delay: 150 });
        }
    }

    render() {

        if (this.state.unauthorizedAccess) {
            return <Redirect to="/dashboard" push={true} />;
        }

        return (
            <div className="gray-bg full-screen">
                <Sidebar activeMenu={'settings'} ref="sidebarComponent" syncLoggedInUserData={this.syncLoggedInUserData.bind(this)} />
                <div className="inner-container">
                    <Topbar ref="topbarComponent" toggleSidebar={this.toggleSidebar.bind(this)} />
                    <div className="row">
                        <div className="col-12 col-sm-9 col-xl-10">
                            <SettingsTab activeLink={'settings-user-roles'} />
                        </div>
                        <div className="col-12 col-md-3 col-xl-2">
                            {(this.state.accessLevels.indexOf(421) > -1) ?
                                <button className="add-btn-green" onClick={() => this.openPopup(null)}>Add User Roles</button>
                                : null}
                            {this.state.showPopup ?
                                <PopupManageAdminUserRoles activeRole={this.state.activeRole} superAdminUserId={this.state.superAdminUserId} accessLevelsList={this.state.accessLevelsList}
                                    getRoles={this.getRoles.bind(this)} getRoleDetails={this.getRoleDetails.bind(this)} closePopup={this.closePopup.bind(this)}
                                />
                                : null
                            }
                        </div>
                    </div>

                    {(this.state.roles.length > 0) ?
                        <Accordion defaultActiveKey={this.state.activeAccordionKey} >
                            {this.state.roles.map((role, index) => {
                                return (
                                    <Card key={index} className={(this.state.activeAccordionKey === role._id) ? 'active-accordion' : ''}>
                                        <Card.Header>
                                            <div className="row">
                                                <div className="col-6 col-md-8 col-xl-9">
                                                    <p className="tab-first overflow-ellipsis">{role.name}</p>
                                                </div>
                                                <div className="col-6 col-md-4 col-xl-3 right-float-edits text-right">
                                                    {(this.state.accessLevels.indexOf(422) > -1) ?
                                                        <button className="settings-edit-button settings-btn btn" onClick={() => this.openPopup(role)} alt="Edit button"></button>
                                                        : null}
                                                    {((this.state.accessLevels.indexOf(423) > -1) && (role._id !== this.state.adminUserRoleId)) ?
                                                        <button className="settings-delete-button settings-btn btn" onClick={() => this.deleteRole(index, role)} alt="Delete button"></button>
                                                        : null}
                                                    <Accordion.Toggle as={Button} variant="link" eventKey={role._id} onClick={() => this.activeAccordionToggle(role._id)} className="settings-toggle-button settings-btn" alt="Toggle button"></Accordion.Toggle>
                                                </div>
                                            </div>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey={role._id}>
                                            <Card.Body>
                                                <div className="accor-body">
                                                    {(role.accessLevels.length > 0) ?
                                                        <div className="row">
                                                            {this.state.accessLevelsList.map((accessLevel, aIndex) => {
                                                                return (
                                                                    (role.accessLevels.indexOf(accessLevel.value) !== -1) ?
                                                                        <div key={aIndex} className="col-md-6">
                                                                            <div className="adv-checkbox">
                                                                                <label htmlFor={accessLevel._id}>
                                                                                    <input type="checkbox" readOnly checked id={accessLevel._id} />
                                                                                    <span className="custom-checkbox">
                                                                                        <i className="icon-check"></i>
                                                                                    </span>
                                                                                    {accessLevel.label}
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        : null
                                                                )
                                                            })}
                                                        </div>
                                                        : <p className="no-data">Access levels not assigned</p>}
                                                </div>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                )
                            })
                            }
                        </Accordion>
                        : null}
                </div>
            </div>
        );
    }
}
export default SettingsUserRole;