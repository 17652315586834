import React from "react";
import axios from 'axios';
import cookie from 'react-cookies';
import { toast } from 'react-toastify';
import * as GLOBAL from '../../../../global';
import '../../../../assets/css/style.css';

class PopupManageComment extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      _id: '',
      itemId: '',
      projectId: '',
      comment: '',
      wsInProgress: false,
      token: cookie.load('_atclToken')
    }
  }

  componentDidMount() {
    if (this.props.activeComment.commentId !== '') {
      this.setState({
        _id: this.props.activeComment.commentId
      });
    }
    this.setState({
      itemId: this.props.activeComment.itemId,
      projectId: this.props.activeComment.projectId,
      comment: this.props.activeComment.comment
    });
  }

  validate = () => {
    if (!this.state.comment) {
      toast.error('Comment cannot be blank', { delay: 150 });
      return false;
    } else {
      return true;
    }
  }

  saveComment = (event) => {
    event.preventDefault();
    if (!this.state.wsInProgress) {

      toast.dismiss();
      const isValid = this.validate();
      if (isValid) {

        this.setState({
          wsInProgress: true
        }, () => {
          this.params = {
            _id: this.state._id,
            itemId: this.state.itemId,
            projectId: this.state.projectId,
            comment: this.state.comment,
            token: this.state.token
          }

          axios.post(GLOBAL.SERVER_URL + "/api/save-inventory-comment", this.params).then(res => {

            this.setState({
              wsInProgress: false
            }, () => {
              if (res.data.errorCode === 0) {
                toast.success(res.data.message, { delay: 150 });
                if (this.state._id !== '') {
                  this.props.getInventoryCommentDetails(this.state._id);
                }
                this.props.closePopup();
              } else {
                toast.error(res.data.message, { delay: 150 });
              }
            });

          }).catch(error => {
            toast.error("Error occured. Please try again!", { delay: 150 });
          });
        });

      }

    }
  }

  render() {

    return (
      <div className="popup">
        <div className="popup_inner popup_inner_medium">
          <div className="page-header">
            <div className="material-card text-left">
              <div className="popup-heads">
                <div className="row">
                  <div className="col-12 col-sm-6 col-xl-6">
                    <h3 className="popup-title">{this.state._id ? 'Update' : 'Add'} Comment</h3>
                  </div>
                  <div className="col-12 col-sm-6 col-xl-6">
                    <button onClick={this.saveComment} className="entry-save">Save</button>
                    <button onClick={this.props.closePopup} className="entry-close">Cancel</button>
                  </div>
                </div>
              </div>
              <div className="popup-body p-b-15">
                <div className="form-group">
                  <label htmlFor="comment">Comment <span className="required">*</span></label>
                  <textarea className="form-control" id="comment" rows="4" value={this.state.comment} onChange={(e) => this.setState({ comment: e.target.value })} ></textarea>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    );

  }
}

export default PopupManageComment;