import React, { Component } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

class TabConsumablesInner extends Component {

    constructor(props) {
        super(props);
        this.state = {
            columns: [],
            defaultSorted: [],
            expandRow: {}
        };
    }

    dateFormatter(cell, row) {
        return (
            new Intl.DateTimeFormat('en-GB', {
                month: 'long',
                day: '2-digit',
                year: 'numeric',
            }).format(new Date(cell))
        );
    }

    componentDidMount() {
        this.setState({
            columns: [{
                dataField: 'date',
                text: 'Date',
                sort: true,
                classes: 'text-left',
                headerClasses: ' text-left',
                formatter: this.dateFormatter
            }, {
                dataField: 'brand',
                text: 'Brand'
            }, {
                dataField: 'orderType',
                text: 'Type'
            }, {
                dataField: 'quantityReceived',
                text: 'Qty Received'
            }, {
                dataField: 'cumulativeReceipt',
                text: 'Cumulative Receipt'
            }, {
                dataField: 'quantityConsumed',
                text: 'Qty Consumed'
            }, {
                dataField: 'cumulativeConsumption',
                text: 'Cumulative Consumption'
            },
            {
                dataField: 'balance',
                text: 'Balance'
            }],
            defaultSorted: [{
                dataField: 'date',
                order: 'desc'
            }],
            expandRow: {
                renderer: row => (
                    <div className="row align-items-center">
                        <div className="col-md-9 col-lg-10 text-left">
                            {row.invoiceNumber && row.invoiceNumber!=' ' ? <p className="mb-0"><b>Invoice Number: </b>{row.invoiceNumber}</p> : null}
                            {row.projects[0] ? <p className="mb-0"><b>Location: </b>{row.projects[0].projectName}</p> : null}
                            {row.deliveryNotes && row.deliveryNotes!=''   ? <p className="mb-0"><b>Delivery Notes: </b>{row.deliveryNotes}</p> : null}
                            {row.consumableNotes && row.consumableNotes!=''  ? <p className="mb-0"><b>Notes: </b>{row.consumableNotes}</p> : null}
                        </div>
                        <div className="col-md-3 col-lg-2 text-right">
                            {(this.props.accessLevels.indexOf(265) > -1) ?
                                <button className="custom-edit-button" onClick={() => this.props.openPopup(row)}></button>
                                : null}
                            {(this.props.accessLevels.indexOf(266) > -1) ?
                                <button className="custom-delete-button" onClick={() => this.props.deleteConsumableItemEntry(row)}></button>
                                : null}
                        </div>
                    </div>
                ),
                showExpandColumn: true
            }
        });
    }

    render() {
        return (
            <div className="consum-register">
                {this.props.consumableDetails ?
                    <h1 className="page-heading">{this.props.consumableDetails.name} <span>({this.props.consumableDetails.unit[0].unit})</span></h1>
                    : null}
                {(this.props.consumableEntries.length > 0) ?
                    <BootstrapTable bootstrap4 classes="table custom-table table-responsive-sm table-responsive-lg table-responsive-xl" keyField='_id' data={this.props.consumableEntries} columns={this.state.columns} expandRow={this.state.expandRow} defaultSorted={this.state.defaultSorted} pagination={paginationFactory({ hideSizePerPage: true })} noDataIndication="Data is not available" />
                    : null}
            </div>
        );
    }
}
export default TabConsumablesInner;