import React from 'react';
import axios from 'axios';
import cookie from 'react-cookies';
import { toast } from 'react-toastify';
import { Multiselect } from 'multiselect-react-dropdown';

import * as GLOBAL from '../../../../global';
import '../project.css';

class PopupManageChecklistInner extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      _id: '',
      projectId: '',
      selectedChecklists: [],
      checklists: [],
      wsInProgress: false,
      token: cookie.load('_atclToken')
    }
  }

  componentDidMount() {
    if (this.props.projectId !== '') {
      this.setState({
        projectId: this.props.projectId
      });
      if (this.props.activeChecklist) {
        this.setState({
          _id: this.props.activeChecklist._id,
          selectedChecklists: this.props.activeChecklist.checklists
        });
      }
      this.getChecklists();
    }
  }

  getChecklists = () => {
    axios.get(GLOBAL.SERVER_URL + "/api/get-checklists").then(res => {
      this.setState({
        checklists: res.data.response
      });
    });
  }

  onChangeChecklist = (event) => {
    this.setState({
      selectedChecklists: event
    });
  }

  validate = () => {
    if (this.state.selectedChecklists.length === 0) {
      toast.error('Select atleast one checklist from the list', { delay: 150 });
      return false;
    } else {
      return true;
    }
  }

  saveChecklist = (event) => {
    event.preventDefault();
    if (!this.state.wsInProgress) {
      toast.dismiss();
      const isValid = this.validate();
      if (isValid) {
        this.setState({
          wsInProgress: true
        }, () => {
          this.params = {
            _id: this.state._id,
            projectId: this.state.projectId,
            checklistArea: this.props.activeChecklist.checklistArea,
            selectedChecklists: this.state.selectedChecklists,
            token: this.state.token
          }

          axios.post(GLOBAL.SERVER_URL + "/api/save-project-checklist", this.params).then(res => {
            this.setState({
              wsInProgress: false
            }, () => {
              if (res.data.errorCode === 0) {
                this.props.getProjectChecklists(this.state._id);
                toast.success(res.data.message, { delay: 150 });
                this.props.closePopup();
              } else {
                toast.error(res.data.message, { delay: 150 });
              }
            });
          }).catch(error => {
            toast.error("Error occured. Please try again!", { delay: 150 });
          });
        });
      }
    }
  }

  render() {
    return (
      <div className="popup">
        <div className="popup_inner popup_inner_small">
          <div className="page-header">
            <div className="material-card text-left">
              <div className="popup-heads">
                <div className="row">
                  <div className="col-12 col-sm-6 col-xl-6">
                    <h3 className="popup-title">Add Checklist</h3>
                  </div>
                  <div className="col-12 col-sm-6 col-xl-6">
                    <button onClick={this.saveChecklist} className="entry-save project-save">Save</button>
                    <button onClick={this.props.closePopup} className="entry-close project-close">Cancel</button>
                  </div>
                </div>
              </div>
              <div className="popup-body">

                <div className="form-group">
                  <label htmlFor="title">Project Area</label>
                  <div className="form-control disabled" id="title">{this.props.activeChecklist.checklistArea}</div>
                </div>

                <div className="form-group">
                  <label htmlFor="checklist">Checklist <span className="required">*</span></label>
                  <Multiselect id="checklist" className="form-control"
                    options={this.state.checklists}
                    selectedValues={this.state.selectedChecklists}
                    emptyRecordMsg="Checklists not available"
                    placeholder="Select Checklist"
                    onSelect={this.onChangeChecklist}
                    onRemove={this.onChangeChecklist}
                    displayValue="title"
                  />
                </div>

              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

export default PopupManageChecklistInner;