import React, { Component } from "react";

class TabOrganization extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>
                <div className="content-org">
                    <div className="row org-mem-row">

                        {(this.props.members.length > 0) &&
                            this.props.members.map((member, index) => {
                                return (
                                    <div key={index} className="col-12 col-sm-6 col-lg-4 col-xl-3 comm-border">
                                        {((this.props.accessLevels.indexOf(222) > -1) || (this.props.accessLevels.indexOf(223) > -1)) ?
                                            <div className="more-menu">
                                                <span></span>
                                                <ul>
                                                    {(this.props.accessLevels.indexOf(222) > -1) ?
                                                        <li onClick={() => this.props.openPopup(member)}>Edit</li>
                                                        : null}
                                                    {(this.props.accessLevels.indexOf(223) > -1) ?
                                                        <li onClick={() => this.props.removeMember(index, member)}>Delete</li>
                                                        : null}
                                                </ul>
                                            </div>
                                            : null}
                                        <div className="row">
                                            <div className="col-2 col-xl-3">
                                                <div className="member-img">
                                                    {member.memberData[0].firstName.charAt(0)}{member.memberData[0].lastName ? member.memberData[0].lastName.charAt(0) : ''}
                                                </div>
                                            </div>
                                            <div className="col-9 col-xl-9">
                                                <div className="org-mem-details">
                                                    <p>Name</p>
                                                    <h5>{member.memberData[0].firstName} {member.memberData[0].lastName}</h5>
                                                    <p>Designation</p>
                                                    <h5>{member.designationData[0].name}</h5>
                                                    <p>Contact</p>
                                                    <h5>{member.memberData[0].contactNumber}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </div>

                    {/* <div className="org-mem-page-link">
                        <ul>
                            <li><Link to="" className="prev"> Previous Page</Link></li>
                            <li><p className="active-slide">01/05</p></li>
                            <li><Link to="" className="next">Next Page</Link></li>
                        </ul>
                    </div> */}
                </div>
            </div>
        );
    }
}
export default TabOrganization;
