import React, { Component } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

class OrdersInnerDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            columns: [],
            defaultSorted: [],
            expandRow: {}
        };
    }

    dateFormatter(cell, row) {
        return (
            new Date(cell).toLocaleDateString("en-GB", {
                month: 'long',
                day: '2-digit',
                year: 'numeric',
            })
        );
    }

    // projectDataFormatter(cell, row) {
    //     return (cell[0].projectName);
    // }

    orderTypeFormatter(cell, row) {
        return (cell === 'plant-machinery') ? 'Plant & Machinery' : 'Consumable';
    }

    // getItemName(cell, row) {
    //     let itemName = '';
    //     if (row.items && row.items.length > 0) {
    //         itemName = row.items[0].itemName;
    //     }
    //     if (row.consumables && row.consumables.length > 0) {
    //         itemName = row.consumables[0].name;
    //     }
    //     return itemName;
    // }

    componentDidMount() {
        this.setState({
            accessLevels: this.props.accessLevels,
            columns: [{
                dataField: 'orderDate',
                text: 'Order Date',
                sort: true,
                classes: 'text-left',
                headerClasses: ' text-left',
                formatter: this.dateFormatter
            }, {
                dataField: 'toProjectName',
                text: 'To Project',
                sort: true

            }, {
                dataField: 'fromProjectName',
                text: 'From Project',
                sort: true,
            }, {
                dataField: 'type',
                text: 'Type',
                sort: true,
                formatter: this.orderTypeFormatter
            }, {
                dataField: 'itemName',
                text: 'Item',
                sort: true,
                // formatter: this.getItemName
            }, {
                dataField: 'quantity',
                text: 'Quantity',
                sort: true,
            }, {
                dataField: 'status',
                text: 'Status',
                sort: true,
            }],
            defaultSorted: [{
                dataField: 'orderDate',
                order: 'desc'
            }],
            expandRow: {
                renderer: row => (
                    <div className="row align-items-center">
                        <div className="col-md-9 col-lg-10 text-left">
                            {row.tripId ? <p className="mb-0"><b>Trip ID: </b>{row.tripId}</p> : null}
                            {row.invoiceNumber ? <p className="mb-0"><b>Invoice Number: </b>{row.invoiceNumber}</p> : null}
                            {row.deliveryAgent ? <p className="mb-0"><b>Delivered By: </b>{row.deliveryAgent}</p> : null}
                            {row.deliveryNotes ? <p className="mb-0"><b>Delivery Notes: </b>{row.deliveryNotes}</p> : null}
                        </div>
                        <div className="col-md-3 col-lg-2 text-right">
                            {((row.status !== 'Delivered') && (this.props.accessLevels.indexOf(310) > -1)) ?
                                <button className="order-edit-btn" onClick={() => this.props.openPopup(row)}></button>
                                : null}
                            {((row.status !== 'Delivered')) ?
                                <button className="order-delet-ebtn" onClick={() => this.props.deleteOrder(row)} ></button>
                                : null
                            }
                        </div>
                    </div>
                ),
                showExpandColumn: true
            }
        });
    }

    render() {
        return (
            <div className="mt-4">
                {(this.props.orders.length > 0) ?
                    <BootstrapTable bootstrap4 classes="table custom-table table-responsive-sm table-responsive-lg table-responsive-xl" keyField='_id' data={this.props.orders} columns={this.state.columns} expandRow={this.state.expandRow} defaultSorted={this.state.defaultSorted} pagination={paginationFactory({ hideSizePerPage: true })} noDataIndication="Data is not available" />
                    : null}
            </div>
        );
    }
}
export default OrdersInnerDetails;