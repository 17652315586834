import React, { Component } from "react";
import axios from 'axios';
import cookie from 'react-cookies';
import { toast } from 'react-toastify';
import { Accordion, Card } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import update from 'immutability-helper';
import { Redirect } from 'react-router-dom';

import 'react-confirm-alert/src/react-confirm-alert.css';
import "./settings.css";
import * as GLOBAL from '../../../global';
import Sidebar from '../includes/sidebar';
import Topbar from '../includes/topbar';
import PopupManageAdminUnits from "./popups/manage-units";
import SettingsTab from "./settings-tab";

class SettingsUnit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            units: [],
            activeUnit: null,
            activeLink: '',
            showPopup: false,
            loggedInUserId: '',
            accessLevels: [],
            unauthorizedAccess: false,
            wsInProgress: false,
            token: cookie.load('_atclToken')
        }
    }

    componentDidMount() {
        if (this.state.token && typeof this.state.token != 'undefined') {
            this.getUnits();
        }
    }

    syncLoggedInUserData(user) {
        this.refs.topbarComponent.syncLoggedInUserData(user);
        this.setState({
            loggedInUserId: user._id,
            accessLevels: user.roleDetails[0].accessLevels,
            unauthorizedAccess: (user.roleDetails[0].accessLevels.indexOf(400) === -1) ? true : false
        });
    }

    toggleSidebar() {
        this.refs.sidebarComponent.toggleSidebar();
     }

    openPopup(unit) {
        let params = {
            showPopup: true
        };
        if (unit) {
            params.activeUnit = unit;
        }
        this.setState(params);
    }

    closePopup() {
        this.setState({
            showPopup: false,
            activeUnit: null
        });
    }

    getUnits = () => {
        toast.dismiss();
        axios.get(GLOBAL.SERVER_URL + "/api/get-units?token=" + this.state.token).then(res => {
            if (res.data.errorCode === 0) {
                var sorted =  res.data.response.sort((a, b) => {
                    let fa = a.label.toLowerCase(),
                        fb = b.label.toLowerCase();
                
                    if (fa < fb) {
                        return -1;
                    }
                    if (fa > fb) {
                        return 1;
                    }
                    return 0;
                });
                this.setState({
                    units: sorted
                });
            } else {
                toast.error(res.data.message, { delay: 150 });
            }
        }).catch(error => {
            toast.error('Error while retrieving units', { delay: 150 });
        })
    }

    getUnitDetails = (unitId) => {

        toast.dismiss();
        axios.get(GLOBAL.SERVER_URL + "/api/get-unit-by-id?token=" + this.state.token + "&unitId=" + unitId).then(res => {
            if (res.data.errorCode === 0) {
                if (res.data.response._id) {
                    const index = this.state.units.map(unit => unit._id).indexOf(res.data.response._id);
                    const updatedUnits = update(this.state.units, { $splice: [[index, 1, res.data.response]] });
                    this.setState({ units: updatedUnits });
                } else {
                    toast.error(res.data.message, { delay: 150 });
                }
            } else {
                toast.error(res.data.message, { delay: 150 });
            }
        }).catch(error => {
            toast.error('Error while retrieving unit details', { delay: 150 });
        })

    }

    deleteUnit(index, item) {
        toast.dismiss();
        if (item._id) {
            if (!this.state.wsInProgress) {
                confirmAlert({
                    title: 'Confirm to delete',
                    message: 'Are you sure to do this?',
                    buttons: [
                        {
                            label: 'Cancel',
                            onClick: () => { }
                        },
                        {
                            label: 'Confirm',
                            onClick: () => {
                                this.setState({
                                    wsInProgress: true
                                }, () => {
                                    let unitObject = this.state.units[index];
                                    this.params = {
                                        _id: unitObject._id,
                                        token: this.state.token
                                    }
                                    axios.post(GLOBAL.SERVER_URL + "/api/delete-unit", this.params).then(res => {
                                        this.setState({
                                            wsInProgress: false
                                        }, () => {
                                            if (res.data.errorCode === 0) {
                                                this.setState({
                                                    units: this.state.units.filter((_, i) => i !== index)
                                                });
                                                toast.success(res.data.message, { delay: 150 });
                                            } else {
                                                toast.error(res.data.message, { delay: 150 });
                                            }
                                        });
                                    }).catch(error => {
                                        toast.error("Error occured. Please try again!", { delay: 150 });
                                    });
                                });
                            }
                        },
                    ]
                });
            }
        } else {
            toast.error("Invalid operation!", { delay: 150 });
        }
    }

    render() {

        if (this.state.unauthorizedAccess) {
            return <Redirect to="/dashboard" push={true} />;
        }

        return (
            <div className="gray-bg full-screen">
                <Sidebar activeMenu={'settings'} ref="sidebarComponent" syncLoggedInUserData={this.syncLoggedInUserData.bind(this)} />
                <div className="inner-container">
                    <Topbar ref="topbarComponent" toggleSidebar={this.toggleSidebar.bind(this)} />
                    <div className="row">
                        <div className="col-12 col-sm-9 col-xl-10">
                            <SettingsTab activeLink={'settings-units'} />
                        </div>
                        <div className="col-12 col-md-3 col-xl-2">
                            {(this.state.accessLevels.indexOf(441) > -1) ?
                                <button className="add-btn-green" onClick={() => this.openPopup(null)}>Add Unit</button>
                                : null}
                            {this.state.showPopup ?
                                <PopupManageAdminUnits activeUnit={this.state.activeUnit}
                                    getUnits={this.getUnits.bind(this)} getUnitDetails={this.getUnitDetails.bind(this)} closePopup={this.closePopup.bind(this)}
                                />
                                : null
                            }
                        </div>
                    </div>
                    {(this.state.units.length > 0) ?
                        <Accordion defaultActiveKey="0">
                            {this.state.units.map((item, index) => {
                                return (
                                    <Card key={index}>
                                        <Card.Header>
                                            <div className="row">
                                                <div className="col-6 col-md-8 col-xl-9">
                                                    <p className="tab-first overflow-ellipsis">{item.label}</p>
                                                </div>
                                                <div className="col-6 col-md-4 col-xl-3 right-float-edits text-right">
                                                    {(this.state.accessLevels.indexOf(442) > -1) ?
                                                        <img src={require("../../../assets/img/edit-green.svg")} onClick={() => this.openPopup(item)} alt="Edit button" />
                                                        : null}
                                                    {(this.state.accessLevels.indexOf(443) > -1) ?
                                                        <img src={require("../../../assets/img/delete-green.svg")} onClick={() => this.deleteUnit(index, item)} alt="Delete button" />
                                                        : null}
                                                </div>
                                            </div>
                                        </Card.Header>
                                    </Card>
                                )
                            })
                            }
                        </Accordion>
                        : null}
                </div>
            </div>
        );
    }
}
export default SettingsUnit;