import React, { Component } from "react";
import axios from 'axios';
import cookie from 'react-cookies';
import { toast } from 'react-toastify';
import { Accordion, Button, Card } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import update from 'immutability-helper';
import { Redirect } from 'react-router-dom';

import 'react-confirm-alert/src/react-confirm-alert.css';
import "./settings.css";
import * as GLOBAL from '../../../global';
import Sidebar from '../includes/sidebar';
import Topbar from '../includes/topbar';
import PopupManageAdminDesignation from "./popups/manage-designation";
import SettingsTab from "./settings-tab";


class SettingsDesignation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            designations: [],
            activeDesignation: null,
            activeAccordionKey: '',
            activeLink: '',
            showPopup: false,
            loggedInUserId: '',
            accessLevels: [],
            unauthorizedAccess: false,
            wsInProgress: false,
            token: cookie.load('_atclToken')
        }
    }

    componentDidMount() {
        if (this.state.token && typeof this.state.token != 'undefined') {
            this.getDesignations();
        }
    }

    syncLoggedInUserData(user) {
        this.refs.topbarComponent.syncLoggedInUserData(user);
        this.setState({
            loggedInUserId: user._id,
            accessLevels: user.roleDetails[0].accessLevels,
            unauthorizedAccess: (user.roleDetails[0].accessLevels.indexOf(400) === -1) ? true : false
        });
    }

    toggleSidebar() {
        this.refs.sidebarComponent.toggleSidebar();
     }

    openPopup(designation) {
        let params = {
            showPopup: true
        };
        if (designation) {
            params.activeDesignation = designation;
        }
        this.setState(params);
    }

    closePopup() {
        this.setState({
            showPopup: false,
            activeDesignation: null
        });
    }

    activeAccordionToggle = (id) => {
        this.setState({
            activeAccordionKey: (this.state.activeAccordionKey === id) ? '' : id
        });
    }

    getDesignations = () => {
        toast.dismiss();
        axios.get(GLOBAL.SERVER_URL + "/api/get-designations?token=" + this.state.token).then(res => {
            if (res.data.errorCode === 0) {
               var sorted =  res.data.response.sort((a, b) => {
                    let fa = a.name.toLowerCase(),
                        fb = b.name.toLowerCase();
                
                    if (fa < fb) {
                        return -1;
                    }
                    if (fa > fb) {
                        return 1;
                    }
                    return 0;
                });
                this.setState({
                    designations: sorted
                });
            } else {
                toast.error(res.data.message, { delay: 150 });
            }
        }).catch(error => {
            toast.error('Error while retrieving designations', { delay: 150 });
        })
    }

    getDesignationDetails = (designationId) => {

        toast.dismiss();
        axios.get(GLOBAL.SERVER_URL + "/api/get-designation-by-id?token=" + this.state.token + "&designationId=" + designationId).then(res => {
            if (res.data.errorCode === 0) {
                if (res.data.response._id) {
                    const index = this.state.designations.map(designation => designation._id).indexOf(res.data.response._id);
                    const updatedDesignations = update(this.state.designations, { $splice: [[index, 1, res.data.response]] });
                    this.setState({ designations: updatedDesignations });
                } else {
                    toast.error(res.data.message, { delay: 150 });
                }
            } else {
                toast.error(res.data.message, { delay: 150 });
            }
        }).catch(error => {
            toast.error('Error while retrieving designation details', { delay: 150 });
        })

    }

    deleteDesignation(index, item) {
        toast.dismiss();
        if (item._id) {
            if (!this.state.wsInProgress) {
                confirmAlert({
                    title: 'Confirm to delete',
                    message: 'Are you sure to do this?',
                    buttons: [
                        {
                            label: 'Cancel',
                            onClick: () => { }
                        },
                        {
                            label: 'Confirm',
                            onClick: () => {
                                this.setState({
                                    wsInProgress: true
                                }, () => {
                                    let designationObject = this.state.designations[index];
                                    this.params = {
                                        _id: designationObject._id,
                                        token: this.state.token
                                    }
                                    axios.post(GLOBAL.SERVER_URL + "/api/delete-designation", this.params).then(res => {
                                        this.setState({
                                            wsInProgress: false
                                        }, () => {
                                            if (res.data.errorCode === 0) {
                                                this.setState({
                                                    designations: this.state.designations.filter((_, i) => i !== index)
                                                });
                                                toast.success(res.data.message, { delay: 150 });
                                            } else {
                                                toast.error(res.data.message, { delay: 150 });
                                            }
                                        });
                                    }).catch(error => {
                                        toast.error("Error occured. Please try again!", { delay: 150 });
                                    });
                                });
                            }
                        },
                    ]
                });
            }
        } else {
            toast.error("Invalid operation!", { delay: 150 });
        }
    }

    render() {

        if (this.state.unauthorizedAccess) {
            return <Redirect to="/dashboard" push={true} />;
        }

        return (
            <div className="gray-bg full-screen">
                <Sidebar activeMenu={'settings'} ref="sidebarComponent" syncLoggedInUserData={this.syncLoggedInUserData.bind(this)} />
                <div className="inner-container">
                    <Topbar ref="topbarComponent" toggleSidebar={this.toggleSidebar.bind(this)} />
                    <div className="row">
                        <div className="col-12 col-sm-9 col-xl-10">
                            <SettingsTab activeLink={'settings-designations'} />
                        </div>
                        <div className="col-12 col-md-3 col-xl-2">
                            {(this.state.accessLevels.indexOf(461) > -1) ?
                                <button className="add-btn-green" onClick={() => this.openPopup(null)}>Add Designation</button>
                                : null}
                            {this.state.showPopup ?
                                <PopupManageAdminDesignation activeDesignation={this.state.activeDesignation}
                                    getDesignations={this.getDesignations.bind(this)} getDesignationDetails={this.getDesignationDetails.bind(this)} closePopup={this.closePopup.bind(this)}
                                />
                                : null
                            }
                        </div>
                    </div>
                    {(this.state.designations.length > 0) ?
                        <Accordion defaultActiveKey="0">
                            {this.state.designations.map((item, index) => {
                                return (
                                    <Card key={index}>
                                        <Card.Header>
                                            <div className="row">
                                                <div className="col-6 col-md-8 col-xl-9">
                                                    <div className="titleContent">
                                                        <p className="tab-first overflow-ellipsis">{item.name}</p>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-4 col-xl-3 right-float-edits text-right">
                                                    {(this.state.accessLevels.indexOf(462) > -1) ?
                                                        <img src={require("../../../assets/img/edit-green.svg")} onClick={() => this.openPopup(item)} alt="Edit button" />
                                                        : null}
                                                    {(this.state.accessLevels.indexOf(463) > -1) ?
                                                        <img src={require("../../../assets/img/delete-green.svg")} onClick={() => this.deleteDesignation(index, item)} alt="Delete button" />
                                                        : null}
                                                    <Accordion.Toggle as={Button} variant="link" eventKey={item._id} onClick={() => this.activeAccordionToggle(item._id)} className="settings-toggle-button settings-btn" alt="Toggle button"></Accordion.Toggle>
                                                </div>
                                            </div>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey={item._id}>
                                            <Card.Body>
                                                <div className="accor-body">
                                                    <div className="designation-details">
                                                        <p><span>Wage Rate</span>: <b>₹{item.wageRate}</b></p>
                                                        <p><span>OT Rate</span>: <b>₹{item.otRate}</b></p>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                )
                            })
                            }
                        </Accordion>
                        : null}
                </div>
            </div>
        );
    }
}
export default SettingsDesignation;