import React, { Component } from "react";
import axios from 'axios';
import cookie from 'react-cookies';
import { toast } from 'react-toastify';
import { Accordion, Button, Card } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import update from 'immutability-helper';
import { Redirect } from 'react-router-dom';

import 'react-confirm-alert/src/react-confirm-alert.css';
import "./settings.css";
import * as GLOBAL from '../../../global';
import Sidebar from '../includes/sidebar';
import Topbar from '../includes/topbar';
import PopupManageAdminChecklist from "./popups/manage-checklist";
import SettingsTab from "./settings-tab";

class SettingsChecklist extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checklists: [],
            activeChecklist: null,
            activeAccordionKey: '',
            activeLink: '',
            showPopup: false,
            loggedInUserId: '',
            accessLevels: [],
            unauthorizedAccess: false,
            wsInProgress: false,
            token: cookie.load('_atclToken')
        }
    }

    componentDidMount() {
        if (this.state.token && typeof this.state.token != 'undefined') {
            this.getChecklists();
        }
    }

    syncLoggedInUserData(user) {
        this.refs.topbarComponent.syncLoggedInUserData(user);
        this.setState({
            loggedInUserId: user._id,
            accessLevels: user.roleDetails[0].accessLevels,
            unauthorizedAccess: (user.roleDetails[0].accessLevels.indexOf(400) === -1) ? true : false
        });
    }

    toggleSidebar() {
        this.refs.sidebarComponent.toggleSidebar();
     }

    openPopup(checklist) {
        let params = {
            showPopup: true
        };
        if (checklist) {
            params.activeChecklist = checklist;
        }
        this.setState(params);
    }

    closePopup() {
        this.setState({
            showPopup: false,
            activeChecklist: null
        });
    }

    activeAccordionToggle = (id) => {
        this.setState({
            activeAccordionKey: (this.state.activeAccordionKey === id) ? '' : id
        });
    }

    getChecklists = () => {
        toast.dismiss();
        axios.get(GLOBAL.SERVER_URL + "/api/get-checklists-with-items?token=" + this.state.token).then(res => {
            if (res.data.errorCode === 0) {
                this.setState({
                    checklists: res.data.response,
                    activeAccordionKey: res.data.response[0]._id
                });
            } else {
                toast.error(res.data.message, { delay: 150 });
            }
        }).catch(error => {
            // toast.error('Error while retrieving checklists', { delay: 150 });
        })
    }

    getChecklistDetails = (checklistId) => {

        toast.dismiss();
        axios.get(GLOBAL.SERVER_URL + "/api/get-checklist-by-id?token=" + this.state.token + "&checklistId=" + checklistId).then(res => {
            if (res.data.errorCode === 0) {
                if (res.data.response._id) {
                    const index = this.state.checklists.map(checklist => checklist._id).indexOf(res.data.response._id);
                    const updatedChecklists = update(this.state.checklists, { $splice: [[index, 1, res.data.response]] });
                    this.setState({ checklists: updatedChecklists });
                } else {
                    toast.error(res.data.message, { delay: 150 });
                }
            } else {
                toast.error(res.data.message, { delay: 150 });
            }
        }).catch(error => {
            toast.error('Error while retrieving checklist details', { delay: 150 });
        })

    }

    deleteChecklist(index, item) {
        toast.dismiss();
        if (item._id) {
            if (!this.state.wsInProgress) {
                confirmAlert({
                    title: 'Confirm to delete',
                    message: 'Are you sure to do this?',
                    buttons: [
                        {
                            label: 'Cancel',
                            onClick: () => { }
                        },
                        {
                            label: 'Confirm',
                            onClick: () => {
                                this.setState({
                                    wsInProgress: true
                                }, () => {
                                    let checklistObject = this.state.checklists[index];
                                    this.params = {
                                        _id: checklistObject._id,
                                        token: this.state.token
                                    }
                                    axios.post(GLOBAL.SERVER_URL + "/api/delete-checklist", this.params).then(res => {
                                        this.setState({
                                            wsInProgress: false
                                        }, () => {
                                            if (res.data.errorCode === 0) {
                                                this.setState({
                                                    checklists: this.state.checklists.filter((_, i) => i !== index)
                                                });
                                                toast.success(res.data.message, { delay: 150 });
                                            } else {
                                                toast.error(res.data.message, { delay: 150 });
                                            }
                                        });
                                    }).catch(error => {
                                        toast.error("Error occured. Please try again!", { delay: 150 });
                                    });
                                });
                            }
                        },
                    ]
                });
            }
        } else {
            toast.error("Invalid operation!", { delay: 150 });
        }
    }

    render() {

        if (this.state.unauthorizedAccess) {
            return <Redirect to="/dashboard" push={true} />;
        }

        return (
            <div className="gray-bg full-screen">
                <Sidebar activeMenu={'settings'} ref="sidebarComponent" syncLoggedInUserData={this.syncLoggedInUserData.bind(this)} />
                <div className="inner-container">
                    <Topbar ref="topbarComponent" toggleSidebar={this.toggleSidebar.bind(this)} />
                    <div className="row">
                        <div className="col-12 col-sm-9 col-xl-10">
                            <SettingsTab activeLink={'settings-checklists'} />
                        </div>
                        <div className="col-12 col-md-3 col-xl-2">
                            {(this.state.accessLevels.indexOf(401) > -1) ?
                                <button className="add-btn-green" onClick={() => this.openPopup(null)}> Add Checklist</button>
                                : null}
                            {this.state.showPopup ?
                                <PopupManageAdminChecklist activeChecklist={this.state.activeChecklist}
                                    getChecklists={this.getChecklists.bind(this)} getChecklistDetails={this.getChecklistDetails.bind(this)} closePopup={this.closePopup.bind(this)}
                                />
                                : null
                            }
                        </div>
                    </div>

                    {(this.state.checklists.length > 0) ?
                        <Accordion defaultActiveKey={this.state.activeAccordionKey}>
                            {this.state.checklists.map((checklist, index) => {
                                return (
                                    <Card key={index} className={(this.state.activeAccordionKey === checklist._id) ? 'active-accordion' : ''}>
                                        <Card.Header>
                                            <div className="row">
                                                <div className="col-6 col-md-8 col-xl-9">
                                                    <p className="tab-first">{checklist.title}</p>
                                                </div>
                                                <div className="col-6 col-md-4 col-xl-3 right-float-edits text-right">
                                                    {(this.state.accessLevels.indexOf(402) > -1) ?
                                                        <button className="settings-edit-button settings-btn btn" onClick={() => this.openPopup(checklist)} alt="Edit button"></button>
                                                        : null}
                                                    {(this.state.accessLevels.indexOf(403) > -1) ?
                                                        <button className="settings-delete-button settings-btn btn" onClick={() => this.deleteChecklist(index, checklist)} alt="Delete button"></button>
                                                        : null}
                                                    <Accordion.Toggle as={Button} variant="link" eventKey={checklist._id} onClick={() => this.activeAccordionToggle(checklist._id)} className="settings-toggle-button settings-btn" alt="Toggle button"></Accordion.Toggle>
                                                </div>
                                            </div>

                                        </Card.Header>
                                        <Accordion.Collapse eventKey={checklist._id}>
                                            <Card.Body>
                                                {(checklist.checklistItems.length > 0) &&
                                                    checklist.checklistItems.map((item, cIndex) => {
                                                        return (
                                                            <div key={cIndex} className="accor-body">
                                                                <div className="adv-checkbox">
                                                                    <label htmlFor={item._id}>
                                                                        <input type="checkbox" readOnly checked id={item._id} />
                                                                        <span className="custom-checkbox">
                                                                            <i className="icon-check"></i>
                                                                        </span>
                                                                        {item.description}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                )
                            })
                            }
                        </Accordion>
                        : null}
                </div>

            </div>
        );
    }
}
export default SettingsChecklist;