import React, { Component } from "react";
import { Link } from 'react-router-dom';

class ProjectTabs extends Component {

   constructor(props) {
      super(props)
      this.state = {
         projectId: null,
         activeLink: ''
      }
   }

   componentDidMount() {
      this.setState({
         activeLink: this.props.activeLink
      })
   }

   syncProjectId(projectId) {
      this.setState({
         projectId: projectId
      })
   }

   render() {
      return (
         <div className="page-header mt-2 project-header">
            <div className="row mb-2">
               <div className="back1">
                  <Link to='/projects' className="back-button projects-back">
                     Back</Link>
               </div>
               <div className="col-10 col-xl-9">
                  <div className="custom-tabs-div"> 
                     <ul className="custom-tabs has-link project-tabs">
                        <li className={((this.state.activeLink === 'organization') ? 'active-tab' : '')}>
                           <Link to={{ pathname: '/organization/' + this.state.projectId }}>Organization</Link>
                        </li>
                        <li className={((this.state.activeLink === 'checklist') ? 'active-tab' : '')}>
                           <Link to={{ pathname: '/checklists/' + this.state.projectId }}>Checklist</Link>
                        </li>
                        <li className={((this.state.activeLink === 'consumables') ? 'active-tab' : '')}>
                           <Link to={{ pathname: '/consumables/' + this.state.projectId }}>Consumables</Link>
                        </li>
                        <li className={((this.state.activeLink === 'labour-tracking') ? 'active-tab' : '')}>
                           <Link to={{ pathname: '/labour-tracking/' + this.state.projectId }}>Labour Tracking</Link>
                        </li>
                     </ul>
                  </div>

                  <div className="clear"></div>
               </div>
            </div>
         </div>
      );
   }
}

export default ProjectTabs;
