import React from "react";
import axios from 'axios';
import cookie from 'react-cookies';
import { toast } from 'react-toastify';

import * as GLOBAL from '../../../../global';
import '../../projects/project.css';
import '../users.css';

class PopupManageUser extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      _id: '',
      firstName: '',
      lastName: '',
      username: '',
      password: '',
      email: '',
      contactNumber: '',
      officeId: '',
      active: false,
      roleId: '',
      designationId: '',
      roles: [],
      designations: [],
      wsInProgress: false,
      token: cookie.load('_atclToken')
    }
  }

  componentDidMount() {
    if (this.props.activeUser) {
      this.setState({
        _id: this.props.activeUser._id,
        firstName: this.props.activeUser.firstName,
        lastName: this.props.activeUser.lastName,
        username: this.props.activeUser.username,
        email: this.props.activeUser.email,
        contactNumber: this.props.activeUser.contactNumber,
        officeId: this.props.activeUser.officeId,
        active: this.props.activeUser.active,
        roleId: this.props.activeUser.roleId,
        designationId: this.props.activeUser.designationId
      });
    }
    this.getDropdownItems();
  }

  getDropdownItems = () => {

    axios.get(GLOBAL.SERVER_URL + "/api/get-roles").then(res => {
      this.setState({
        roles: res.data.response
      });
    });

    axios.get(GLOBAL.SERVER_URL + "/api/get-designations?token=" + this.state.token).then(res => {
      this.setState({
        designations: res.data.response
      });
    });

  }

  validate = () => {

    let nameReg = /^[\w -_]*$/;
    let usernameReg = /^[a-zA-Z0-9]+([_ -]?[a-zA-Z0-9])*$/;
    let idNum = /^[a-zA-Z0-9_ ]*$/;
    let phoneNum = /^([+][9][1]|[9][1]|[0]){0,1}([7-9]{1})([0-9]{9})$/;

    if (!this.state.firstName) {
      toast.error('First Name cannot be blank', { delay: 150 });
      return false;
    } else if (!(this.state.firstName).match(nameReg)) {
      toast.error('Enter a valid First Name', { delay: 150 });
      return false;
    } else if (!(this.state.lastName).match(nameReg)) {
      toast.error('Enter a valid Last Name', { delay: 150 });
      return false;
    } else if (!this.state.username) {
      toast.error('Username cannot be blank', { delay: 150 });
      return false;
    } else if (!(this.state.username).match(usernameReg)) {
      toast.error('Enter a valid Username', { delay: 150 });
      return false;
    } else if (this.state.username.length < 5) {
      toast.error('Minimum 5 characters required for Username', { delay: 150 });
      return false;
    } else if (!this.state._id && !this.state.password) {
      toast.error('Password cannot be blank', { delay: 150 });
      return false;
    } else if (!this.state.designationId) {
      toast.error('Designation cannot be blank', { delay: 150 });
      return false;
    } else if (!this.state.officeId) {
      toast.error('Office ID cannot be blank', { delay: 150 });
      return false;
    } else if (!(this.state.officeId).match(idNum)) {
      toast.error('Enter a valid Office ID', { delay: 150 });
      return false;
    } else if (!this.state.contactNumber) {
      toast.error('Contact Number cannot be blank', { delay: 150 });
      return false;
    } else if (!(this.state.contactNumber).match(phoneNum)) {
      toast.error('Enter a valid Contact Number', { delay: 150 });
      return false;
    } else if (!this.state.roleId) {
      toast.error('Role cannot be blank', { delay: 150 });
      return false;
    } else {
      return true;
    }
  }

  saveUser = (event) => {
    event.preventDefault();
    if (!this.state.wsInProgress) {
      toast.dismiss();
      const isValid = this.validate();
      if (isValid) {
        this.setState({
          wsInProgress: true
        }, () => {
          this.params = {
            _id: this.state._id,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            username: this.state.username,
            password: this.state.password,
            email: this.state.email,
            contactNumber: this.state.contactNumber,
            officeId: this.state.officeId,
            active: this.state.active,
            roleId: this.state.roleId,
            designationId: this.state.designationId,
            token: this.state.token
          }

          axios.post(GLOBAL.SERVER_URL + "/api/save-user", this.params).then(res => {
            this.setState({
              wsInProgress: false
            }, () => {
              if (res.data.errorCode === 0) {
                toast.success(res.data.message, { delay: 150 });
                if (this.state._id !== '') {
                  this.props.getUserDetails(this.state._id);
                } else {
                  this.props.getUsers();
                }
                this.props.closePopup();
              } else if (res.data.errorCode === 2) {
                let passwordError = 'Password must be: \n';
                JSON.parse(res.data.message).forEach((item, index) => {
                  passwordError = passwordError + item + "\n";
                });
                toast.error(passwordError, { delay: 150 });
              } else {
                toast.error(res.data.message, { delay: 150 });
              }
            });
          }).catch(error => {
            toast.error("Error occured. Please try again!", { delay: 150 });
          });
        });
      }
    }
  }

  render() {
    return (
      <div className="popup">
        <div className="popup_inner popup_inner_medium">
          <div className="page-header">
            <div className="material-card text-left">
              <div className="popup-heads">
                <div className="row">
                  <div className="col-12 col-sm-6 col-xl-6">
                    <h3 className="popup-title">
                      {this.state._id ?
                        'Update User'
                        : 'Add User'
                      }</h3>
                  </div>
                  <div className="col-12 col-sm-6 col-xl-6">
                    <button onClick={this.saveUser} className="entry-save user-save">Save</button>
                    <button onClick={this.props.closePopup} className="entry-close user-close">Cancel</button>
                  </div>
                </div>
              </div>
              <div className="popup-body p-b-15">

                <div className="row mb-1">
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label htmlFor="firstName">First Name <span className="required">*</span></label>
                      <input type="text" id="firstName" name="firstName" className="form-control" value={this.state.firstName} onChange={(e) => this.setState({ firstName: e.target.value })} autoComplete="off" />
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label htmlFor="lastName">Last Name</label>
                      <input type="text" id="lastName" name="lastName" className="form-control" value={this.state.lastName} onChange={(e) => this.setState({ lastName: e.target.value })} autoComplete="off" />
                    </div>
                  </div>
                </div>

                <div className="row mb-1">
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label htmlFor="username">Username <span className="required">*</span></label>
                      <input type="username" id="username" name="username" className="form-control" value={this.state.username} onChange={(e) => this.setState({ username: e.target.value })} autoComplete="off" />
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label htmlFor="password">Password {(!this.state._id) ? <span className="required">*</span> : null}</label>
                      <input type="password" id="password" name="password" className="form-control" placeholder={"" + (this.state._id ? '********' : '')} value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} autoComplete="off" />
                    </div>
                  </div>
                </div>

                <div className="row mb-1">
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label htmlFor="designationId">Designation <span className="required">*</span></label>
                      <select className="form-control" id="designationId" name="designationId" value={this.state.designationId} onChange={(e) => this.setState({ designationId: e.target.value })} >
                        <option value="">Select designation</option>
                        {this.state.designations.map((value, index) => {
                          return (<option value={value._id} key={index}>{value.name}</option>)
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label htmlFor="officeId">Office ID <span className="required">*</span></label>
                      <input type="text" id="officeId" name="officeId" className="form-control" value={this.state.officeId} onChange={(e) => this.setState({ officeId: e.target.value })} autoComplete="off" />
                    </div>
                  </div>
                </div>

                <div className="row mb-1">
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label htmlFor="email">Email</label>
                      <input type="text" id="email" name="email" className="form-control" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} autoComplete="off" />
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label htmlFor="contactNumber">Contact Number <span className="required">*</span></label>
                      <input type="text" id="contactNumber" name="contactNumber" className="form-control" value={this.state.contactNumber} onChange={(e) => this.setState({ contactNumber: e.target.value })} autoComplete="off" />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label htmlFor="roleId">Role <span className="required">*</span></label>
                      <select className="form-control" id="roleId" name="roleId" value={this.state.roleId} onChange={(e) => this.setState({ roleId: e.target.value })} >
                        <option value="">Select role</option>
                        {this.state.roles.map((value, index) => {
                          return (<option value={value._id} key={index}>{value.name}</option>)
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label htmlFor="active">Active</label>
                      <select className="form-control" id="active" name="active" value={this.state.active} onChange={(e) => this.setState({ active: e.target.value })} >
                        <option value="false">False</option>
                        <option value="true">True</option>
                      </select>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
}

export default PopupManageUser;
