import React from 'react';
import cookie from 'react-cookies';
import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import update from 'immutability-helper';

import * as GLOBAL from '../../../../global';
import '../project.css';

class PopupViewLabourDetails extends React.Component {

   constructor(props) {
      super(props)
      this.state = {
         activeMember: null,
         wageRate: 0,
         userRoleId: '',
         otRate: 0,
         totalAttendance: 0,
         totalOTHours: 0,
         totalAmount: 0,
         totalWages: 0,
         totalOTAmount: 0,
         extraAllowance: 0,
         advanceDues: 0,
         newAdvance: 0,
         weeklyAdvance: 0,
         advanceDeduction: 0,
         finalAmount: 0,
         fieldEditStatus: null,
         wsInProgress: false,
         token: cookie.load('_atclToken'),
         accno: 0,
         ifsc: 0
      }

   }

   componentDidMount() {
      if (this.props.projectId && this.props.activeMember) {
         this.setState({
            activeMember: this.props.activeMember
         }, () => {
            toast.dismiss();
            let selectedDate = moment(this.state.activeMember.attendanceDetails[0].attendanceDate);
            let today = moment(new Date().toDateString("MM/DD/YYYY"));
            let weekendDay = moment(this.findeWeekenDay().toDateString("MM/DD/YYYY"))
            let diff = selectedDate.diff(today, 'days');
            let days = weekendDay.diff(selectedDate, 'days');
            let userRole = this.props.userRoleId;
            let adminUserId = '5e940fcc0ede797f3574e946';
            if (diff <= 0) {
               this.setState({ fieldEditStatus: true });
            } else {
               this.setState({
                  fieldEditStatus: false
               }, () => {
                  toast.error("Attendance cannot be added on future dates", { delay: 150 });
               });
            }

            if (days > 14 & userRole == adminUserId) {
               this.setState({ fieldEditStatus: true });
            } else if (days > 14 & userRole !== adminUserId) {
               this.setState({
                  fieldEditStatus: false
               }, () => {
                  toast.error("You are not a authorized user to edit two weeks befores attendence", { delay: 150 });
               });
            }

            this.setState({
               wageRate: (typeof this.state.activeMember.wageRate === 'undefined') ? parseFloat(this.state.activeMember.designationDetails[0].wageRate) : parseFloat(this.state.activeMember.wageRate),
               otRate: (typeof this.state.activeMember.otRate === 'undefined') ? parseFloat(this.state.activeMember.designationDetails[0].otRate) : parseFloat(this.state.activeMember.otRate),
               extraAllowance: parseFloat(this.state.activeMember.extraAllowance),
               advanceDues: (typeof this.state.activeMember.advanceDues === 'undefined') ? parseFloat(this.state.activeMember.advance) : parseFloat(this.state.activeMember.advanceDues),
               weeklyAdvance: (typeof this.state.activeMember.weeklyAdvance === 'undefined') ? 0 : parseFloat(this.state.activeMember.weeklyAdvance),
               newAdvance: (typeof this.state.activeMember.newAdvance === 'undefined') ? 0 : parseFloat(this.state.activeMember.newAdvance),
               advanceDeduction: (typeof this.state.activeMember.advanceDeduction === 'undefined') ? 0 : parseFloat(this.state.activeMember.advanceDeduction),
               finalAmount: parseFloat(this.state.activeMember.finalAmount).toFixed(2),
               accno: this.state.activeMember.accno != null ? this.state.activeMember.accno : 0,
               ifsc: this.state.activeMember.ifsc != null ? this.state.activeMember.ifsc.toUpperCase() : 0,
            }, () => {
               this.calculateTotalAttendanceAndOT();
            });
         });
      } else {
         this.props.closePopup();
      }
   }

   findeWeekenDay() {
      var lastday = new Date().getDate() - (new Date().getDay() - 1) + 6;
      return new Date(new Date().setDate(lastday));
   }

   calculateTotalAttendanceAndOT() {
      let totalAttendance = 0;
      let totalOTHours = 0;
      this.state.activeMember.attendanceDetails.forEach(function (entry) {
         if (entry.attendanceStatus) {
            totalAttendance = totalAttendance + 1;
         }
         totalOTHours = totalOTHours + ((entry.ot && parseFloat(entry.ot) > 0) ? parseFloat(entry.ot) : 0);
      });
      if (parseFloat(totalOTHours) % 1 != 0) {
         totalOTHours = parseFloat(totalOTHours).toFixed(2);
      }
      this.setState({
         totalAttendance: totalAttendance,
         totalOTHours: totalOTHours
      }, () => {
         this.calculateTotalWagesAndOT()
      });
   }

   calculateTotalWagesAndOT = () => {
      let totalOTAmount = 0;
      let totalWages = 0;
      totalOTAmount = this.state.otRate * parseFloat(this.state.totalOTHours);
      if (parseFloat(totalOTAmount) % 1 != 0) {
         totalOTAmount = parseFloat(totalOTAmount).toFixed(2);
      }
      totalWages = this.state.wageRate * parseFloat(this.state.totalAttendance);
      if (parseFloat(totalWages) % 1 != 0) {
         totalWages = parseFloat(totalWages).toFixed(2);
      }
      this.setState({
         totalOTAmount: totalOTAmount,
         totalWages: totalWages
      }, () => {
         this.calculateFinalAmount();
      });
   }

   calculateFinalAmount = () => {
      this.setState({
         totalAmount: ((this.state.totalWages && parseFloat(this.state.totalWages) > 0) ? parseFloat(this.state.totalWages) : 0) + ((this.state.totalOTAmount && parseFloat(this.state.totalOTAmount) > 0) ? parseFloat(this.state.totalOTAmount) : 0) + ((this.state.extraAllowance && parseFloat(this.state.extraAllowance) > 0) ? parseFloat(this.state.extraAllowance) : 0)
      }, () => {
         this.setState({
            finalAmount: ((this.state.totalAmount && parseFloat(this.state.totalAmount) > 0) ? parseFloat(this.state.totalAmount) : 0) - ((this.state.advanceDeduction && parseFloat(this.state.advanceDeduction) > 0) ? parseFloat(this.state.advanceDeduction) : 0) - ((this.state.weeklyAdvance && parseFloat(this.state.weeklyAdvance) > 0) ? parseFloat(this.state.weeklyAdvance) : 0)
         });
      });
   }
   // + ((this.state.newAdvance && parseFloat(this.state.newAdvance) > 0) ? parseFloat(this.state.newAdvance) : 0)
   handleDecimalValue(e, event) {
      let value = 0;
      if (event === 'change') {
         value = e.target.value;
      } else {
         value = e.target.value ? ((parseFloat(e.target.value) < 0) ? 0 : e.target.value) : 0;
         if (parseFloat(value) % 1 != 0) {
            value = parseFloat(value).toFixed(2);
         }
      }
      return value;
   }

   handleAllowanceChange(e, event) {
      this.setState({
         extraAllowance: this.handleDecimalValue(e, event)
      }, () => {
         this.calculateFinalAmount()
      });
   }

   handleNewAdvanceChange(e, event) {
      this.setState({
         newAdvance: this.handleDecimalValue(e, event)
      }, () => {
         this.calculateFinalAmount();
      });
   }

   handleWeeklyAdvanceChange(e, event) {
      this.setState({
         weeklyAdvance: this.handleDecimalValue(e, event)
      }, () => {
         this.calculateFinalAmount()
      });
   }

   handleAdvanceDeductionChange(e, event) {
      this.setState({
         advanceDeduction: this.handleDecimalValue(e, event)
      }, () => {
         this.calculateFinalAmount();
      });
   }

   checkboxClickhandle(e, i, date) {
      let selectedDate = moment(date);
      let today = moment(new Date().toDateString("MM/DD/YYYY"));
      let diff = selectedDate.diff(today, 'days');
      let tempMember = this.state.activeMember;
      if (diff <= 0) {
         tempMember.attendanceDetails[i].attendanceStatus = e.target.checked;
         this.setState({
            activeMember: tempMember
         }, () => {
            this.calculateTotalAttendanceAndOT();
         });
      } else {
         tempMember.attendanceDetails[i].attendanceStatus = false;
         this.setState({
            activeMember: tempMember
         });
      }
   }

   changeHandlerOt(index, event, e) {
      toast.dismiss();
      var lObject = this.state.activeMember.attendanceDetails[index];
      if (event === 'change') {
         lObject[e.target.name] = e.target.value;
      } 
      else {
         //console.log(parseFloat(e.target.value).toFixed(2));
         lObject[e.target.name] = e.target.value ? ((parseFloat(e.target.value) < 0) ? 0 : e.target.value) : 0;
         if (parseFloat(lObject[e.target.name]) % 1 != 0) {
            lObject[e.target.name] = parseFloat(lObject[e.target.name]).toFixed(2);
         }
      }
      const updatedData = update(this.state.activeMember.attendanceDetails, { $splice: [[index, 1, lObject]] });
      this.state.activeMember.attendanceDetails = updatedData;
      this.calculateTotalAttendanceAndOT();
   }

   validateOT = () => {
      let errArray = [];
      this.state.activeMember.attendanceDetails.forEach(function (entry) {
         if (parseFloat(entry.ot) > 16) {
            errArray.push(entry.attendanceDate);
         }
         // if (entry.attendanceStatus == false) {
         //    entry.ot = 0;
         // }
      });
      if (errArray.length > 0) {
         return false;
      } else {
         return true;
      }
   }

   saveLabourData = (event) => {
      event.preventDefault();
      if (!this.state.wsInProgress) {
         toast.dismiss();
         const isValid = this.validateOT();
         if (isValid) {
            this.setState({
               wsInProgress: true
            }, () => {
               this.params = {
                  member: this.state.activeMember,
                  startDate: this.state.activeMember.attendanceDetails[0].attendanceDate,
                  endDate: this.state.activeMember.attendanceDetails[this.state.activeMember.attendanceDetails.length - 1].attendanceDate,
                  wageRate: this.state.wageRate,
                  otRate: this.state.otRate,
                  extraAllowance: this.state.extraAllowance,
                  advanceDues: this.state.advanceDues,
                  newAdvance: this.state.newAdvance,
                  advanceDeduction: this.state.advanceDeduction,
                  weeklyAdvance: this.state.weeklyAdvance,
                  finalAmount: this.state.finalAmount,
                  token: this.state.token
               }
               axios.post(GLOBAL.SERVER_URL + "/api/save-labour-attendance-data", this.params).then(res => {
                  this.setState({
                     wsInProgress: false
                  }, () => {
                     if (res.data.errorCode === 0) {
                        toast.success(res.data.message, { delay: 150 });
                        this.props.getMemberDetails(this.state.activeMember._id);
                        this.props.closePopup();
                     } else {
                        toast.error(res.data.message, { delay: 150 });
                     }
                  });
               }).catch(error => {
                  toast.error("Error occured. Please try again!", { delay: 150 });
               });
            });
         } else {
            toast.error("Maximum 16 hours are allowed as OT", { delay: 150 });
         }
      }
   }

   render() {
      return (
         <div className="popup">
            <div className="popup_inner add-labour-mem">
               <div className="labour-popup">
                  <div className="labour-first-row">
                     <div className="row">
                        <div className="col-12 col-sm-6  col-xl-6">
                           <button onClick={this.props.closePopup} className="go-back project-save">GO BACK</button>
                        </div>
                        <div className="col-12 col-sm-6 col-xl-6">
                           {this.state.fieldEditStatus ?
                              <button onClick={this.saveLabourData} className="entry-save project-close">Save</button>
                              :
                              <button onClick={this.saveLabourData} className="entry-save project-close display-hide">Save</button>
                           }

                        </div>
                     </div>
                  </div>
                  <div className="row-bb">
                     <div className="row labour-details">
                        <div className="col-12 col-sm-12 col-xl-5">
                           <p>Name</p>
                           <h5>{this.state.activeMember?.firstName} {this.state.activeMember?.lastName ? this.state.activeMember?.lastName : ''}</h5>
                           <p>Designation</p>
                           <h5>{this.state.activeMember?.designationDetails[0].name}</h5>
                        </div>
                        <div className="col-6 col-sm-6 col-xl-4">
                           <p>Total Attendance</p>
                           <h5>{this.state.totalAttendance}</h5>
                           <p>Total OT Hours</p>
                           <h5>{this.state.totalOTHours}</h5>
                        </div>
                        <div className="col-6 col-sm-6 col-xl-3">
                           <p>Wage Rate</p>
                           <h5>₹ {this.state.wageRate}</h5>
                           <p>OT Rate</p>
                           <h5>₹ {this.state.otRate}</h5>
                        </div>
                        <div className="col-6 col-md-6 col-lg-5 col-sm-6">
                           <p>Account Number</p>
                           <h5>{this.state.accno}</h5>
                        </div>
                        <div className="col-6 col-md-6 col-sm-6">
                           <p>IFSC Code</p>
                           <h5>{this.state.ifsc}</h5>
                        </div>
                     </div>
                  </div>
                  <div className="labour-table-detail">
                     <div className="row">
                        <div className="col-xl-12">
                           <div className="total-wage-div">
                              <div className="row">
                                 <div className="col-6 col-md-3 col-sm-6">
                                    <p>Total Wages</p>
                                    <h4 className="lab-allow-btn">₹ {this.state.totalWages}</h4>
                                 </div>
                                 <div className="col-6 col-md-3 col-sm-3">
                                    <p>Total OT</p>
                                    <h4 className="lab-allow-btn">₹ {this.state.totalOTAmount}</h4>
                                 </div>
                                 <div className="col-6 col-md-3 col-sm-6">
                                    <p>Extra Allowance</p>
                                    {this.state.fieldEditStatus ?
                                       <input className="lab-allow-btn" type="number" value={this.state.extraAllowance} onChange={(e) => this.handleAllowanceChange(e, 'change')} onBlur={(e) => this.handleAllowanceChange(e, 'blur')} />
                                       :
                                       <div className="lab-allow-btn text-left">{this.state.extraAllowance}</div>
                                    }
                                 </div>
                                 <div className="col-6 col-md-3 col-sm-6">
                                    <p>Total Amount</p>
                                    <h4>₹ {this.state.totalAmount}</h4>
                                 </div>
                              </div>
                              <div className="row">
                                 <div className="col-6 col-md-3 col-sm-6">
                                    <p>Previous Loan Dues</p>
                                    <h4 className="lab-allow-btn">₹ {this.state.advanceDues}</h4>
                                 </div>

                                 <div className="col-6 col-md-3 col-sm-6">
                                    <p>Loan Paid</p>
                                    {this.state.fieldEditStatus ?
                                       <input className="lab-allow-btn" type="number" value={this.state.newAdvance} onChange={(e) => this.handleNewAdvanceChange(e, 'change')} onBlur={(e) => this.handleNewAdvanceChange(e, 'blur')} />
                                       :
                                       <div className="lab-allow-btn text-left">{this.state.newAdvance}</div>
                                    }
                                 </div>
                                 <div className="col-6 col-md-3 col-sm-6">
                                    <p>Loan Deducted</p>
                                    {this.state.fieldEditStatus ?
                                       <input className="lab-allow-btn" type="number" value={this.state.advanceDeduction} onChange={(e) => this.handleAdvanceDeductionChange(e, 'change')} onBlur={(e) => this.handleAdvanceDeductionChange(e, 'blur')} />
                                       :
                                       <div className="lab-allow-btn text-left">{this.state.advanceDeduction}</div>
                                    }
                                 </div>
                                 <div className="col-6 col-md-3 col-sm-6">
                                    <p>Weekly Advance Paid</p>
                                    {this.state.fieldEditStatus ?
                                       <input className="lab-allow-btn" type="number" value={this.state.weeklyAdvance} onChange={(e) => this.handleWeeklyAdvanceChange(e, 'change')} onBlur={(e) => this.handleWeeklyAdvanceChange(e, 'blur')} />
                                       :
                                       <div className="lab-allow-btn text-left">{this.state.weeklyAdvance}</div>
                                    }
                                 </div>
                              </div>
                              <div className="row">
                                 <div className="col-6 col-md-3 col-sm-2 total-div">
                                    <p>Balance Amount Due</p>
                                    <h4>₹ {parseFloat(this.state.finalAmount).toFixed(0)}</h4>
                                 </div>
                              </div>
                           </div>
                        </div>

                        <div className="col-xl-12">
                           <div className="labour-popup-table">
                              <table className="table custom-table table-responsive-sm">
                                 <thead>
                                    <tr>
                                       <th className="text-left">Date</th>
                                       <th>Attendance</th>
                                       <th>OT Hours</th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    {this.state.activeMember?.attendanceDetails.map((value, index) => (
                                       <tr key={index}>
                                          <td>
                                             <div className="lab-allow-btn labour-date-input">{moment(value.attendanceDate).format("DD/MM/YYYY")}</div>
                                          </td>
                                          <td>
                                             <div className="adv-checkbox blue-checkbox text-center">
                                                <label htmlFor={'labour-' + index} className="m-t-10">
                                                   <input type="checkbox" id={'labour-' + index} value={value.attendanceStatus} name={'labour-' + index} checked={value.attendanceStatus} onChange={e => this.checkboxClickhandle(e, index, value.attendanceDate)} />
                                                   <span className="custom-checkbox m-0">
                                                      <i className="icon-check"></i>
                                                   </span>
                                                </label>
                                             </div>
                                          </td>
                                          <td>
                                            {moment(value.attendanceDate)<=moment()?
                                                <input className="lab-allow-btn ot-hours-input text-center" type="number" name="ot" step=".01" value={value.ot} onChange={this.changeHandlerOt.bind(this, index, 'change')} onBlur={this.changeHandlerOt.bind(this, index, 'blur')} />:
                                            <p className="not-editable-box">0</p>}    
                                                                                       
                                          </td>
                                       </tr>
                                    ))}
                                 </tbody>
                              </table>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      );
   }
}
export default PopupViewLabourDetails;