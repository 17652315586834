import React, { Component } from "react";
import 'chart.js';
import cookie from 'react-cookies';
import { PieChart, AreaChart, ColumnChart, BarChart } from 'react-chartkick';
import Sidebar from '../includes/sidebar';
import Topbar from '../includes/topbar';

class Dashboard extends Component {
   constructor(props) {
      super(props)
      this.state = {
         loggedInUserId: '',
         accessLevels: [],
         token: cookie.load('_atclToken')
      }
   }

   syncLoggedInUserData(user) {
      this.refs.topbarComponent.syncLoggedInUserData(user);
      this.setState({
         loggedInUserId: user._id,
         accessLevels: user.roleDetails[0].accessLevels
      });
   }

   toggleSidebar() {
      this.refs.sidebarComponent.toggleSidebar();
   }

   render() {
      return (
         <div className="gray-bg full-screen">
            <Sidebar activeMenu={'dashboard'} ref="sidebarComponent" syncLoggedInUserData={this.syncLoggedInUserData.bind(this)} />
            <div className="inner-container">
               <Topbar ref="topbarComponent" toggleSidebar={this.toggleSidebar.bind(this)} />

               <div className="dashoard-div">
                  <div className="row">
                     <div className="col-12 col-md-12 col-lg-6 col-xl-6">
                        <div className="charts-div">
                           <div className="line-chart">

                              <h5>Total Sales</h5>
                              <AreaChart id="area-chart" legend={true} colors={["#F3FBFA"], ["#dbf5f0"]}
                                 data={{
                                    "2017-01-01 00:00:00 -0800": 2, "2017-01-01 00:01:00 -0800": 5, "2017-01-01 00:02:00 -0800": 1, "2017-01-01 00:03:00 -0800": 1, "2017-01-01 00:04:00 -0800": 4, "2017-01-01 00:05:00 -0800": 5, "2017-01-01 00:06:00 -0800": 2

                                 }}

                              />
                           </div>
                        </div>
                     </div>
                     <div className="col-12 col-md-12 col-lg-6 col-xl-6">
                        <div className="charts-div">
                           <div className="doughnout-chart">
                              <h5>Top Items Used</h5>
                              <PieChart id="pie-chart" colors={["#4FB3FF", "#847BFF", "#FF98D6", "#00D5FF", "#FFA43A", "#10B999"]} legend="right"
                                 border="0" data={[["Cement", 44], ["Iron", 23], ["M Sand", 40], ["Metal", 10], ["Steel", 30], ["OTHER", 28]]}
                              />
                           </div>
                        </div>
                     </div>
                     <div className="clear"></div>
                     <div className="row row-class-charts">
                        <div className="col-12 col-md-12 col-lg-6 col-xl-4">
                           <div className="charts-div">
                              <div className="doughnout-chart">
                                 <h5>Income / Expenditure</h5>
                                 <ColumnChart stacked={true} colors={["#10B999", "#10B999"]}
                                    className="width-100" height="250px"
                                    data={[["Income", 32], ["Current Balance", 28], ["Outcome", 46]]} />
                              </div>

                           </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-6 col-xl-4">
                           <div className="charts-div">
                              <div className="pie-chart">
                                 <h4>+ 39,423</h4>
                                 <p>Quantity Received in 2020</p>
                                 <PieChart
                                    className="width-100" height="205px" colors={["#10B999", "#4FB3FF"]} legend={true}
                                    data={[["Qty Received", 44], ["Consumed", 23]]}
                                    donut={true}
                                 />
                              </div>
                           </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-6 col-xl-4">
                           <div className="charts-div">
                              <div className="pie-chart">
                                 <h4>12,234</h4>
                                 <p>Projects</p>
                                 <BarChart width="300px" height="200px" label="Value"
                                    data={[["Delayed", 5], ["Total", 27], ["Off", 19], ["On Time", 27]]}
                                 />
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="clear"></div>
         </div>
      );
   }
}

export default Dashboard;