import React from "react";
import axios from 'axios';
import cookie from 'react-cookies';
import { toast } from 'react-toastify';

import * as GLOBAL from '../../../../global';
import '../../../../assets/css/style.css';

class PopupManageInventory extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      _id: '',
      brand: '',
      itemName: '',
      pmCode: '',
      model: '',
      quantity: '',
      type: '',
      unit: '',
      itemDescription: '',
      token: cookie.load('_atclToken'),
      units: [],
      wsInProgress: false
    }
  }

  componentDidMount() {
    this.getDropdownItems();
    if (this.props.itemId !== '') {
      this.setState({
        _id: this.props.itemId
      });
      this.getItemDetails(this.props.itemId);
    }
  }


  getDropdownItems = () => {
    axios.get(GLOBAL.SERVER_URL + "/api/get-units").then(res => {
      this.setState({
        units: res.data.response
      });
    });
  }

  validate = () => {

    let nameReg = /^[\w -_]*$/;
    
    if (!this.state.brand) {
      toast.error('Brand Name cannot be blank', { delay: 150 });
      return false;
    } else if (!(this.state.brand).match(nameReg)) {
      toast.error('Enter a valid Brand Name', { delay: 150 });
      return false;
    } else if (!(this.state.model).match(nameReg)) {
      toast.error('Enter a valid Model', { delay: 150 });
      return false;
    } else if (!this.state.model) {
      toast.error('Model cannot be blank', { delay: 150 });
      return false;
    } else if (!this.state.itemName) {
      toast.error('Item name cannot be blank', { delay: 150 });
      return false;
    } else if (!(this.state.itemName).match(nameReg)) {
      toast.error('Enter a valid Item Name', { delay: 150 });
      return false;
    } else if (!(this.state.pmCode).match(nameReg)) {
      toast.error('Enter a Valid P&M Code', { delay: 150 });
      return false;
    } else if (!this.state.pmCode) {
      toast.error('P&M code cannot be blank', { delay: 150 });
      return false;
    } else if (!this.state.quantity) {
      toast.error('Quantity cannot be blank', { delay: 150 });
      return false;
    } else if (!this.state.quantity || isNaN(this.state.quantity)) {
      toast.error('Valid Quantity is required', { delay: 150 });
      return false;
    } else if (!this.state.type) {
      toast.error('Unit cannot be blank', { delay: 150 });
      return false;
    } else if (!this.state.itemDescription) {
      toast.error('Item Description cannot be blank', { delay: 150 });
      return false;
    } else {
      return true;
    }
  }

  getItemDetails = (itemId) => {

    toast.dismiss();
    axios.get(GLOBAL.SERVER_URL + "/api/get-inventory-item-by-id?token=" + this.state.token + "&itemId=" + itemId).then(res => {

      if (res.data.errorCode === 0) {
        if (res.data.response._id) {
          this.setState({
            brand: res.data.response.brand,
            itemName: res.data.response.itemName,
            pmCode: res.data.response.pmCode,
            model: res.data.response.model,
            quantity: res.data.response.quantity,
            type: res.data.response.unit[0]._id,
            itemDescription: res.data.response.itemDescription
          });
        } else {
          toast.error(res.data.message, { delay: 150 });
          this.props.closePopup();
        }
      } else {
        toast.error(res.data.message, { delay: 150 });
        this.props.closePopup();
      }
    }).catch(error => {
      toast.error('Error while retrieving inventory', { delay: 150 });
    })

  }

  saveInventory = (event) => {
    event.preventDefault();

    if (!this.state.wsInProgress) {
      toast.dismiss();
      const isValid = this.validate();
      if (isValid) {

        this.setState({
          wsInProgress: true
        }, () => {
          this.params = {
            _id: this.state._id,
            brand: this.state.brand,
            itemName: this.state.itemName,
            pmCode: this.state.pmCode,
            model: this.state.model,
            quantity: this.state.quantity,
            type: this.state.type,
            itemDescription: this.state.itemDescription,
            token: this.state.token
          }

          axios.post(GLOBAL.SERVER_URL + "/api/save-inventory", this.params).then(res => {

            this.setState({
              wsInProgress: false
            }, () => {
              if (res.data.errorCode === 0) {
                toast.success(res.data.message, { delay: 150 });
                if (this.state._id !== '') {
                  this.props.getItemDetails(this.state._id);
                } else {
                  this.props.getInventoryItems();
                }
                this.props.closePopup();
              } else {
                toast.error(res.data.message, { delay: 150 });
              }
            });

          }).catch(error => {
            toast.error("Error occured. Please try again!", { delay: 150 });
          });
        });

      }

    }
  }

  render() {

    return (
      <div className="popup">
        <div className="popup_inner">
          <div className="page-header">
            <div className="material-card text-left">
              <div className="popup-heads">
                <div className="row">
                  <div className="col-12 col-sm-5 col-xl-6">
                    <h3 className="popup-title">
                      {(this.state._id === '') ? 'Add New Item' : 'Update Item'}
                    </h3>
                  </div>
                  <div className="col-12 col-sm-7 col-xl-6">
                    <button onClick={this.saveInventory} className="entry-save">Save</button>
                    <button onClick={this.props.closePopup} className="entry-close">Cancel</button>
                  </div>
                </div>
              </div>
              <div className="popup-body p-b-15">

                <div className="row mb-1">
                  <div className="col-12 col-lg-4">
                    <div className="form-group">
                      <label htmlFor="brand-name">Brand Name <span className="required">*</span></label>
                      <input className="form-control" id="brand-name" name="brand-name" value={this.state.brand} onChange={(e) => this.setState({ brand: e.target.value })} autoComplete="off" />

                    </div>
                  </div>
                  <div className="col-12 col-lg-4">
                    <div className="form-group">
                      <label htmlFor="model">Model <span className="required">*</span></label>
                      <input type="text" id="model" className="form-control" value={this.state.model} onChange={(e) => this.setState({ model: e.target.value })} autoComplete="off" />
                    </div>
                  </div>
                  <div className="col-12 col-lg-4">
                    <div className="form-group">
                      <label htmlFor="item-name">Item Name <span className="required">*</span></label>
                      <input type="text" id="item-name" className="form-control" value={this.state.itemName} onChange={(e) => this.setState({ itemName: e.target.value })} autoComplete="off" />
                    </div>
                  </div>
                </div>

                <div className="row mb-1">
                  <div className="col-12 col-lg-4">
                    <div className="form-group">
                      <label htmlFor="pm-code">P&M Code <span className="required">*</span></label>
                      <input type="text" id="pm-code" className="form-control" value={this.state.pmCode} onChange={(e) => this.setState({ pmCode: e.target.value })} autoComplete="off" />
                    </div>
                  </div>
                  <div className="col-12 col-lg-4">
                    <div className="form-group">
                      <label htmlFor="quantity">Quantity <span className="required">*</span></label>
                      <input type="text" id="quantity" className="form-control" value={this.state.quantity} onChange={(e) => this.setState({ quantity: e.target.value })} autoComplete="off" />
                    </div>
                  </div>
                  <div className="col-12 col-lg-4">
                    <div className="form-group">
                      <label htmlFor="type">Unit <span className="required">*</span></label>
                      <select className="form-control" id="type" value={this.state.type} onChange={(e) => this.setState({ type: e.target.value })} >
                        <option value="">Select</option>
                        {this.state.units.map((value, index) => {
                          return (<option value={value._id} key={index}>{value.unit}</option>)
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row mb-1">
                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="item-description">Item Description <span className="required">*</span></label>
                      <textarea className="form-control" id="item-description" rows="4" value={this.state.itemDescription} onChange={(e) => this.setState({ itemDescription: e.target.value })} ></textarea>
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div >
    );

  }
}

export default PopupManageInventory;
