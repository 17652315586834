import React, { Component } from "react";
import { Link } from 'react-router-dom';

class TabCheckListMain extends Component {

   constructor(props) {
      super(props);
      this.state = {};
   }

   render() {
      return (
         <div className="main-div-consumable">
            <div className="row">

               {(this.props.checklists.length > 0) &&
                  this.props.checklists.map((checklist, index) => {
                     return (
                        <div key={index} className="col-12 col-md-4 col-xl-4">

                           <div className="consumable-blocks">
                              {((this.props.accessLevels.indexOf(242) > -1) || (this.props.accessLevels.indexOf(243) > -1)) ?
                                 <div className="more-menu">
                                    <span></span>
                                    <ul>
                                       {(this.props.accessLevels.indexOf(242) > -1) ?
                                          <li onClick={() => this.props.openPopup(checklist)}>Edit</li>
                                          : null}
                                       {(this.props.accessLevels.indexOf(243) > -1) ?
                                          <li onClick={() => this.props.deleteProjectChecklist(index, checklist)}>Delete</li>
                                          : null}
                                    </ul>
                                 </div>
                                 : null}
                              <Link className="clickable-block" to={{ pathname: '/single-checklist/' + checklist.projectId + '/' + checklist._id }} >
                                 <h5>{checklist.checklistArea}</h5>
                                 <p>No: of Checklist: <span>{checklist.checklists.length}</span></p>
                              </Link>
                           </div>
                        </div>
                     )
                  })
               }

            </div>

         </div>
      );
   }
}
export default TabCheckListMain;