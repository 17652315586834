import React, { Component } from "react";
import axios from 'axios';
import cookie from 'react-cookies';
import { toast } from 'react-toastify';

import * as GLOBAL from '../../../../global';

class TabCheckListInner extends Component {

  constructor(props) {
    super(props);
    this.state = {
      wsInProgress: false,
      token: cookie.load('_atclToken')
    };
  }

  updateChecklistValue = (checklistId, checklistItemId, value) => {

    if (!this.state.wsInProgress) {
      this.setState({
        wsInProgress: true
      }, () => {
        toast.dismiss();
        this.params = {
          projectId: this.props.projectMainChecklist.projectId,
          checklistId: checklistId,
          checklistItemId: checklistItemId,
          value: value,
          token: this.state.token
        }

        axios.post(GLOBAL.SERVER_URL + "/api/update-project-checklist-value", this.params).then(res => {
          this.setState({
            wsInProgress: false
          }, () => {
            if (res.data.errorCode === 0) {
              this.props.getProjectChecklists(this.props.projectMainChecklist._id);
            }
          });
        }).catch(error => {
          toast.error("Error occured. Please try again!", { delay: 150 });
        });
      });
    }

  }

  render() {
    return (
      <div className="checklist-contents">
        {(this.props.projectChecklists.length > 0) &&
          this.props.projectChecklists.map((checklist, index) => {
            return (
              <div key={index}>
                <h4 className="check-list-head">{checklist.title}</h4>
                <table className="table custom-table checklist-table mb-4 table-responsive-md">
                  <thead>
                    <tr>
                      <th className="bold-head text-center width-75">Sl. No</th>
                      <th className="bold-head text-left">Description</th>
                      <th className="bold-head text-center width-90">Yes</th>
                      <th className="bold-head text-center width-90">No</th>
                      <th className="bold-head text-center width-90">N/A</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(checklist.checklistitems.length > 0) &&
                      checklist.checklistitems.map((checklistItem, cindex) => {
                        return (
                          <tr key={cindex}>
                            <td className="text-center">{cindex + 1}</td>
                            <td>{checklistItem.description}</td>
                            <td className="text-center">
                              <label className="custom-radio-btn">
                                <input type="radio" name={checklistItem._id + '-' + cindex} value="yes" checked={checklistItem.value === 'yes'} onChange={() => this.updateChecklistValue(checklist._id, checklistItem._id, 'yes')} />
                                <span></span>
                              </label>
                            </td>
                            <td className="text-center">
                              <label className="custom-radio-btn">
                                <input type="radio" name={checklistItem._id + '-' + cindex} value="no" checked={checklistItem.value === 'no'} onChange={() => { this.updateChecklistValue(checklist._id, checklistItem._id, 'no') }} />
                                <span></span>
                              </label>
                            </td>
                            <td className="text-center">
                              <label className="custom-radio-btn">
                                <input type="radio" name={checklistItem._id + '-' + cindex} value="na" checked={checklistItem.value === 'na'} onChange={() => this.updateChecklistValue(checklist._id, checklistItem._id, 'na')} />
                                <span></span>
                              </label>
                            </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>
            )
          })
        }
      </div>
    );
  }
}
export default TabCheckListInner;