import React, { Component } from "react";
import axios from 'axios';
import cookie from 'react-cookies';
import { toast } from 'react-toastify';
import { Link, Redirect } from 'react-router-dom';
import * as GLOBAL from '../../../global';
import Sidebar from '../includes/sidebar';
import Topbar from '../includes/topbar';
import InventoryLocations from "./inventory-locations";
import Activities from './activities';
// import Orders from './orders';
import Comments from './comments';
import './inventory.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import PopupManageInventory from './popups/manage-inventory-popup';

class ManageInventory extends Component {

   constructor(props) {
      super(props)
      this.state = {
         _id: '',
         brand: '',
         itemName: '',
         pmCode: '',
         model: '',
         quantity: '',
         type: '',
         unit: '',
         itemDescription: '',
         projectLocations: [],
         requestFromProjectId: '',
         requestToProjectId: '',
         requestQuantity: '',
         showPopup: false,
         activeTabIndex: 0,
         loggedInUserId: '',
         accessLevels: [],
         itemUniqueLocations: [],
         workshopProjectId: null,
         unauthorizedAccess: false,
         wsInProgress: false,
         token: cookie.load('_atclToken')
      }
   }

   componentDidMount() {
      if (this.state.token && typeof this.state.token != 'undefined') {
         if (this.props.match.params && this.props.match.params.itemId) {
            this.getItemDetails(this.props.match.params.itemId);
         }
      }
   }

   syncLoggedInUserData(user) {
      this.refs.topbarComponent.syncLoggedInUserData(user);
      this.setState({
         loggedInUserId: user._id,
         accessLevels: user.roleDetails[0].accessLevels,
         unauthorizedAccess: (user.roleDetails[0].accessLevels.indexOf(300) === -1) ? true : false
      });
   }

   toggleSidebar() {
      this.refs.sidebarComponent.toggleSidebar();
   }

   togglePopup() {
      this.setState({
         showPopup: !this.state.showPopup
      });
   }

   getProjectLocations = () => {
      axios.get(GLOBAL.SERVER_URL + "/api/get-project-locations").then(res => {
         this.setState({
            projectLocations: res.data.response,
            workshopProjectId: res.data.workshopProjectId
         });
      });
   }

   getItemUniqueLocations(itemId) {
      toast.dismiss();
      axios.get(GLOBAL.SERVER_URL + "/api/get-inventory-unique-locations?token=" + this.state.token + "&itemId=" + itemId).then(res => {
         if (res.data.errorCode === 0) {
            this.setState({
               itemUniqueLocations: res.data.response
            });
         }
         this.getProjectLocations();
      }).catch(error => {
         toast.error('Error while retrieving inventory items', { delay: 150 });
      })
   }

   getItemDetails = (itemId) => {
      toast.dismiss();
      axios.get(GLOBAL.SERVER_URL + "/api/get-inventory-item-by-id?token=" + this.state.token + "&itemId=" + itemId).then(res => {
         if (res.data.errorCode === 0) {
            if (res.data.response._id) {
               this.setState({
                  _id: res.data.response._id,
                  brand: res.data.response.brand,
                  itemName: res.data.response.itemName,
                  pmCode: res.data.response.pmCode,
                  model: res.data.response.model,
                  quantity: res.data.response.quantity,
                  type: res.data.response.unit[0]._id,
                  unit: res.data.response.unit[0].unit,
                  itemDescription: res.data.response.itemDescription
               });
               this.getItemUniqueLocations(res.data.response._id);
               if (this.state.activeTabIndex === 2) {
                  this.refs.activityComponent.getInventoryActivities(res.data.response._id, '');
               }
            } else {
               toast.error(res.data.message, { delay: 150 });
               const { history } = this.props;
               history.push({
                  pathname: '/inventory'
               });
            }
         } else {
            toast.error(res.data.message, { delay: 150 });
            const { history } = this.props;
            history.push({
               pathname: '/'
            });
         }
      }).catch(error => {
         toast.error('Error while retrieving inventory', { delay: 150 });
      })
   }

   validateRequestForm = () => {
      if (!this.state.requestFromProjectId) {
         toast.error('From project cannot be blank', { delay: 150 });
         return false;
      } else if (!this.state.requestToProjectId) {
         toast.error('To project cannot be blank', { delay: 150 });
         return false;
      } else if (this.state.requestFromProjectId === this.state.requestToProjectId) {
         toast.error('From and To projects cannot be the same', { delay: 150 });
         return false;
      } else if (!this.state.requestQuantity || isNaN(this.state.requestQuantity)) {
         toast.error('Valid quantity value is required', { delay: 150 });
         return false;
      } else {
         return true;
      }
   }

   orderItem = () => {
      if (!this.state.wsInProgress) {
         toast.dismiss();
         this.isValid = this.validateRequestForm();
         if (this.isValid) {
            this.setState({
               wsInProgress: true
            }, () => {
               this.params = {
                  _id: this.state._id,
                  fromProjectId: this.state.requestFromProjectId,
                  toProjectId: this.state.requestToProjectId,
                  quantity: this.state.requestQuantity,
                  orderType: 'request',
                  unit: this.state.unit,
                  token: this.state.token
               }
               axios.post(GLOBAL.SERVER_URL + "/api/order-inventory", this.params).then(res => {
                  this.setState({
                     wsInProgress: false
                  }, () => {
                     if (res.data.errorCode === 0) {
                        this.setState({
                           requestFromProjectId: '',
                           requestToProjectId: '',
                           requestQuantity: ''
                        })
                        if (this.state.activeTabIndex === 1) {
                           this.refs.orderComponent.getOrders(this.state._id);
                        }
                        toast.success(res.data.message, { delay: 150 });
                     } else {
                        toast.error(res.data.message, { delay: 150 });
                     }
                  });
               }).catch(error => {
                  toast.error("Error occured. Please try again!", { delay: 150 });
               });
            });
         }
      }
   }

   render() {
      console.log("This.satet",this.state)

      if (this.state.unauthorizedAccess) {
         return <Redirect to="/dashboard" push={true} />;
      }

      return (
         <div className="gray-bg full-screen">
            <Sidebar activeMenu={'inventory'} ref="sidebarComponent" syncLoggedInUserData={this.syncLoggedInUserData.bind(this)} />
            <div className="inner-container">
               <Topbar ref="topbarComponent" toggleSidebar={this.toggleSidebar.bind(this)} />
               <div className="page-header mt-3 inventory-inner-container">
                  <Tabs selectedIndex={this.state.activeTabIndex} onSelect={activeTabIndex => this.setState({ activeTabIndex })}>
                     <Link to='/inventory' className="back-button mr-4">
                        Back </Link>
                     <div className="custom-tabs-div">
                        <TabList className="custom-tabs">

                           <Tab>Details</Tab>
                           {/* {(this.state.accessLevels.indexOf(307) > -1) ?
                           <Tab>Orders</Tab>
                              : null} */}
                           {(this.state.accessLevels.indexOf(311) > -1) ?
                              <Tab>Activities</Tab>
                              : null}
                           {(this.state.accessLevels.indexOf(312) > -1) ?
                              <Tab>Comments</Tab>
                              : null}

                        </TabList>

                     </div>

                     {(this.state.accessLevels.indexOf(302) > -1) ?
                        <button className="custom-btn edit-button m-inven-btn" onClick={this.togglePopup.bind(this)}>EDIT</button>
                        : null}
                     {this.state.showPopup ?
                        <PopupManageInventory itemId={this.state._id}
                           closePopup={this.togglePopup.bind(this)} getItemDetails={this.getItemDetails.bind(this)}
                        />
                        : null
                     }
                     <div className="clear"></div>

                     <TabPanel>
                        <div className="inventory-item-box">
                           <div className="row inventory-meta-details zero-margin">
                              <div className="material-card-detail text-left item-data-display col-12 col-md-12">
                                 <h3 className="order-item-name-overflow">{this.state.itemName}</h3>
                                 <p className="order-item-name-overflow">{this.state.itemDescription}</p>
                              </div>
                              <div className="col-6 col-md-3 zero-padding left-bor-radius">
                                 <div className="single-meta-data-detail">
                                    <h2 className="overflow-ellipsis">{this.state.pmCode}</h2>
                                    <span>P&M Code</span>
                                 </div>
                              </div>
                              <div className="col-6 col-md-3 zero-padding">
                                 <div className="single-meta-data-detail">
                                    <h2 className="overflow-ellipsis">{this.state.brand}</h2>
                                    <span>Make / Brand</span>
                                 </div>
                              </div>
                              <div className="col-6 col-md-3 zero-padding">
                                 <div className="single-meta-data-detail">
                                    <h2 className="overflow-ellipsis">{this.state.model}</h2>
                                    <span>Model</span>
                                 </div>
                              </div>
                              <div className="col-6 col-md-3 zero-padding right-bor-radius">
                                 <div className="single-meta-data-detail">
                                    <h2 className="overflow-ellipsis">{this.state.quantity} <span className="unit-text">{this.state.unit}</span></h2>
                                    <span>Total Quantity</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                        {(this.state._id && (this.state.projectLocations.length > 0)) ?
                           <InventoryLocations compData={{ _id: this.state._id, itemName: this.state.itemName, quantity: this.state.quantity, type: this.state.type, unit: this.state.unit, projectLocations: this.state.projectLocations, workshopProjectId: this.state.workshopProjectId, accessLevels: this.state.accessLevels }} getItemUniqueLocations={this.getItemUniqueLocations.bind(this)} />
                           : null
                        }
                     </TabPanel>

                     {/* {(this.state.accessLevels.indexOf(307) > -1) ?
                        <TabPanel>
                           <Orders ref="orderComponent" compData={{ _id: this.state._id, itemName: this.state.itemName, pmCode: this.state.pmCode, unit: this.state.unit, projectLocations: this.state.projectLocations, workshopProjectId: this.state.workshopProjectId, accessLevels: this.state.accessLevels }} />
                        </TabPanel>
                        : null} */}

                     {(this.state.accessLevels.indexOf(311) > -1) ?
                        <TabPanel>
                           <Activities ref="activityComponent" compData={{ _id: this.state._id, itemName: this.state.itemName, pmCode: this.state.pmCode, projectLocations: this.state.projectLocations, workshopProjectId: this.state.workshopProjectId, accessLevels: this.state.accessLevels }} />
                        </TabPanel>
                        : null}

                     {(this.state.accessLevels.indexOf(312) > -1) ?
                        <TabPanel>
                           <Comments compData={{ _id: this.state._id, itemName: this.state.itemName, pmCode: this.state.pmCode, projectLocations: this.state.projectLocations, workshopProjectId: this.state.workshopProjectId, accessLevels: this.state.accessLevels }} />
                        </TabPanel>
                        : null}

                  </Tabs>
               </div>
               {/* <div className="form-sent-sidebar">
                  <Tabs className="send-req-tabs">
                     <TabList>
                        <Tab>REQUEST</Tab>
                     </TabList> 
                     <TabPanel>
                        <div className="form-body">
                           <div className="page-header mt-2">
                              <div className="form-group">
                                 <label htmlFor="request_from_location">From Project</label>
                                 <select className="form-control" id="request_from_location" value={this.state.requestFromProjectId} onChange={(e) => this.setState({ requestFromProjectId: e.target.value })} >
                                    <option value="">Select project</option>
                                    {this.state.itemUniqueLocations.map((value, index) => {
                                       return (
                                          <option value={value._id} key={index}>{value.projectName}  {(value._id === this.state.workshopProjectId) ? '(WS)' : null}</option>
                                       )
                                    })}
                                 </select>
                              </div>
                              <div className="form-group">
                                 <label htmlFor="request_to_location">To Project</label>
                                 <select className="form-control" id="request_to_location" value={this.state.requestToProjectId} onChange={(e) => this.setState({ requestToProjectId: e.target.value })} >
                                    <option value="">Select project</option>
                                    {this.state.projectLocations.map((value, index) => {
                                       return (
                                          <option value={value._id} key={index}>{value.projectName}  {(value._id === this.state.workshopProjectId) ? '(WS)' : null}</option>
                                       )
                                    })}
                                 </select>
                              </div>
                              <div className="form-group full-width">
                                 <div className="col-md-8">
                                    <div className="form-group">
                                       <label htmlFor="request_quantity"> Quantity <span className="label-sub-text">({this.state.unit})</span></label>
                                       <input type="text" id="request_quantity" className="form-control" value={this.state.requestQuantity} onChange={(e) => this.setState({ requestQuantity: e.target.value })} autoComplete="off" />
                                    </div>
                                 </div>
                              </div>
                              <div className="send-req-btn">
                                 <div className="sendbttn">
                                    {(this.state.accessLevels.indexOf(309) > -1) ?
                                       <button className="send-btn send-button send-btn-a" onClick={() => this.orderItem()}>REQUEST</button>
                                       : null}
                                 </div>
                              </div>
                           </div>
                        </div>
                     </TabPanel>

                  </Tabs>
               </div> */}
            </div>
            <div className="clear"></div>
         </div>
      );
   }
}

export default ManageInventory;
